import { type FetchError } from 'ofetch';
import { SurveyListParticipantsFactory } from '~/models/factories/SurveyListParticipants';
import { type SurveyListParticipants } from '~/models/SurveyListParticipants';
import { type ExtranetRelationSurveyList } from '~/models/ExtranetRelationSurveyList';
import { type SurveylistParticipantResponse } from '~/types/SurveylistParticipant';
import BaseApiService from '~/services/api/BaseApiService';
import { QuestionStepFactory } from '~/models/factories/QuestionStepFactory';
import { type ReadQuestionStepResponse } from '~/types/QuestionStep';
import { type QuestionStep } from '~/models/QuestionStep';
import { type WriteSurveyListAnswerDto } from '~/types/SurveyListAnswer';
import {
    type ExtranetRelationSurveyListResponse,
    type ExtranetRelationSurveyListsOverview,
    type ExtranetRelationSurveyListsOverviewResponse,
} from '~/types/ExtranetRelationSurveyList';
import { ExtranetRelationSurveyListsFactory } from '~/models/factories/ExtranetRelationSurveyListsFactory';
import { ExtranetRelationSurveyListDetailsFactory } from '~/models/factories/ExtranetRelationSurveyListDetailsFactory';
import { type ReadExtranetRelationSurveyListDetailsDto } from '~/types/ExtranetRelationSurveyListDetailsDto';
import { type ExtranetRelationSurveyListDetailsModel } from '~/models/ExtranetRelationSurveyListDetails';

export default class ExtranetSurveyListService extends BaseApiService {
    private basePath = 'surveylists';

    /**
     * @description Get the relation based on the token of Extranet user.
     * @param {string} uuid An unique ID for getting the surveylist
     * @returns {Promise<SurveyListParticipants>} Promise with SurveyListParticipants as payload
     */
    async getIntro(uuid: string): Promise<SurveyListParticipants> {
        try {
            const response = await this.baseGet<SurveylistParticipantResponse>(
                `${this.createPath(this.basePath)}/${uuid}`,
            );

            return (new SurveyListParticipantsFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Start the surveylist.
     * @param {string} uuid An unique ID for starting the surveylist
     * @returns {Promise<SurveyListParticipants>} Promise with SurveyListParticipants as payload
     */
    async startSurvey(uuid: string): Promise<SurveyListParticipants> {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response = await this.basePost<any>(
                `${this.createPath(this.basePath)}/${uuid}`,
            );

            return (new SurveyListParticipantsFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    async finishSurvey(uuid: string, data: {
        confirmed: string;
    }): Promise<SurveyListParticipants> {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response = await this.basePost<any>(
                `${this.createPath(this.basePath)}/${uuid}/end`,
                data,
            );

            return (new SurveyListParticipantsFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Get the question of the step for the surveylist.
     * @param {string} uuid An unique ID for the surveylist
     * @param {number} step The index of the step
     * @returns {Promise<QuestionStep>} Promise with QuestionStep as response
     */
    async getQuestionStep(uuid: string, step: number): Promise<QuestionStep> {
        try {
            const response = await this.baseGet<ReadQuestionStepResponse>(
                `${this.createPath(this.basePath)}/${uuid}/step/${step}`,
            );

            return (new QuestionStepFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Submit the answer for the question of the surveylist.
     * @param {string} uuid An unique ID for the surveylist
     * @param {number} step The index of the step
     * @param {WriteSurveyListAnswerDto} data The data that is being send as payload
     * @returns {Promise<QuestionStep>} Promise with QuestionStep as response
     */
    async submitAnswer(uuid: string, step: number, data: WriteSurveyListAnswerDto): Promise<QuestionStep> {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response = await this.basePost<any>(
                `${this.createPath(this.basePath)}/${uuid}/step/${step}`,
                data,
            );

            return (new QuestionStepFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Delete optionset from surveylist.
     * @param {number} optionsetId The index of the step
     * @returns {Promise<null>} Promise with no response
     */
    async deleteOptionset(optionsetId: number): Promise<null> {
        try {
            return await this.baseDelete(
                `${this.createPath(this.basePath)}/optionsets/${optionsetId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Delete option from surveylist.
     * @param {number} optionId The index of the step
     * @returns {Promise<null>} Promise with no response
     */
    async deleteOption(optionId: number): Promise<null> {
        try {
            return await this.baseDelete(
                `${this.createPath(this.basePath)}/options/${optionId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch the statement PDF.
     * @param {string} uuid An unique ID for the surveylist
     * @param {number} step The index of the step
     * @returns {Promise<void>} Returns the statement file
     */
    async fetchStatement(uuid: string, step: number): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(this.basePath)}/${uuid}/step/${step}/download`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch the surveylists the user has fully answered.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<ExtranetRelationSurveyListsOverview>} API response
     */
    async fetchAnsweredSurveylists(queryParameters = null): Promise<ExtranetRelationSurveyListsOverview> {
        try {
            const response = await this.baseGet<ExtranetRelationSurveyListsOverviewResponse>(
                `${this.createPath(this.basePath)}/answered`,
                queryParameters,
            );
            const data = (new ExtranetRelationSurveyListsFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch the answers of a participant for a certain surveylist.
     * @param {string} uuid Identifier of the surveylist to fetch
     * @returns {Promise<ExtranetRelationSurveyList>} API response
     */
    async fetchAnsweredSurveylist(uuid: string): Promise<ExtranetRelationSurveyList> {
        try {
            const response = await this.baseGet<ExtranetRelationSurveyListResponse>(
                `${this.createPath(this.basePath)}/${uuid}/answers`,
            );

            return (new ExtranetRelationSurveyListsFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    async fetchDetails(uuid: string): Promise<ExtranetRelationSurveyListDetailsModel> {
        try {
            const response = await this.baseGet<{
                data: ReadExtranetRelationSurveyListDetailsDto;
            }>(
                `${this.createPath(this.basePath)}/${uuid}/details`,
            );

            return (new ExtranetRelationSurveyListDetailsFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    async downloadQuestionsPdf(uuid: string): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(this.basePath)}/${uuid}/download-questions`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }
}
