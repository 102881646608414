import type { Attachment } from '~/models/Attachment';

export class RelationCourse {
    public applicableYear: number | null = null;

    public chamber: string | null = null;

    public courseCode: string | null = null;

    public courseDate: string | null = null;

    public courseName: string | null = null;

    public courseId: number | null = null;

    public id: number | null = null;

    public isActivity: boolean | null = null;

    public points: number | null = null;

    public relationId: number | null = null;

    public source: string | null = null;

    public trainingPartner: string | null = null;

    public attachments: Attachment[] | null = null;
}
