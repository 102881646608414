import type { InvoiceLine } from './InvoiceLine';
import type { InvoiceLog } from './InvoiceLog';
import type { Relation } from './Relation';
import { formatCurrency } from '~/utils/TextUtils';
import DateFormatUtils from '~/utils/DateFormatUtils';

export class Invoice {
    public accountNumber: string | null = null;

    public accountingPeriod: number | null = null;

    public address: string | null = null;

    public addressType: string | null = null;

    public createdAt: string | null = null;

    public deletedAt: string | null = null;

    public queuedAt?: string | null = null;

    public date: string | null = null;

    public dateSent: string | null = null;

    public daysPastDue: number | null = null;

    public description: string | null = null;

    public id: number | null = null;

    public invoiceNumber: string | null = null;

    public invoiceStatusId: number | null = null;

    public invoiceStatusName: string | null = null;

    public invoiceTypeName: string | null = null;

    public openAmountInclVat: number | null = null;

    public paidOn: string | null = null;

    public relation: Relation | null = null;

    public relationId: number | null = null;

    public reminderSent: string | null = null;

    public secondReminderSent: string | null = null;

    public sendDate: string | null = null;

    public statusText: string | null = null;

    public subtotal: number | null = null;

    public tax: number | null = null;

    public total: number | null = null;

    public totalAmount: number | null = null;

    public visibleOnExtranet: boolean | null = null;

    public invoiceLines: InvoiceLine[] | null = null;

    public invoiceLogs: InvoiceLog[] | null = null;

    public isPdfPresent: boolean | null = null;

    /*
     * @doc get invoice total currency.
     * On the invoice details total is used
     * On the list overview the totalAmount is used.
     * @returns string
     */
    public getTotalAmountFormatted(): string {
        let totalToFormat = null;
        // eslint-disable-next-line no-undefined
        if (this.totalAmount !== undefined) {
            totalToFormat = this.totalAmount;
        // eslint-disable-next-line no-undefined
        } else if (this.total !== undefined) {
            totalToFormat = this.total;
        }

        return formatCurrency(Number(totalToFormat));
    }

    public getDate(): string {
        return DateFormatUtils.dateFormatToDisplay(this?.date?.split(' ')[0] ?? '');
    }

    public getDateSent(): string {
        return DateFormatUtils.dateFormatToDisplay(this?.dateSent?.split(' ')[0] ?? '');
    }

    public getSendDate(): string {
        return DateFormatUtils.dateFormatToDisplay(this?.sendDate?.split(' ')[0] ?? '');
    }

    public getReminderSent(): string | null {
        return DateFormatUtils.dateFormatToDisplay(this?.reminderSent?.split(' ')[0] ?? '');
    }

    public getReminderSentString(): string {
        if (this.reminderSent === null) {
            return 'Nee';
        }

        return `Ja - ${this.getReminderSent()}`;
    }
}
