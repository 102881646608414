import { BaseFactory } from './BaseFactory';
import type { ReadChamberDto } from '~/types/Chamber';
import { Chamber } from '~/models/Chamber';

export class ChamberFactory extends BaseFactory<ReadChamberDto, Chamber> {
    public toModel(dto: ReadChamberDto): Chamber {
        const model = new Chamber();

        model.activity = Boolean(dto.activity);
        model.code = dto.code || null;
        model.id = Number(dto.id);
        model.mandatory = Boolean(dto.mandatory);
        model.name = dto.name;
        model.shareDataWithVi = dto.shareDataWithVi || null;

        return model;
    }
}
