import type { Attachment } from './Attachment';

export class PeriodActivity {
    public activityId: number | null = null;

    public activityTypeString: string | null = null;

    public attachments: Attachment[] | null = null;

    public date: string | null = null;

    public description: string | null = null;

    public other: string | null = null;

    public points: number | null = null;
}
