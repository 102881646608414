import type { Attachment } from './Attachment';

export class PeriodCourse {
    public attachments: Attachment[] | null = null;

    public chamberCode: string | null = null;

    public code: string | null = null;

    public courseId: number | null = null;

    public coursePeriodId: number | null = null;

    public date: string | null = null;

    public name: string | null = null;

    public points: number | null = null;

    public trainerId: number | null = null;

    public trainerName: string | null = null;
}
