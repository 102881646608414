export class Chamber {
    public activity = false;

    public code: string | null = null;

    public id: number | null = null;

    public mandatory = false;

    public name: string | null = null;

    public shareDataWithVi: string | null = null;
}
