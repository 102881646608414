import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type DocumentResponse, type DocumentsOverview, type DocumentsOverviewResponse, type WriteDocumentDto,
} from '~/types/Document';
import { DocumentFactory } from '~/models/factories/DocumentFactory';
import { type Document } from '~/models/Document';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';

export default class DocumentService extends BaseApiService {
    public static basePath = 'documents';

    /**
     * @description Delete a document.
     * @param {number} documentId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(documentId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(DocumentService.basePath)}/${documentId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new document.
     * @param {WriteDocumentDto} data Form data to post
     * @returns {Promise<Document>} Api response
     */
    async create(data: WriteDocumentDto): Promise<Document> {
        const response = await this.basePost<DocumentResponse>(
            this.createPath(DocumentService.basePath),
            data,
        );

        return (new DocumentFactory()).toModel(response.data);
    }

    /**
     * @description Fetch single document by document id.
     * @param {number} documentId Identifier of the document to fetch
     * @returns {Promise<Document>} Promise with the Document model as payload
     */
    async fetchDocument(documentId: number): Promise<Document> {
        try {
            const response = await this.baseGet<DocumentResponse>(
                `${this.createPath(DocumentService.basePath)}/${documentId}`,
            );

            return (new DocumentFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple documents depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<DocumentsOverview>} Promise with the documentsOverview as payload
     */
    async fetchDocuments(queryParameters = null): Promise<DocumentsOverview> {
        try {
            const response = await this.baseGet<DocumentsOverviewResponse>(
                this.createPath(DocumentService.basePath),
                queryParameters,
            );
            const documentData = (new DocumentFactory()).toModels(response.data);
            const metaData = response.meta;

            return {
                data: documentData,
                meta: metaData,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a document.
     * @param {number} documentId Id of the resource to save
     * @param {WriteDocumentDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(documentId: number, data: WriteDocumentDto): Promise<null> {
        return this.basePost<null>(
            `${this.createPath(DocumentService.basePath)}/${documentId}`,
            data,
        );
    }

    /**
     * @description Fetch single download by document id.
     * @param {number} documentId Identifier of the download to fetch
     * @returns {Promise<void>} Returns the document file
     */
    async downloadDocument(documentId: number): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(DocumentService.basePath)}/${documentId}/download`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }
}
