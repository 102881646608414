import { BaseFactory } from './BaseFactory';
import type { ReadCompanyMovementDto } from '~/types/CompanyMovement';
import { CompanyMovement } from '~/models/CompanyMovement';

export class CompanyMovementFactory extends BaseFactory<ReadCompanyMovementDto, CompanyMovement> {
    /* eslint-disable-next-line max-statements, complexity */
    public toModel(dto: ReadCompanyMovementDto): CompanyMovement {
        const model = new CompanyMovement();

        model.fromCompanyId = dto.fromCompanyId || null;

        model.name = dto.name || null;

        model.street = dto.street || null;

        model.status = dto.status || null;

        model.houseNumber = dto.houseNumber || null;

        model.houseNumberSuffix = dto.houseNumberSuffix || null;

        model.postal = dto.postal || null;

        model.city = dto.city || null;

        model.phone = dto.phone || null;

        model.per = dto.per || null;

        model.email = dto.email || null;

        model.website = dto.website || null;

        model.mailbox = dto.mailbox || null;

        model.mailboxPostal = dto.mailboxPostal || null;

        model.mailboxCity = dto.mailboxCity || null;

        model.movementDate = dto.movementDate || null;

        model.cocFile = dto.cocFile || null;

        return model;
    }
}
