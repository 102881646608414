import { Period } from '../Period';
import { BaseFactory } from './BaseFactory';
import type { ReadPeriodDto } from '~/types/Period';

export class PeriodFactory extends BaseFactory<ReadPeriodDto, Period> {
    public toModel(dto: ReadPeriodDto): Period {
        const model = new Period();

        model.active = Boolean(dto.active);
        model.end = dto.end;
        model.id = Number(dto.id);
        model.start = dto.start;

        return model;
    }
}
