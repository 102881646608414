import { BaseFactory } from './BaseFactory';
import { AttachmentFactory } from './AttachmentFactory';
import { ExtranetRelationSurveyListAnswer } from '~/models/ExtranetRelationSurveyListAnswer';
import type { ReadExtranetRelationSurveyListAnswerDto } from '~/types/ExtranetRelationSurveyListAnswer';

export class ExtranetRelationSurveyListAnswersFactory
    extends BaseFactory<ReadExtranetRelationSurveyListAnswerDto, ExtranetRelationSurveyListAnswer> {
    /* eslint-disable-next-line max-statements, complexity */
    public toModel(
        dto: ReadExtranetRelationSurveyListAnswerDto,
        questionType: string): ExtranetRelationSurveyListAnswer {
        const model = new ExtranetRelationSurveyListAnswer();

        model.no = Number(dto.no);

        model.question = dto.question || null;
        model.type = dto.type || null;
        model.isChild = Boolean(dto.isChild);
        model.else = dto.else || null;
        model.questionId = Number(dto.questionId);
        model.parentQuestionChoice = dto.parentQuestionChoice || null;

        // Refactoring this is tech debt and will change when the new surveylist creation flow is implemented
        // Requires fundamental changes in the FE and BE
        // Backlog ticket for this is: https://bitf.atlassian.net/browse/NRVT-2065

        switch (questionType) {
            // Default the answer to an empty array when the question type is selectmore
            case 'selectmore': {
                model.answer = dto.answer ? (dto.answer === 'nvt' ? [] : dto.answer) : [];

                break;
            }
            // Transform the answer to a single value instead of array when the question type is selectone
            case 'selectone': {
                model.answer = dto.answer ? (dto.else ? dto.answer : (dto.answer as string[])[0]) : null;

                break;
            }
            // Transform the answer to a boolean value instead of string when the question type is checkbox
            case 'check': {
                model.answer = dto.answer ? dto.answer === 'true' : null;

                break;
            }
            default: {
                model.answer = dto.answer || null;
            }
        }

        if (dto?.attachments && dto?.attachments.length) {
            model.attachments = (new AttachmentFactory()).toModels(dto.attachments);
        }

        return model;
    }
}
