import { RelationStatus } from '../RelationStatus';
import { BaseFactory } from './BaseFactory';
import type { ReadRelationStatusDto } from '~/types/RelationStatus';

export class RelationStatusFactory extends BaseFactory<ReadRelationStatusDto, RelationStatus> {
    public toModel(dto: ReadRelationStatusDto): RelationStatus {
        const model = new RelationStatus();

        model.id = Number(dto.id);
        model.name = dto.name;
        model.order = dto.order;

        return model;
    }
}
