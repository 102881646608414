import { BaseFactory } from './BaseFactory';
import type { ReadExtranetCompanyDto } from '~/types/ExtranetCompany';
import { ExtranetCompany } from '~/models/ExtranetCompany';
import { secureUrl } from '~/utils/TextUtils';

export class ExtranetCompanyFactory extends BaseFactory<ReadExtranetCompanyDto, ExtranetCompany> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadExtranetCompanyDto): ExtranetCompany {
        const model = new ExtranetCompany();

        model.city = dto.city;
        model.cocNumber = dto.cocNumber;
        model.email = dto.email;
        model.houseNumber = dto.houseNumber || null;
        model.houseNumberPostfix = dto.houseNumberPostfix || null;
        model.id = Number(dto.id);
        model.mailbox = dto.mailbox || null;
        model.mailboxCity = dto.mailboxCity || null;
        model.mailboxPostal = dto.mailboxPostal || null;
        model.name = dto.name;
        model.phone = dto.phone;
        model.postal = dto.postal || null;
        model.street = dto.street || null;
        model.type = dto.type;
        model.website = secureUrl(dto.website);

        return model;
    }
}
