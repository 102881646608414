import type { Attachment } from '~/models/Attachment';

export class ExtranetCertificateDetail {
    public id: number | null = null;

    public certificateStatus: string | null = null;

    public chamberName: string | null = null;

    public certificateNumber: number | null = null;

    public attachments: Attachment[] | null = null;
}

