import { PointsCombinationChamber } from '../PointsCombinationChamber';
import { BaseFactory } from './BaseFactory';
import type { ReadPointsCombinationChamberDto } from '~/types/PointsCombinationChamber';

export class PointsCombinationChamberFactory
    extends BaseFactory<ReadPointsCombinationChamberDto, PointsCombinationChamber> {
    public toModel(dto: ReadPointsCombinationChamberDto): PointsCombinationChamber {
        const model = new PointsCombinationChamber();

        model.id = dto.id || null;

        model.chambers = dto.chambers || null;

        return model;
    }
}
