import {
    GET_FILTERS,
    GET_FILTER_VALUE,
    GET_ITEMS,
    GET_LOADING,
    GET_ORDER_BY,
    GET_PAGE,
    GET_PAGINATION,
    GET_PARAMETERS,
    GET_PER_PAGE,
    GET_QUERY_STRING,
    GET_SORT_DESCENDING,
    HAS_DATAGRID,
    HAS_FILTERS,
} from './-getter-types';
import type { PaginationResponse } from '~/types/Meta';
import { useDataGridsStore } from '~/stores/data-grids';

const Getters = {
    [GET_FILTER_VALUE]: (): (
        identifier: string,
        property: string,
    ) => string | number => {
        const dataGridsStore = useDataGridsStore();

        return (identifier: string, property: string) => dataGridsStore
            .dataGrids[identifier]
            ?.filters[property] ?? null;
    },
    [GET_FILTERS]: (): (identifier: string) => {[key: string]: string | number} => {
        const dataGridsStore = useDataGridsStore();

        return (identifier: string) => dataGridsStore
            .dataGrids[identifier]
            ?.filters ?? {};
    },
    [GET_ITEMS]: (): (identifier: string) => unknown => {
        const dataGridsStore = useDataGridsStore();

        return (identifier: string) => dataGridsStore
            .dataGrids[identifier]
            ?.items;
    },
    [GET_LOADING]: (): (identifier: string) => boolean => {
        const dataGridsStore = useDataGridsStore();

        return (identifier: string) => dataGridsStore
            .dataGrids[identifier]
            ?.loading;
    },
    [GET_ORDER_BY]: (): (identifier: string) => string | string[] | null => {
        const dataGridsStore = useDataGridsStore();

        return (identifier: string) => dataGridsStore
            .dataGrids[identifier]
            ?.orderBy;
    },
    [GET_PAGE]: (): (identifier: string) => number => {
        const dataGridsStore = useDataGridsStore();

        return (identifier: string) => dataGridsStore
            .dataGrids[identifier]
            ?.page;
    },
    [GET_PAGINATION]: (): (identifier: string) => PaginationResponse => {
        const dataGridsStore = useDataGridsStore();

        return (identifier: string) => dataGridsStore
            .dataGrids[identifier]
            ?.pagination;
    },
    [GET_PARAMETERS]: (): (identifier: string) => {[key: string]: string | number} => {
        const dataGridsStore = useDataGridsStore();

        return (identifier: string) => dataGridsStore
            .dataGrids[identifier]
            ?.parameters ?? {};
    },
    [GET_PER_PAGE]: (): (identifier: string) => number => {
        const dataGridsStore = useDataGridsStore();

        return (identifier: string) => dataGridsStore
            .dataGrids[identifier]
            ?.perPage;
    },
    [GET_QUERY_STRING]: (): (identifier: string) => string | null => {
        const dataGridsStore = useDataGridsStore();

        return (identifier: string) => dataGridsStore
            .dataGrids[identifier]
            ?.queryString;
    },
    [GET_SORT_DESCENDING]: (): (identifier: string) => number | null => {
        const dataGridsStore = useDataGridsStore();

        return (identifier: string) => dataGridsStore
            .dataGrids[identifier]
            ?.sortDescending;
    },
    [HAS_DATAGRID]: (): (identifier: string) => boolean => {
        const dataGridsStore = useDataGridsStore();

        return (identifier: string) => identifier in dataGridsStore.dataGrids;
    },
    [HAS_FILTERS]: (): (identifier: string) => boolean => {
        const dataGridsStore = useDataGridsStore();

        return (identifier: string) => Object.keys(dataGridsStore[GET_FILTERS](identifier)).length > 0;
    },
};

export default Getters;
