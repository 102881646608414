import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type AssessmentsOverview,
    type AssessmentsOverviewResponse,
    type WriteAssessmentDto,
} from '~/types/Assessment';
import { AssessmentFactory } from '~/models/factories/AssessmentFactory';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';

export default class AssessmentService extends BaseApiService {
    public static basePath = 'assessments';

    /**
     * @description Delete an assessment.
     * @param {number} assessmentId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(assessmentId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(AssessmentService.basePath)}/${assessmentId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple Assessments depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @param {string} path optional sub path for fetch Assessments
     * @returns {Promise<AssessmentsOverview>} Promise with the AssessmentsOverview as payload
     */
    async fetchAssessments(queryParameters = null, path = ''): Promise<AssessmentsOverview> {
        try {
            const response = await this.baseGet<AssessmentsOverviewResponse>(
                `${this.createPath(AssessmentService.basePath)}/${path}`,
                queryParameters,
            );

            const data = (new AssessmentFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Add a mass assessment.
     * @param {WriteAssessmentDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    async add(data: WriteAssessmentDto): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(AssessmentService.basePath)}`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Download a CSV file with all the assessments.
     * @returns {Promise<void>} Returns the CSV file
     */
    async downloadAssessments(): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(AssessmentService.basePath)}/download`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }
}
