import { BaseFactory } from './BaseFactory';
import type { ReadExtranetDocumentDto } from '~/types/ExtranetDocument';
import { ExtranetDocument } from '~/models/ExtranetDocument';

export class ExtranetDocumentFactory extends BaseFactory<ReadExtranetDocumentDto, ExtranetDocument> {
    public toModel(dto: ReadExtranetDocumentDto): ExtranetDocument {
        const model = new ExtranetDocument();

        model.filename = dto.filename || null;
        model.typeName = dto.typeName || null;
        model.id = Number(dto.id);
        model.createdAt = dto.createdAt || null;

        return model;
    }
}
