export class Assessment {
    public id: number | null = null;

    public relationId: number | null = null;

    public relationName: string | null = null;

    public importDate: string | null = null;

    public checkedDate: string | null = null;

    public year: number | null = null;

    public quarter: number | null = null;

    public createdAt: string | null = null;

    public updatedAt: string | null = null;
}
