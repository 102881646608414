import { BaseFactory } from './BaseFactory';
import { CompanyFactory } from './CompanyFactory';
import { DispensationFactory } from './DispensationFactory';
import { RelationMovementFactory } from './RelationMovementFactory';
import { RelationStatusFactory } from './RelationStatusFactory';
import { RemarkFactory } from './RemarkFactory';
import { SurveyListParticipantsFactory } from './SurveyListParticipants';
import { AssessmentFactory } from './AssessmentFactory';
import { TradeOrganisationFactory } from './TradeOrganisationFactory';
import { UserFactory } from './UserFactory';
import { type ReadRelationDto } from '~/types/Relation';
import { Relation } from '~/models/Relation';

export class RelationFactory extends BaseFactory<ReadRelationDto, Relation> {
    /* eslint-disable-next-line max-statements, complexity, max-lines-per-function */
    public toModel(dto: ReadRelationDto): Relation {
        const model = new Relation();

        model.accountNumber = dto.accountNumber;
        model.active = Boolean(dto.active);
        model.appraisalreportDisabled = Boolean(dto.appraisalreportDisabled);
        model.city = dto.city || null;
        model.comment = dto.comment || null;

        // eslint-disable-next-line no-undefined
        if (dto.companion !== undefined) {
            model.companion = (new RelationFactory()).toModel(dto.companion.data);
        }
        model.companionId = dto.companionId ? Number(dto.companionId) : null;
        if (dto.companionOf) {
            model.companionOf = (new RelationFactory()).toModels(dto.companionOf.data);
        }

        model.company = dto.company;
        model.companyCity = dto.companyCity || null;
        model.companyId = Number(dto.companyId);

        // eslint-disable-next-line no-undefined
        if (dto.companies !== undefined) {
            model.companies = (new CompanyFactory()).toModels(dto.companies.data);
        }

        model.costCentre = dto.costCentre || null;
        model.dateOfBirth = dto.dateOfBirth;

        // eslint-disable-next-line no-undefined
        if (dto.dispensations !== undefined) {
            model.dispensations = (new DispensationFactory()).toModels(dto.dispensations.data);
        }

        model.email = dto.email || null;
        model.emailInvoice = dto.emailInvoice || null;
        model.excludeFromAddresscheckInvitation = dto.excludeFromAddresscheckInvitation;
        model.exportToSmile = dto.exportToSmile;
        model.exportToSmileShow = dto.exportToSmileShow;
        model.exportToSmileText = dto.exportToSmileText;
        model.fullName = dto.fullName || null;
        model.gender = dto.gender;
        model.houseNumber = dto.houseNumber || null;
        model.houseNumberPostfix = dto.houseNumberPostfix || null;
        model.id = Number(dto.id);
        model.initials = dto.initials;
        model.invoiceAttn = dto.invoiceAttn || null;
        model.invoicePreferenceAddress = dto.invoicePreferenceAddress || null;
        model.invoicePreferenceAddresses = dto.invoicePreferenceAddresses || null;
        model.invoicePreferenceAddressTranslated = dto.invoicePreferenceAddressTranslated || null;
        model.purchaseOrderNumber = dto.purchaseOrderNumber || null;
        model.isCompanion = Boolean(dto.isCompanion);
        model.isPlausible = Boolean(dto.isPlausible);

        // eslint-disable-next-line no-undefined
        if (dto.importantRemarks !== undefined) {
            model.importantRemarks = (new RemarkFactory()).toModels(dto.importantRemarks.data);
        }

        model.lastName = dto.lastName;
        model.loginEmail = dto.loginEmail || null;
        model.lat = dto.lat || null;
        model.linkedin = dto.linkedin || null;
        model.loginEmail = dto.loginEmail || null;
        model.lng = dto.lng || null;
        model.middleName = dto.middleName;

        // eslint-disable-next-line no-undefined
        if (dto.movements !== undefined) {
            model.movements = (new RelationMovementFactory()).toModels(dto.movements.data);
        }

        model.noAnnualInvoice = Boolean(dto.noAnnualInvoice);
        model.optinChecked = Boolean(dto.optinChecked);
        model.per = dto.per || null;
        model.phone = dto.phone || null;
        model.phoneMobile = dto.phoneMobile || null;
        model.photo = dto.photo || null;
        model.placeOfBirth = dto.placeOfBirth || null;
        model.postal = dto.postal || null;
        model.postfix = dto.postfix || null;
        model.prefix = dto.prefix || null;
        model.province = dto.province || null;
        model.purchaseOrderNumber = dto.purchaseOrderNumber || null;

        if (
            // eslint-disable-next-line no-undefined
            dto.relationStatus !== undefined &&
            dto.relationStatus?.data
        ) {
            model.relationStatus = (new RelationStatusFactory()).toModel(dto.relationStatus.data);
        // eslint-disable-next-line no-undefined
        } else if (dto.relationStatus !== undefined) {
            model.relationStatusText = dto.relationStatus;
        }

        model.relationStatusId = dto.relationStatusId ? Number(dto.relationStatusId) : null;
        model.relationTypeId = dto.relationTypeId ? Number(dto.relationTypeId) : null;
        model.ricsNumber = dto.ricsNumber || null;
        model.rtNumber = dto.rtNumber;
        model.salutation = dto.salutation || null;
        model.scvmId = dto.scvmId || null;
        model.scvmNumber = dto.scvmNumber || null;
        model.showOptin = Boolean(dto.showOptin);
        model.statusText = dto.statusText || null;
        model.street = dto.street || null;

        // eslint-disable-next-line no-undefined
        if (dto.surveylistParticipants !== undefined && dto.surveylistParticipants !== null) {
            model.surveylistParticipants =
                (new SurveyListParticipantsFactory()).toModels(dto.surveylistParticipants.data);
        }

        // eslint-disable-next-line no-undefined
        if (dto.tradeOrganisations !== undefined) {
            model.tradeOrganisations = (new TradeOrganisationFactory()).toModels(dto.tradeOrganisations.data);
        }

        // eslint-disable-next-line no-undefined
        if (dto.assessments !== undefined) {
            model.assessments = (new AssessmentFactory()).toModels(dto.assessments.data);
        }

        // eslint-disable-next-line no-undefined
        if (dto.user !== undefined && dto.user.data.id !== undefined) {
            model.user = (new UserFactory()).toModel(dto.user.data);
        }

        model.uuid = dto.uuid || null;
        model.vNumber = dto.vNumber || null;
        model.vog = Boolean(dto.vog);
        model.showDocumentsChecked = Boolean(dto.showDocumentsChecked);

        model.invoiceCity = dto.invoiceCity || null;
        model.invoiceHouseNumber = dto.invoiceHouseNumber || null;
        model.invoiceHouseNumberPostfix = dto.invoiceHouseNumberPostfix || null;
        model.invoicePostal = dto.invoicePostal || null;
        model.invoiceStreet = dto.invoiceStreet || null;
        model.invoiceMailbox = dto.invoiceMailbox || null;
        model.invoiceMailboxPostal = dto.invoiceMailboxPostal || null;
        model.invoiceMailboxCity = dto.invoiceMailboxCity || null;
        model.masterCertificationDate = dto.masterCertificationDate || null;
        model.openForMaster = dto.openForMaster;

        return model;
    }
}
