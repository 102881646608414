import validate from "/usr/local/jenkins/workspace/appnrvtnl/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_stylelint@16.10.0_typescript@5.5.4_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45setroutenamespace_45global from "/usr/local/jenkins/workspace/appnrvtnl/middleware/01.setroutenamespace.global.ts";
import _02_45authorization_45global from "/usr/local/jenkins/workspace/appnrvtnl/middleware/02.authorization.global.js";
import _03_45setbreadcrumbs_45global from "/usr/local/jenkins/workspace/appnrvtnl/middleware/03.setbreadcrumbs.global.ts";
import _04_45setsaveurl_45global from "/usr/local/jenkins/workspace/appnrvtnl/middleware/04.setsaveurl.global.ts";
import manifest_45route_45rule from "/usr/local/jenkins/workspace/appnrvtnl/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_stylelint@16.10.0_typescript@5.5.4_vite@5.4.11/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45setroutenamespace_45global,
  _02_45authorization_45global,
  _03_45setbreadcrumbs_45global,
  _04_45setsaveurl_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/local/jenkins/workspace/appnrvtnl/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.1_next-auth@4.21.1/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}