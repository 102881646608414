import { nanoid } from 'nanoid';
import { BaseFactory } from './BaseFactory';
import type { ReadInvoiceLineDto, WriteInvoiceLineDto } from '~/types/InvoiceLine';
import { InvoiceLine } from '~/models/InvoiceLine';

export class InvoiceLineFactory extends BaseFactory<ReadInvoiceLineDto, InvoiceLine> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadInvoiceLineDto): InvoiceLine {
        const model = new InvoiceLine();

        model.id = Number(dto.id);
        model.amount = Number(dto.amount);
        model.invoiceId = Number(dto.invoiceId);
        model.productCode = dto.productCode;
        model.productId = Number(dto.productId);
        model.productName = dto.productName;
        model.description = dto.description;
        model.productPrice = Number(dto.productPrice);
        model.subtotal = Number(dto.subtotal);
        model.taxRate = Number(dto.taxRate);

        return model;
    }

    public toWriteDto(invoiceLine: InvoiceLine): WriteInvoiceLineDto {
        const dto: WriteInvoiceLineDto = {
            amount: Number(invoiceLine.amount),
            description: String(invoiceLine.description),
            id: invoiceLine.id ? Number(invoiceLine.id) : null,
            productCode: String(invoiceLine.productCode),
            productId: Number(invoiceLine.productId),
            productName: String(invoiceLine.productName),
            productPrice: Number(invoiceLine.productPrice),
            subtotal: Number(invoiceLine.subtotal),
            taxRate: Number(invoiceLine.taxRate),
            uid: nanoid(),
        };

        return dto;
    }
}
