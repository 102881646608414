import { BaseFactory } from './BaseFactory';
import type { ReadLogDto } from '~/types/Log';
import { Log } from '~/models/Log';

export class LogFactory extends BaseFactory<ReadLogDto, Log> {
    public toModel(dto: ReadLogDto): Log {
        const model = new Log();

        model.date = dto.date;
        model.username = dto.username;
        model.logMessage = dto.logMessage;

        return model;
    }
}
