import { BaseFactory } from './BaseFactory';
import type { ReadExtranetCertificateDto } from '~/types/ExtranetCertificate';
import { ExtranetCertificate } from '~/models/ExtranetCertificate';

export class ExtranetCertificateFactory extends BaseFactory<ReadExtranetCertificateDto, ExtranetCertificate> {
    public toModel(dto: ReadExtranetCertificateDto): ExtranetCertificate {
        const model = new ExtranetCertificate();

        model.chamberName = dto.chamberName || null;
        model.id = Number(dto.id);
        model.years = dto.years || null;
        model.unsubscribeDate = dto?.unsubscribeDate;
        model.requestUnsubscribeDate = dto?.requestUnsubscribeDate;

        return model;
    }
}
