import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type ExtranetInvoicesOverview,
    type ExtranetInvoicesOverviewResponse,
} from '~/types/ExtranetInvoice';
import { ExtranetInvoiceFactory } from '~/models/factories/ExtranetInvoiceFactory';

export default class ExtranetInvoiceService extends BaseApiService {
    public static basePath = 'invoices';

    /**
     * @description Fetch multiple ExtranetInvoices depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<ExtranetInvoicesOverview>} Promise with the ExtranetInvoiceOverview as payload
     */
    async fetchExtranetInvoices(queryParameters = null): Promise<ExtranetInvoicesOverview> {
        try {
            const response = await this.baseGet<ExtranetInvoicesOverviewResponse>(
                this.createPath(ExtranetInvoiceService.basePath),
                queryParameters,
            );
            const data = (new ExtranetInvoiceFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch single invoice by invoice id.
     * @param {number} invoiceId Identifier of the invoice to fetch
     * @returns {Promise<void>} Returns the invoice file
     */
    async fetchInvoice(invoiceId: number): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(ExtranetInvoiceService.basePath)}/${invoiceId}/download`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }
}
