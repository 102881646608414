import { formatCurrency } from '~/utils/TextUtils';

export class InvoiceCode {
    public id: number | null = null;

    public code: string | null = null;

    public name: string | null = null;

    public price: number | null = null;

    public taxRate: string | null = null;

    public taxRateId: number | null = null;

    public ledgerNumber: string | null = null;

    public getPriceFormatted(): string {
        return formatCurrency(Number(this.price));
    }
}
