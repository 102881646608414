import { BaseFactory } from './BaseFactory';
import type { ReadRelationTradeOrganisationDto } from '~/types/RelationTradeOrganisation';
import { RelationTradeOrganisation } from '~/models/RelationTradeOrganisation';

export class RelationTradeOrganisationFactory
    extends BaseFactory<ReadRelationTradeOrganisationDto, RelationTradeOrganisation> {
    public toModel(dto: ReadRelationTradeOrganisationDto): RelationTradeOrganisation {
        const model = new RelationTradeOrganisation();

        model.tradeOrganisationName = dto.name;
        model.id = Number(dto.id);
        model.number = dto.number || null;
        model.updatedAt = dto.updatedAt || null;

        return model;
    }
}
