import { type FetchError } from 'ofetch';
import { type List } from '~/models/List';
import { ListFactory } from '~/models/factories/ListFactory';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type DashboardDetailQueryParametersDto,
    type DashboardDetailResponse,
    type DashboardOverviewResponse,
    type PermanentEducationYearBucketDto,
    type ReadDashboardDetailDto,
    type ReadDashboardOverviewDto,
} from '~/types/Dashboard';
import { type ListResponse } from '~/types/List';
import type {
    PermanentEducationBacklogPreviousYearsResponse,
    PermanentEducationBacklogResponse,
    PermanentEducationOverviewResponse,
    ReadPermanentEducationBacklogDto,
    ReadPermanentEducationBacklogPreviousYearsDto,
} from '~/types/dashboard/PermanentEducation';
import type { PermanentEducationYearBucketOverview } from '~/types/dashboard/PermanentEducationYearBucket';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type { ServiceQueryParameters } from '~/types/Service';

export default class DashboardService extends BaseApiService {
    public static basePath = 'dashboard';

    /**
     * @description Fetch dashboard details.
     * @returns {Promise<ReadDashboardOverviewDto>} Promise with the overview DTO
     */
    async fetchOverview(): Promise<ReadDashboardOverviewDto> {
        try {
            const response = await this.baseGet<DashboardOverviewResponse>(
                this.createPath(DashboardService.basePath),
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch dashboard details with optional from-to date.
     * @param {string} type Type of the dashboard to fetch
     * @param {Array} queryParameters Optional filters to query the results on
     * @returns {Promise<ReadDashboardDetailDto>} Promise with the Detail DTO
     */
    async fetchDetails(
        type: 'new' | 'active' | 'unsubscribed',
        queryParameters: DashboardDetailQueryParametersDto,
    ): Promise<ReadDashboardDetailDto> {
        try {
            const response = await this.baseGet<DashboardDetailResponse>(
                `${this.createPath(DashboardService.basePath)}/details/${type}`,
                queryParameters,
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchPermanentEducationOverview(): Promise<PermanentEducationOverviewResponse> {
        try {
            const response = await this.baseGet<PermanentEducationOverviewResponse>(
                `${this.createPath(DashboardService.basePath)}/peoverview`,
            );

            return response;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchPermanentEducationBacklog(year?: number): Promise<ReadPermanentEducationBacklogDto> {
        try {
            const response = await this.baseGet<PermanentEducationBacklogResponse>(
                `${this.createPath(DashboardService.basePath)}/peoverview/points-backlog`,
                {
                    year,
                },
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchPermanentEducationBacklogPreviousYears(): Promise<ReadPermanentEducationBacklogPreviousYearsDto> {
        try {
            const response = await this.baseGet<PermanentEducationBacklogPreviousYearsResponse>(
                `${this.createPath(DashboardService.basePath)}/peoverview/points-backlog-previous-years`,
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchPermanentEducationYearBucket({
        bucket,
        queryParameters,
        year,
    }: PermanentEducationYearBucketDto & ServiceQueryParameters): Promise<PermanentEducationYearBucketOverview> {
        try {
            return await this.baseGet<PermanentEducationYearBucketOverview>(
                `${this.createPath(DashboardService.basePath)}/peoverview/${year}/${bucket}`,
                queryParameters,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async downloadPermanentEducationYearBucket({
        bucket,
        year,
    }: PermanentEducationYearBucketDto) {
        try {
            return await this.baseDownload(
                `${this.createPath(DashboardService.basePath)}/peoverview/${year}/${bucket}/export`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchPermanentEducationYearsList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(DashboardService.basePath)}/peoverview/years/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchPermanentEducationBucketsList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(DashboardService.basePath)}/peoverview/buckets/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
