import type { Attachment } from '~/models/Attachment';

export class RelationMovement {
    public id: number | null = null;

    public name?: string | null = null;

    public postal: string | null = null;

    public city: string | null = null;

    public street: string | null = null;

    public houseNumber: string | null = null;

    public houseNumberPostfix: string | null = null;

    public email: string | null = null;

    public phone: string | null = null;

    public phoneMobile: string | null = null;

    public movementDate: string | null = null;

    public website?: string | null = null;

    public mailbox?: string | null = null;

    public mailboxCity?: string | null = null;

    public mailboxPostal?: string | null = null;

    public cocNumber?: string | null = null;

    public establishmentNumber?: string | null = null;

    public attachments?: Attachment[] | null = null;
}
