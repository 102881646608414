import { RelationMovementList } from '../RelationMovementList';
import { BaseFactory } from './BaseFactory';
import type { ReadRelationMovementListDto } from '~/types/RelationMovement';

export class RelationMovementListFactory extends BaseFactory<ReadRelationMovementListDto, RelationMovementList> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadRelationMovementListDto): RelationMovementList {
        const model = new RelationMovementList();

        model.city = dto.city;
        model.email = dto.email;
        model.fullName = dto.fullName;
        model.id = dto.id;
        model.per = dto.per;
        model.phone = dto.phone;
        model.relationId = Number(dto.relationId);
        model.status = dto.status;
        model.statusText = dto.statusText;

        return model;
    }
}
