import { BaseFactory } from './BaseFactory';
import type { ReadActivityDto } from '~/types/Activity';
import { Activity } from '~/models/Activity';

export class ActivityFactory extends BaseFactory<ReadActivityDto, Activity> {
    public toModel(dto: ReadActivityDto): Activity {
        const model = new Activity();

        model.id = dto.id ?? Number(dto.id);
        model.activityTypeId = dto.activityType && Number(dto.activityType.data.id);
        model.points = dto.points ?? Number(dto.points);

        model.date = dto.date || null;
        model.description = dto.description || null;
        model.other = dto.other || null;

        return model;
    }
}
