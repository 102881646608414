import { BaseFactory } from './BaseFactory';
import { PointsYear } from '~/models/PointsYear';
import type { ReadPointsYearDto } from '~/types/PointsYear';

export class PointsYearFactory extends BaseFactory<ReadPointsYearDto, PointsYear> {
    public toModel(dto: ReadPointsYearDto): PointsYear {
        const model = new PointsYear();

        model.label = dto.label ? Number(dto.label) : null;
        model.passed = Boolean(dto.passed);
        model.value = dto.value ? Number(dto.value) : null;

        return model;
    }
}
