import type { SurveyList } from './SurveyList';

export class SurveyListParticipants {
    public id: number | null = null;

    public endedDate: string | null = null;

    public startedDate: string | null = null;

    public step: number | null = null;

    public surveyProgress: number | null = null;

    public uuid: string | null = null;

    public surveylist: SurveyList | null = null;
}
