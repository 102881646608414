import { BaseFactory } from './BaseFactory';
import type { ReadExtranetInvoiceDto } from '~/types/ExtranetInvoice';
import { ExtranetInvoice } from '~/models/ExtranetInvoice';

export class ExtranetInvoiceFactory extends BaseFactory<ReadExtranetInvoiceDto, ExtranetInvoice> {
    public toModel(dto: ReadExtranetInvoiceDto): ExtranetInvoice {
        const model = new ExtranetInvoice();

        model.id = dto.id || null;

        model.invoiceNumber = dto.invoiceNumber || null;

        model.description = dto.description || null;

        model.totalAmount = dto.totalAmount || null;

        model.date = dto.date || null;

        model.status = dto.status || null;

        model.paymentLink = dto.paymentLink || null;

        return model;
    }
}
