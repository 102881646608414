import { BaseFactory } from './BaseFactory';
import type { ReadDisciplinaryLawDto } from '~/types/DisciplinaryLaw';
import { DisciplinaryLaw } from '~/models/DisciplinaryLaw';

export class DisciplinaryLawFactory extends BaseFactory<ReadDisciplinaryLawDto, DisciplinaryLaw> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadDisciplinaryLawDto): DisciplinaryLaw {
        const model = new DisciplinaryLaw();

        model.relationId = dto.relationId ?? Number(dto.relationId);
        model.id = dto.id ?? Number(dto.id);
        model.disciplinaryLawStatusId = dto.disciplinaryLawStatusId ?? Number(dto.disciplinaryLawStatusId);

        model.disciplinaryLawStatusName = dto.disciplinaryLawStatusName || null;
        model.fromDate = dto.fromDate || null;
        model.tillDate = dto.tillDate || null;
        model.comment = dto.comment || null;
        model.createdAt = dto.createdAt || null;
        model.attachments = dto?.attachments || [];

        return model;
    }
}
