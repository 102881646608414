import type { Relation } from './Relation';
import type { SurveyList } from './SurveyList';
import type { SurveyListAnswer } from './SurveyListAnswer';

export class SurveyListAnswers {
    public dateStarted: string | null = null;

    public dateEnded: string | null = null;

    public relation: Relation | null = null;

    public surveylist: SurveyList | null = null;

    public answers: SurveyListAnswer[] | null = null;
}
