import type { CertificateState } from './CertificateState';
import type { Chamber } from './Chamber';
import type { Period } from './Period';
import type { Relation } from './Relation';
import type { Department } from './Department';

export class Certificate {
    public certificateNumber: string | null = null;

    public certificateState: CertificateState | null = null;

    public chamber: Chamber | string | null = null;

    public department: Department | string | null = null;

    public exportForValidation = false;

    public id: number | null = null;

    public period: string | null = null;

    public periodId: number | null = null;

    public periods: Period[] | null = null;

    public relation: Relation | null = null;

    public relationFullName: string | null = null;

    public relationId: number | null = null;

    public relationRTNumber: string | null = null;

    public requestUnsubscribeDate: string | null = null;

    public sentEndingReminder = false;

    public status: string | null = null;

    public unsubscribeDate: string | null = null;

    public unsubscribeReason: string | null = null;
}
