import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type ActivityTypeResponse,
    type ActivityTypesOverview,
    type ActivityTypesOverviewResponse,
    type WriteActivityTypeDto,
} from '~/types/ActivityType';
import { ActivityTypeFactory } from '~/models/factories/ActivityTypeFactory';
import { type ActivityType } from '~/models/ActivityType';
import { type List } from '~/models/List';
import { type ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';

export default class ActivityTypeService extends BaseApiService {
    public static basePath = 'activity-types';

    /**
     * @description Delete a ActivityType.
     * @param {number} activitytypeId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(activitytypeId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(ActivityTypeService.basePath)}/${activitytypeId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new ActivityType.
     * @param {WriteActivityTypeDto} data Form data to post
     * @returns {Promise<ActivityType>} Api response
     */
    async create(data: WriteActivityTypeDto): Promise<ActivityType> {
        const response = await this.basePost<ActivityTypeResponse>(
            this.createPath(ActivityTypeService.basePath),
            data,
        );

        return (new ActivityTypeFactory()).toModel(response.data);
    }

    /**
     * @description Fetch multiple ActivityTypes depending on values in queryString.
     * @param {any} queryParameters Object of query parameters
     * @returns {Promise<ActivityTypesOverview>} Promise with the ActivityTypeOverview as payload
     */
    async fetchActivityTypes(queryParameters = null): Promise<ActivityTypesOverview> {
        try {
            const response = await this.baseGet<ActivityTypesOverviewResponse>(
                this.createPath(ActivityTypeService.basePath),
                queryParameters,
            );
            const activitytypeData = (new ActivityTypeFactory()).toModels(response.data);
            const metaData = response.meta;

            return {
                data: activitytypeData,
                meta: metaData,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single ActivityType by ActivityType id.
     * @param {number} activitytypeId Identifier of the ActivityType to fetch
     * @returns {Promise<ActivityType>} Promise with the ActivityType model as payload
     */
    async fetchActivityType(activitytypeId: number): Promise<ActivityType> {
        try {
            const response = await this.baseGet<ActivityTypeResponse>(
                `${this.createPath(ActivityTypeService.basePath)}/${activitytypeId}`,
            );

            return (new ActivityTypeFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of ActivityType.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchActivityTypeList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(ActivityTypeService.basePath)}/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a ActivityType.
     * @param {number} activitytypeId Id of the resource to save
     * @param {WriteActivityTypeDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(activitytypeId: number, data: WriteActivityTypeDto): Promise<null> {
        return this.basePatch<null>(
            `${this.createPath(ActivityTypeService.basePath)}/${activitytypeId}`,
            data,
        );
    }
}
