import { BaseFactory } from './BaseFactory';
import type { ReadRelationCertificateDto } from '~/types/RelationCertificate';
import { RelationCertificate } from '~/models/RelationCertificate';

export class RelationCertificateFactory extends BaseFactory<ReadRelationCertificateDto, RelationCertificate> {
    public toModel(dto: ReadRelationCertificateDto): RelationCertificate {
        const model = new RelationCertificate();

        model.id = dto.id ?? Number(dto.id);
        model.periodId = dto.periodId ?? Number(dto.periodId);

        model.chamber = dto.chamber || null;
        model.period = dto.period || null;
        model.status = dto.status || null;
        model.unsubscribeDate = dto.unsubscribeDate || null;

        return model;
    }
}
