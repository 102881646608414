export class PointsChamberCombination {
    public id: number | null = null;

    public name: string | null = null;

    public code: string | null = null;

    public peCombinationId: number | null = null;

    public chamberId: number | null = null;

    public required: number | null = null;

    public free: number | null = null;

    public facultative: number | null = null;
}
