import { RelationCourse } from '../RelationCourse';
import { BaseFactory } from './BaseFactory';
import { Attachment } from '~/models/Attachment';
import type { ReadRelationCourseDto } from '~/types/RelationCourse';

export class RelationCourseFactory extends BaseFactory<ReadRelationCourseDto, RelationCourse> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadRelationCourseDto): RelationCourse {
        const model = new RelationCourse();

        model.applicableYear = Number(dto.applicableYear);
        model.chamber = dto.chamber;
        model.courseCode = dto.courseCode;
        model.courseDate = dto.courseDate;
        model.courseName = dto.courseName;
        model.courseId = Number(dto.courseId);
        model.id = Number(dto.id);
        model.isActivity = dto.isActivity;
        model.points = Number(dto.points);
        model.relationId = Number(dto.relationId);
        model.source = dto.source;
        model.trainingPartner = dto.trainingPartner || null;

        if (dto?.attachments) {
            const attachmentData: Attachment[] = [];

            for (const attachment of dto.attachments) {
                const attachmentModel = new Attachment();
                attachmentModel.id = Number(attachment.id);
                attachmentModel.filename = attachment.filename;
                attachmentModel.type = attachment.type;
                attachmentModel.visibleOnExtranet = attachment.visibleOnExtranet;
                attachmentModel.createdAt = attachment.createdAt;

                attachmentData.push(attachmentModel);
            }

            model.attachments = attachmentData;
        }

        return model;
    }
}
