export enum FetchInvoices {
    ALL = '',
    ANNUAL = 'annual-invoices',
    DELETED = 'deleted-invoices',
    OPEN = 'open-invoices',
    PAID = 'paid-invoices',
    UNPAID = 'unpaid-invoices',
    OPEN_REMINDER = 'open-invoices-not-queued',
    UNPAID_REMINDER = 'unpaid-invoices-not-queued',
}
