import type { DispensationType } from './DispensationType';

export class Dispensation {
    public dispensationType: DispensationType | null = null;

    public dispensationTypeName: string | null = null;

    public id: number | null = null;

    public year: number | null = null;
}
