export class ExtranetCompany {
    public city: string | null = null;

    public cocNumber: string | null = null;

    public email: string | null = null;

    public houseNumber: string | null = null;

    public houseNumberPostfix: string | null = null;

    public id: number | null = null;

    public mailbox: string | null = null;

    public mailboxCity: string | null = null;

    public mailboxPostal: string | null = null;

    public name: string | null = null;

    public phone: string | null = null;

    public postal: string | null = null;

    public street: string | null = null;

    public type: string | null = null;

    public website: string | null = null;

    public getWebsiteHref(): string {
        return `${((/(http(s?)):\/\//i).test(this.website || '') ? this.website : `//${this.website}`)}`;
    }
}
