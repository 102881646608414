import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { type User } from '~/models/User';
import { UserFactory } from '~/models/factories/UserFactory';
import {
    type UserResponse,
    type UsersOverview,
    type UsersOverviewResponse,
    type WriteUserDto,
} from '~/types/User';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';

export default class UserService extends BaseApiService {
    private basePath = 'users';

    /**
     * @description Fetch single user by user id.
     * @param {number} userId Identifier of the user to fetch
     * @returns {Promise<User>} Promise with the User model as payload
     */
    async fetchUser(userId: number): Promise<User> {
        try {
            const response = await this.baseGet<UserResponse>(
                `${this.createPath(this.basePath)}/${userId}`,
            );

            return (new UserFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple users depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<UsersOverview>} Promise with the UsersOverview as payload
     */
    async fetchUsers(queryParameters = null): Promise<UsersOverview> {
        try {
            const response = await this.baseGet<UsersOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new UserFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new user.
     * @param {WriteUserDto} data Form data to post
     * @returns {Promise<User>} Api response
     */
    async create(data: WriteUserDto): Promise<User> {
        const response = await this.basePost<UserResponse>(
            this.createPath(this.basePath),
            data,
        );

        return (new UserFactory()).toModel(response.data);
    }

    /**
     * @description Update a user.
     * @param {number} userId Id of the resource to save
     * @param {WriteUserDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(userId: number, data: WriteUserDto): Promise<null> {
        return this.basePut<null>(
            `${this.createPath(this.basePath)}/${userId}`,
            data,
        );
    }

    /**
     * @description Delete a user.
     * @param {number} userId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(userId: number): Promise<null> {
        return this.baseDelete(`${this.createPath(this.basePath)}/${userId}`);
    }

    /**
     * @description Reset 2FA for a user.
     * @param {number} userId ID of the user to reset 2FA
     * @returns {Promise<null>} Api response
     */
    reset2Fa(userId: number): Promise<null> {
        return this.basePatch(`${this.createPath(this.basePath)}/${userId}/reset2fa`);
    }
}
