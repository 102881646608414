import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type PointsRequiredYearResponse,
    type PointsRequiredYearsOverview,
    type PointsRequiredYearsOverviewResponse,
    type WritePointsRequiredYearDto,
} from '~/types/PointsRequiredYear';
import { PointsRequiredYearFactory } from '~/models/factories/PointsRequiredYearFactory';
import { type PointsRequiredYear } from '~/models/PointsRequiredYear';
import { type List } from '~/models/List';
import { type ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';

export default class PointsRequiredYearService extends BaseApiService {
    public static basePath = 'pe-required-year';

    /**
     * @description Create a new PointsRequiredYear.
     * @param {WritePointsRequiredYearDto} data Form data to post
     * @returns {Promise<PointsRequiredYear>} Api response
     */
    async create(data: WritePointsRequiredYearDto): Promise<PointsRequiredYear> {
        const response = await this.basePost<PointsRequiredYearResponse>(
            this.createPath(PointsRequiredYearService.basePath),
            data,
        );

        return (new PointsRequiredYearFactory()).toModel(response.data);
    }

    /**
     * @description Fetch multiple PointsRequiredYears depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<PointsRequiredYearsOverview>} Promise with the PointsRequiredYearOverview as payload
     */
    async fetchPointsRequiredYears(queryParameters = null): Promise<PointsRequiredYearsOverview> {
        try {
            const response = await this.baseGet<PointsRequiredYearsOverviewResponse>(
                this.createPath(PointsRequiredYearService.basePath),
                queryParameters,
            );
            const pointsrequiredyearData = (new PointsRequiredYearFactory()).toModels(response.data);
            const metaData = response.meta;

            return {
                data: pointsrequiredyearData,
                meta: metaData,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single PointsRequiredYear by PointsRequiredYear id.
     * @param {number} pointsrequiredyearId Identifier of the PointsRequiredYear to fetch
     * @returns {Promise<PointsRequiredYear>} Promise with the PointsRequiredYear model as payload
     */
    async fetchPointsRequiredYear(pointsrequiredyearId: number): Promise<PointsRequiredYear> {
        try {
            const response = await this.baseGet<PointsRequiredYearResponse>(
                `${this.createPath(PointsRequiredYearService.basePath)}/${pointsrequiredyearId}`,
            );

            return (new PointsRequiredYearFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of PointsRequiredYear.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchPointsRequiredYearList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(PointsRequiredYearService.basePath)}/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a PointsRequiredYear.
     * @param {number} pointsrequiredyearId Id of the resource to save
     * @param {WritePointsRequiredYearDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(pointsrequiredyearId: number, data: WritePointsRequiredYearDto): Promise<null> {
        return this.basePut<null>(
            `${this.createPath(PointsRequiredYearService.basePath)}/${pointsrequiredyearId}`,
            data,
        );
    }
}
