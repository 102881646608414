import type { Company } from './Company';
import type { Dispensation } from './Dispensation';
import type { InvoicePreferenceAddress } from './InvoicePreferenceAddress';
import type { RelationMovement } from './RelationMovement';
import type { RelationStatus } from './RelationStatus';
import type { Remark } from './Remark';
import type { SurveyListParticipants } from './SurveyListParticipants';
import type { Assessment } from './Assessment';
import type { TradeOrganisation } from './TradeOrganisation';
import type { User } from './User';
import DateFormatUtils from '~/utils/DateFormatUtils';

export class Relation {
    public accountNumber: string | null = null;

    public active: boolean | null = null;

    public appraisalreportDisabled: boolean | null = null;

    public city: string | null = null;

    public comment: string | null = null;

    public companies: Company[] = [];

    // @TODO[NRVT-2213]: This is a circular reference
    // eslint-disable-next-line no-use-before-define
    public companion: Relation | null = null;

    public companionId: number | null = null;

    // @TODO[NRVT-2213]: This is a circular reference
    // eslint-disable-next-line no-use-before-define
    public companionOf: Relation[] = [];

    public company: string | null = null;

    public companyCity: string | null = null;

    public companyId: number | null = null;

    public costCentre: string | null = null;

    public dateOfBirth: string | null = null;

    public dispensations: Dispensation[] = [];

    public email: string | null = null;

    public emailInvoice: string | null = null;

    public excludeFromAddresscheckInvitation: boolean | null = null;

    public exportToSmile: boolean | null = null;

    public exportToSmileShow: boolean | null = null;

    public exportToSmileText: string | null = null;

    public fullName: string | null = null;

    public gender: string | null = null;

    public houseNumber: string | null = null;

    public houseNumberPostfix: string | null = null;

    public id: number | null = null;

    public initials: string | null = null;

    public invoiceAttn: string | null = null;

    public invoicePreferenceAddress: string | null = null;

    public invoicePreferenceAddresses: InvoicePreferenceAddress | null = null;

    public invoicePreferenceAddressTranslated: string | null = null;

    public isCompanion: boolean | null = null;

    public importantRemarks: Remark[] = [];

    public isPlausible: boolean | null = null;

    public lastName: string | null = null;

    public lat: string | null = null;

    public linkedin: string | null = null;

    public loginEmail: string | null = null;

    public lng: string | null = null;

    public movements: RelationMovement[] = [];

    public middleName: string | null = null;

    public noAnnualInvoice: boolean | null = null;

    public optinChecked: boolean | null = null;

    public per: string | null = null;

    public phone: string | null = null;

    public phoneMobile: string | null = null;

    public photo: string | null = null;

    public placeOfBirth: string | null = null;

    public postal: string | null = null;

    public postfix: string | null = null;

    public prefix: string | null = null;

    public province: string | null = null;

    public purchaseOrderNumber: string | null = null;

    public relationStatus: RelationStatus | null = null;

    public relationStatusText: unknown | null = null;

    public relationStatusId: number | null = null;

    public relationTypeId: number | null = null;

    public ricsNumber: string | null = null;

    public rtNumber: string | null = null;

    public salutation: string | null = null;

    public scvmId: string | null = null;

    public scvmNumber: string | null = null;

    public showOptin: boolean | null = null;

    public statusText: string | null = null;

    public street: string | null = null;

    public surveylistParticipants: SurveyListParticipants[] = [];

    public tradeOrganisations: TradeOrganisation[] = [];

    public assessments: Assessment[] = [];

    public user: User | null = null;

    public uuid: string | null = null;

    public vNumber: string | null = null;

    public vog: boolean | null = null;

    public showDocumentsChecked: boolean | null = null;

    public invoiceStreet: string | null = null;

    public invoiceHouseNumber: string | null = null;

    public invoiceHouseNumberPostfix: string | null = null;

    public invoicePostal: string | null = null;

    public invoiceCity: string | null = null;

    public invoiceMailbox: string | null = null;

    public invoiceMailboxPostal: string | null = null;

    public invoiceMailboxCity: string | null = null;

    public masterCertificationDate: string | null = null;

    public openForMaster: boolean = false;

    public getDateOfBirth(): string {
        return DateFormatUtils.dateFormatToDisplay(this.dateOfBirth ?? '');
    }

    public getFullName(postfix = false): string {
        let name = '';
        name += (this.initials ? `${this.initials} ` : '');
        name += (this.middleName ? `${this.middleName} ` : '');
        name += (this.lastName ? `${this.lastName}` : '');

        if (postfix) {
            name += (this.postfix ? ` ${this.postfix}` : '');
        }

        return name;
    }

    public getGender(): string {
        return this.gender === 'm' ? 'De heer' : 'Mevrouw';
    }

    public getIsCompanion(): string {
        return this.isCompanion ? 'Ja' : 'Nee';
    }

    public getOpenForMaster() {
        return this.openForMaster ? 'Ja' : 'Nee';
    }

    public getOptinChecked(): string {
        return this.optinChecked ? 'Ja' : 'Nee';
    }

    public getVog(): string {
        return this.vog ? 'Ja' : 'Nee';
    }

    public getFormattedTradeOrganisations(): string {
        return this.tradeOrganisations ? (
            this.tradeOrganisations.map(tradeOrganisation => tradeOrganisation.shortName).join(', ')
        ) : 'Geen';
    }

    public getinvoicePreferenceAddressPersonal(): string {
        return this.invoicePreferenceAddresses?.p.join('<br>').toString() ?? '';
    }

    public getinvoicePreferenceAddressWork(): string {
        return this.invoicePreferenceAddresses?.w.join('<br>').toString() ?? '';
    }
}
