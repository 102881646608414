import { BaseFactory } from './BaseFactory';
import type { MailLogDto } from '~/types/MailLog';
import { MailLog } from '~/models/MailLog';

export class MailLogFactory extends BaseFactory<MailLogDto, MailLog> {
    public toModel(data: MailLogDto): MailLog {
        const model = new MailLog();

        model.id = data.id || null;
        model.dateSent = data.dateSent || null;
        model.trigger = data.trigger || null;
        model.maillog = data.maillog || null;

        return model;
    }

    public toModels(items: MailLogDto[]): MailLog[] {
        return items.map((data: MailLogDto): MailLog => this.toModel(data));
    }
}
