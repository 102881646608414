export class MailLog {
    public id: number | null = null;

    public dateSent: string | null = null;

    public trigger: string | null = null;

    public maillog: {
        data: {
            id: number;
            attachments: string[];
            renderedView: string;
            subject: string;
            to: (string | null)[];
        };
    } | null = null;
}
