export class Activity {
    public id: number | null = null;

    public activityTypeId: number | null = null;

    public other: string | null = null;

    public description: string | null = null;

    public date: string | null = null;

    public points: number | null = 0;
}
