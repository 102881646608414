import { BaseFactory } from './BaseFactory';
import { DispensationTypeFactory } from './DispensationTypeFactory';
import type { ReadDispensationDto } from '~/types/Dispensation';
import { Dispensation } from '~/models/Dispensation';

export class DispensationFactory extends BaseFactory<ReadDispensationDto, Dispensation> {
    public toModel(dto: ReadDispensationDto): Dispensation {
        const model = new Dispensation();

        // eslint-disable-next-line no-undefined
        if (dto.dispensationType !== undefined) {
            model.dispensationType = (new DispensationTypeFactory()).toModel(dto.dispensationType.data);
        }

        model.dispensationTypeName = dto.dispensationTypeName || null;
        model.id = Number(dto.id);
        model.year = dto.year || null;

        return model;
    }
}
