import { format } from 'date-fns';

export class InvoiceLog {
    public createdAt: string | null = null;

    public fullName: string | null = null;

    public id: number | null = null;

    public invoiceId: number | null = null;

    public message: string | null = null;

    public updatedAt: string | null = null;

    public userId: number | null = null;

    public getFormatedCreatedDate(): string {
        return format(new Date(this.createdAt ?? ''), 'd MMMM y k:mm');
    }
}
