import { BaseFactory } from './BaseFactory';
import { AttachmentFactory } from './AttachmentFactory';
import type { ReadExtranetActivityDto } from '~/types/ExtranetActivity';
import { ExtranetActivity } from '~/models/ExtranetActivity';

export class ExtranetActivityFactory extends BaseFactory<ReadExtranetActivityDto, ExtranetActivity> {
    /* eslint-disable-next-line max-statements, complexity */
    public toModel(dto: ReadExtranetActivityDto): ExtranetActivity {
        const model = new ExtranetActivity();

        model.activityTypeId = !model.activityTypeId && dto.activityType?.data ? (
            dto.activityType.data.id
        ) : dto.activityTypeId ?? Number(dto.activityTypeId);

        model.id = dto.id ?? Number(dto.id);
        model.points = dto.points ?? Number(dto.points);

        model.activityTypeString = dto.activityTypeString || null;
        model.date = dto.date || null;
        model.description = dto.description || null;
        model.other = dto.other || null;

        if (dto.attachments) {
            model.attachments = (new AttachmentFactory()).toModels(dto.attachments.data);
        }

        return model;
    }
}
