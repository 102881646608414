import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { DocumentFactory } from '~/models/factories/DocumentFactory';
import { type DocumentsOverview, type DocumentsOverviewResponse } from '~/types/Document';

export default class DownloadService extends BaseApiService {
    public static basePath = 'downloads';

    /**
     * @description Fetch multiple downloads for the currently logged in user.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<DocumentsOverview>} Promise with the DocumentsOverview as payload
     */
    async fetchDocuments(queryParameters = null): Promise<DocumentsOverview> {
        try {
            const response = await this.baseGet<DocumentsOverviewResponse>(
                `${this.createPath(DownloadService.basePath)}`,
                queryParameters,
            );

            const data = (new DocumentFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch single download by document id.
     * @param {number} documentId Identifier of the download to fetch
     * @returns {Promise<void>} Returns the document file
     */
    async downloadDocument(documentId: number): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(DownloadService.basePath)}/${documentId}/download`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }
}
