export class RelationPoints2019 {
    public mandatory: {
        needed: number | null;
        achieved: number | null;
        toAchieve: number | null;
        courses: string[] | [];
    } | null = null;

    public optional: {
        needed: number | null;
        extra: number | null;
        achieved: number | null;
        toAchieve: number | null;
        courses: string[] | [];
    } | null = null;

    public free: {
        needed: number | null;
        extra: number | null;
        achieved: number | null;
        toAchieve: number | null;
        activities: string[] | [];
    } | null = null;

    public total: {
        needed: number | null;
        achieved: number | null;
        toAchieve: number | null;
        extraNextYear: number | null;
        shortageNextYear: {
            chambers: number | null;
            optional: number | null;
            free: number | null;
        } | [];
        extraLastYear: number | null;
        shortageLastYear: {
            chambers: number | null;
            optional: number | null;
            free: number | null;
        } | [];
        totalShortageLastYear: number | null;
        passed: boolean | null;
    } | null = null;

    public year: number | null = null;

    public requiredElearningPassed: boolean | null = false;
}
