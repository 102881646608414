import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { type ExtranetDocumentsOverview, type ExtranetDocumentsOverviewResponse } from '~/types/ExtranetDocument';
import { ExtranetDocumentFactory } from '~/models/factories/ExtranetDocumentFactory';
import { type ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';
import { type List } from '~/models/List';

export default class DocumentService extends BaseApiService {
    public static basePath = 'documents';

    /**
     * @description Fetch multiple Documents for the currently logged in user.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<ExtranetDocumentsOverview>} Promise with the DocumentsOverview as payload
     */
    async fetchDocuments(queryParameters = null): Promise<ExtranetDocumentsOverview> {
        try {
            const response = await this.baseGet<ExtranetDocumentsOverviewResponse>(
                `${this.createPath(DocumentService.basePath)}`,
                queryParameters,
            );

            const data = (new ExtranetDocumentFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch multiple Documents for the currently logged in user.
     * @returns {Promise<List[]>} Promise with the DocumentsOverview as payload
     */
    async fetchDocumentsAttachmentList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath('document-attachments-list')}`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }
}
