import { type Relation } from './Relation';
import { type Role } from './Role';

export class User {
    public active: number | null = null;

    public email: string | null = null;

    public emailVerifiedAt: string | null = null;

    public fullName: string | null = null;

    public id: number | null = null;

    public relation: Relation | null = null;

    public roles: Role[] = [];

    public getRolesList(): string {
        return this.roles.length > 0 ? this.roles.map(role => role.name).join(', ') : '';
    }
}
