/* eslint-disable max-lines */
import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type CertificateResponse,
    type CertificatesOverview,
    type CertificatesOverviewResponse,
    type WriteCertificateDto,
} from '~/types/Certificate';
import { CertificateFactory } from '~/models/factories/CertificateFactory';
import { type Certificate } from '~/models/Certificate';
import { type AttachmentsOverview, type AttachmentsOverviewResponse } from '~/types/Attachment';
import { AttachmentFactory } from '~/models/factories/AttachmentFactory';
import { PeriodFactory } from '~/models/factories/PeriodFactory';
import { type PeriodsOverview, type PeriodsOverviewResponse } from '~/types/Period';
import { type Period } from '~/models/Period';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';

export default class CertificateService extends BaseApiService {
    public static basePath = 'certificates';

    /**
     * @description Cancel or confirm a unsubscribe request.
     * @param {number} certificateId Id of the resource to delete
     * @param {string} action confirm or cancel action
     * @returns {Promise<null>} Api response
     */
    cancelOrConfirmUnsubscribeRequest(certificateId: number, action: 'cancel' | 'confirm'): Promise<null> {
        try {
            return this.basePost<null>(
                `${this.createPath(CertificateService.basePath)}/unsubscribe-requests/${certificateId}/${action}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete a certificate.
     * @param {number} certificateId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(certificateId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(CertificateService.basePath)}/${certificateId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single certificate by certificate id.
     * @param {number} certificateId Identifier of the certificate to fetch
     * @returns {Promise<Certificate>} Promise with the Certificate model as payload
     */
    async fetchCertificate(certificateId: number): Promise<Certificate> {
        try {
            const response = await this.baseGet<CertificateResponse>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}`,
            );

            return (new CertificateFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple certificates depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @param {string} path optional sub path for fetch certificates
     * @returns {Promise<CertificatesOverview>} Promise with the CertificatesOverview as payload
     */
    async fetchCertificates(queryParameters = null, path: string = ''): Promise<CertificatesOverview> {
        try {
            const response = await this.baseGet<CertificatesOverviewResponse>(
                `${this.createPath(CertificateService.basePath)}/${path}`,
                queryParameters,
            );

            const data = (new CertificateFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a certificate.
     * @param {number} certificateId Id of the resource to save
     * @param {WriteCertificateDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(certificateId: number, data: WriteCertificateDto): Promise<null> {
        try {
            return this.basePatch<null>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a certificate.
     * @param {number} certificateId Id of the resource to save
     * @param {WriteCertificateDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    convert(certificateId: number, data: WriteCertificateDto): Promise<null> {
        try {
            return this.basePost<null>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/convert`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a certificate.
     * @param {WriteCertificateDto} data Form data to post
     * @returns {Promise<Certificate>} Api response
     */
    async create(data: WriteCertificateDto): Promise<Certificate> {
        try {
            const response = await this.basePost<CertificateResponse>(
                `${this.createPath(CertificateService.basePath)}`,
                data,
            );

            return (new CertificateFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple courses depending on relation id.
     * @param {number} certificateId Id of the relation to fetch the linked courses of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CertificatesOverview>} Promise with the CoursesOverview as payload
     */
    async fetchOtherCertificates(certificateId: number, queryParameters = null): Promise<CertificatesOverview> {
        try {
            const response = await this.baseGet<CertificatesOverviewResponse>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/others`,
                queryParameters,
            );

            const data = (new CertificateFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple courses depending on relation id.
     * @param {number} certificateId Id of the relation to fetch the linked courses of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CertificatesOverview>} Promise with the CoursesOverview as payload
     */
    async fetchOtherDepartments(certificateId: number, queryParameters = null): Promise<CertificatesOverview> {
        try {
            const response = await this.baseGet<CertificatesOverviewResponse>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/other-departments`,
                queryParameters,
            );

            const data = (new CertificateFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch attachments that are related to the certificate.
     * @param {number} certificateId Id of the relation to fetch the attachments of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<AttachmentsOverview>} Promise with the AttachemntsOverview as payload
     */
    async fetchAttachments(certificateId: number, queryParameters = null): Promise<AttachmentsOverview> {
        try {
            const response = await this.baseGet<AttachmentsOverviewResponse>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/attachments`,
                queryParameters,
            );

            const data = (new AttachmentFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchPeriods(certificateId: number, queryParameters = null): Promise<PeriodsOverview> {
        try {
            const response = await this.baseGet<PeriodsOverviewResponse>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/periods`,
                queryParameters,
            );

            const data = (new PeriodFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Set a period active of a certificate.
     * @param {number} certificateId Id of the certificate to make change for
     * @param {number} periodId The period to make active
     * @returns {Promise<null>} Promise with null response
     */
    async activePeriod(certificateId: number, periodId: number): Promise<null> {
        try {
            const response = await this.basePost<null>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/periods/${periodId}/active`,
            );

            return response;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete a period from of a certificate.
     * @param {number} certificateId Id of the certificate to make change for
     * @param {number} periodId The period to make active
     * @returns {Promise<null>} Promise with null response
     */
    async deletePeriod(certificateId: number, periodId: number): Promise<null> {
        try {
            const response = await this.baseDelete(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/periods/${periodId}`,
            );

            return response;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a period of a certificate.
     * @param {certificate} certificate The certificate to make change for period
     * @param {Period} period The period to make active
     * @returns {Promise<null>} Promise with null response
     */
    updatePeriod(certificate: Certificate, period: Period): Promise<null> {
        try {
            return this.basePut(
                `${this.createPath(CertificateService.basePath)}/${certificate.id}/periods/${period.id}`,
                {
                    certificate,
                    end: period.end,
                    period,
                    start: period.start,
                },
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Extend a period active of a certificate.
     * @param {number} certificateId Id of the certificate to make change for
     * @returns {Promise<null>} Promise with null response
     */
    async extendPeriod(certificateId: number): Promise<null> {
        try {
            const response = await this.basePost<null>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/extend`,
            );

            return response;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
