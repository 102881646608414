export class RelationPoints2018 {
    public mandatory: {
        [key: string]: {
            needed: number | null;
            achieved: number | null;
            toAchieve: number | null;
        };
    } | null = null;

    public optional: {
        needed: number | null;
        extra: number | null;
        achieved: number | null;
        toAchieve: number | null;
    } | null = null;

    public free: {
        needed: number | null;
        extra: number | null;
        achieved: number | null;
        toAchieve: number | null;
    } | null = null;

    public total: {
        needed: number | null;
        achieved: number | null;
        toAchieve: number | null;
        extraNextYear: number | null;
        shortageNextYear: {
            chambers: {
                [key: string]: number;
            } | [];
        };
        extraLastYear: number | null;
        shortageLastYear: {
            chambers: {
                [key: string]: number;
            } | [];
        };
        totalShortageLastYear: number | null;
        passed: boolean | false;
    } | null = null;

    public year: number | null = null;

    public requiredElearningPassed: boolean | null = false;
}
