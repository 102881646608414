/* eslint-disable unicorn/filename-case, max-lines, vue/sort-keys */
import ActivityService from '~/services/api/extranet/ActivityService';
import ActivityTypeService from '~/services/api/crm/ActivityTypeService';
import CertificateService from '~/services/api/crm/CertificateService';
import CompanyService from '~/services/api/crm/CompanyService';
import CourseService from '~/services/api/crm/CourseService';
import DocumentService from '~/services/api/crm/DocumentService';
import ExactService from '~/services/api/crm/ExactService';
import SurveyListsService from '~/services/api/crm/SurveyListsService';
import ReportService from '~/services/api/crm/ReportService';
import InvoiceService from '~/services/api/crm/InvoiceService';
import ExtranetCertificateService from '~/services/api/extranet/CertificateService';
import ExtranetCompanyService from '~/services/api/extranet/CompanyService';
import InvoiceCodeService from '~/services/api/crm/InvoiceCodeService';
import PeriodService from '~/services/api/crm/PeriodService';
import RelationService from '~/services/api/crm/RelationService';
import UserService from '~/services/api/crm/UserService';
import ExtranetCourseService from '~/services/api/extranet/ExtranetCourseService';
import ExtranetDocumentService from '~/services/api/extranet/ExtranetDocumentService';
import ExtranetSurveyListService from '~/services/api/extranet/ExtranetSurveyListService';
import { FetchInvoices } from '~/enums/FetchInvoices';
import PersonalMovementService from '~/services/api/extranet/PersonalMovementService';
import PointsCombinationService from '~/services/api/crm/PointsCombinationService';
import PointsRequiredYearService from '~/services/api/crm/PointsRequiredYearService';
import DownloadService from '~/services/api/extranet/DownloadService';
import AssessmentService from '~/services/api/crm/AssessmentService';
import ExtranetInvoiceService from '~/services/api/extranet/ExtranetInvoiceService';
import DashboardService from '~/services/api/crm/DashboardService';
import MailMessagesService from '~/services/api/crm/MailMessagesService';

const FetchActions = {
    forDataGridCertificatesDepartments({ queryParameters },
    ) {
        const service = new CertificateService();

        return service.fetchCertificates(queryParameters, 'departments');
    },
    forDataGridCompaniesViewRelations({ parameters, queryParameters },
    ) {
        const { companyId } = parameters;
        const service = new CompanyService();

        return service.fetchLinkedRelations(companyId, queryParameters);
    },
    forDataGridCompaniesViewLogs({ parameters, queryParameters },
    ) {
        const { companyId } = parameters;
        const service = new CompanyService();

        return service.fetchCompanyLogs(companyId, queryParameters);
    },
    forDataGridCertificatesOverview({ queryParameters },
    ) {
        const service = new CertificateService();

        return service.fetchCertificates(queryParameters);
    },
    forDataGridCertificatesPeriodsActivities({ parameters, queryParameters },
    ) {
        const { certificateId, periodId } = parameters;
        const service = new PeriodService();

        return service.fetchActivities(certificateId, periodId, queryParameters);
    },
    forDataGridCertificatesPeriodsCourses({ parameters, queryParameters },
    ) {
        const { certificateId, periodId } = parameters;
        const service = new PeriodService();

        return service.fetchCourses(certificateId, periodId, queryParameters);
    },
    forDataGridCertificatesUnsubscribed({ queryParameters },
    ) {
        const service = new CertificateService();

        return service.fetchCertificates(queryParameters, 'unsubscribed');
    },
    forDataGridCertificatesUnsubscribeRequests({ queryParameters },
    ) {
        const service = new CertificateService();

        return service.fetchCertificates(queryParameters, 'unsubscribe-requests');
    },
    forDataGridCertificatesViewOtherCertificates({ parameters, queryParameters },
    ) {
        const { certificateId } = parameters;

        const service = new CertificateService();

        return service.fetchOtherCertificates(certificateId, queryParameters);
    },
    forDataGridCertificatesViewOtherDepartments({ parameters, queryParameters },
    ) {
        const { certificateId } = parameters;

        const service = new CertificateService();

        return service.fetchOtherDepartments(certificateId, queryParameters);
    },
    forDataGridCertificatesViewPeriods({ parameters, queryParameters },
    ) {
        const { certificateId } = parameters;

        const service = new CertificateService();

        return service.fetchPeriods(certificateId, queryParameters);
    },
    forDataGridCompaniesOverview({ queryParameters },
    ) {
        const service = new CompanyService();

        return service.fetchCompanies(queryParameters);
    },
    forDataGridCertificatesViewAttachments({ parameters, queryParameters },
    ) {
        const { certificateId } = parameters;

        const service = new CertificateService();

        return service.fetchAttachments(certificateId, queryParameters);
    },
    forDataGridCoursesOverview({ queryParameters },
    ) {
        const service = new CourseService();

        return service.fetchCourses(queryParameters);
    },
    forDataGridCoursesViewLogs({ parameters, queryParameters },
    ) {
        const { courseId } = parameters;
        const service = new CourseService();

        return service.fetchLinkedLogs(courseId, queryParameters);
    },
    forDataGridDashboardPermanentEducationYearBucket({ parameters, queryParameters }) {
        return (new DashboardService()).fetchPermanentEducationYearBucket({
            year: parameters.year,
            bucket: parameters.bucket,
            queryParameters,
        });
    },
    forDataGridDocumentsOverview({ queryParameters },
    ) {
        const service = new DocumentService();

        return service.fetchDocuments(queryParameters);
    },
    forDataGridMailMessagesOverview({ queryParameters }) {
        return new MailMessagesService().fetchAll(queryParameters);
    },
    forDataGridMailMessagesRecipients({ parameters, queryParameters }) {
        return new MailMessagesService().fetchRecipients(parameters.id, queryParameters);
    },
    forDataGridExactLogsOverview({ queryParameters },
    ) {
        const service = new ExactService();

        return service.fetchLogs(queryParameters);
    },
    forDataGridExtranetActivities({ parameters, queryParameters },
    ) {
        const service = new ActivityService();
        const { year } = parameters;

        return service.fetchActivities(year, queryParameters);
    },
    forDataGridExtranetCertificates({ queryParameters },
    ) {
        const service = new ExtranetCertificateService();

        return service.fetchCertificates(queryParameters);
    },
    forDataGridExtranetCompanies({ queryParameters },
    ) {
        const service = new ExtranetCompanyService();

        return service.fetchCompanies(queryParameters);
    },
    forDataGridExtranetCoursesOverview({ parameters, queryParameters },
    ) {
        const service = new ExtranetCourseService();
        const { year } = parameters;

        return service.fetchExtranetCourses(year, queryParameters);
    },
    forDataGridExtranetInvoicesOverview({ queryParameters },
    ) {
        const service = new ExtranetInvoiceService();

        return service.fetchExtranetInvoices(queryParameters);
    },
    forDataGridExtranetDocuments({ queryParameters },
    ) {
        const service = new ExtranetDocumentService();

        return service.fetchDocuments(queryParameters);
    },
    forDataGridExtranetDownloads({ queryParameters },
    ) {
        const service = new DownloadService();

        return service.fetchDocuments(queryParameters);
    },
    forDataGridExtranetMovementsOverview({ queryParameters },
    ) {
        const service = new PersonalMovementService();

        return service.fetchMovementsCompanies(queryParameters);
    },
    forDataGridExtranetSurveylists({ queryParameters },
    ) {
        const service = new ExtranetSurveyListService();

        return service.fetchAnsweredSurveylists(queryParameters);
    },
    forDataGridPointsCombinationOverview({ queryParameters },
    ) {
        const service = new PointsCombinationService();

        return service.fetchPointsCombinations(queryParameters);
    },
    forDataGridSettingsOverviewAssessments({ queryParameters },
    ) {
        const service = new AssessmentService();

        return service.fetchAssessments(queryParameters);
    },
    forDataGridSettingsOverviewPePoints({ queryParameters },
    ) {
        const service = new PointsRequiredYearService();

        return service.fetchPointsRequiredYears(queryParameters);
    },
    forDataGridSettingsOverviewFinalScore({ queryParameters },
    ) {
        const service = new RelationService();

        return service.fetchRelations(queryParameters);
    },
    forDataGridRelationsOverview({ queryParameters },
    ) {
        const service = new RelationService();

        return service.fetchRelations(queryParameters);
    },
    forDataGridRelationsOverviewCompanions({ queryParameters },
    ) {
        const service = new RelationService();

        return service.fetchRelations(queryParameters, 'companions');
    },
    forDataGridRelationsOverviewContacts({ queryParameters },
    ) {
        const service = new RelationService();

        return service.fetchRelations(queryParameters, 'contacts');
    },
    forDataGridRelationsOverviewDeleted({ queryParameters },
    ) {
        const service = new RelationService();

        return service.fetchRelations(queryParameters, 'deleted');
    },
    forDataGridRelationsOverviewDepartments({ queryParameters },
    ) {
        const service = new RelationService();

        return service.fetchRelations(queryParameters, 'departments');
    },
    forDataGridRelationsOverviewUnsubscribed({ queryParameters },
    ) {
        const service = new RelationService();

        return service.fetchRelations(queryParameters, 'unsubscribed');
    },
    forDataGridRelationsOverviewWaitingForSubscription({ queryParameters },
    ) {
        const service = new RelationService();

        return service.fetchRelations(queryParameters, 'waitingforsubscription');
    },
    forDataGridRelationsOverviewMovements({ queryParameters },
    ) {
        const service = new RelationService();

        return service.fetchRelationsMovements(queryParameters, 'movements');
    },
    forDataGridRelationsOverviewMasters({ queryParameters },
    ) {
        const service = new RelationService();

        return service.fetchMasters(queryParameters);
    },
    forDataGridRelationsViewActivities({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedActivities(relationId, queryParameters);
    },
    forDataGridRelationsViewAttachments({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedAttachments(relationId, queryParameters);
    },
    forDataGridRelationsViewCompanies({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedCompanies(relationId, queryParameters);
    },
    forDataGridRelationsViewCourses({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedCourses(relationId, queryParameters);
    },
    forDataGridRelationsViewDispensations({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedDispensations(relationId, queryParameters);
    },
    forDataGridRelationsViewCertificates({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedCertificates(relationId, queryParameters);
    },
    forDataGridRelationsViewDepartments({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedDepartments(relationId, queryParameters);
    },
    forDataGridRelationsViewInvoices({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedInvoices(relationId, queryParameters);
    },
    forDataGridRelationsViewLogs({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedLogs(relationId, queryParameters);
    },
    forDataGridRelationsViewDisciplinaryLaws({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedDisciplinaryLaws(relationId, queryParameters);
    },
    forDataGridRelationsViewMails({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedMails(relationId, queryParameters);
    },
    forDataGridRelationsViewRelationTradeOrganisations({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedRelationTradeOrganisations(relationId, queryParameters);
    },
    forDataGridRelationsViewRemarks({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedRemarks(relationId, queryParameters);
    },
    forDataGridRelationsViewSurveyLists({ parameters, queryParameters },
    ) {
        const { relationId } = parameters;
        const service = new RelationService();

        return service.fetchLinkedSurveyLists(relationId, queryParameters);
    },
    forDataGridSettingsOverviewInvoiceCodes({ queryParameters },
    ) {
        const service = new InvoiceCodeService();

        return service.fetchInvoiceCodes(queryParameters);
    },
    forDataGridSettingsOverviewUsers({ queryParameters },
    ) {
        const service = new UserService();

        return service.fetchUsers(queryParameters);
    },
    forDataGridSurveyListsOverview({ queryParameters },
    ) {
        const service = new SurveyListsService();

        return service.fetchSurveyLists(queryParameters);
    },
    forDataGridSurveyListOptionsOverview({ queryParameters },
    ) {
        const service = new SurveyListsService();

        return service.fetchSurveyListOptions(queryParameters);
    },
    forDataGridSurveyListOptionSetsOverview({ queryParameters },
    ) {
        const service = new SurveyListsService();

        return service.fetchSurveyListOptionSets(queryParameters);
    },
    forDataGridSurveylistParticipantsOverview({ parameters, queryParameters },
    ) {
        const { surveylistId } = parameters;

        const service = new SurveyListsService();

        return service.fetchSurveylistParticipants(queryParameters, surveylistId);
    },
    forDataGridActivityTypeOverview({ queryParameters },
    ) {
        const service = new ActivityTypeService();

        return service.fetchActivityTypes(queryParameters);
    },

    forDataGridReportOverview({ queryParameters },
    ) {
        const service = new ReportService();

        return service.fetchReports(queryParameters);
    },

    forDataGridInvoicesAnnualOverview({ queryParameters },
    ) {
        const service = new InvoiceService();

        return service.fetchOverviewAnnualInvoices(queryParameters);
    },

    forDataGridInvoicesOverview({ queryParameters },
    ) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters, FetchInvoices.ALL);
    },

    forDataGridInvoicesOpenOverview({ queryParameters },
    ) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters, FetchInvoices.OPEN);
    },

    forDataGridInvoicesUnpaidOverview({ queryParameters },
    ) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters, FetchInvoices.UNPAID);
    },

    forDataGridInvoicesPaidOverview({ queryParameters },
    ) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters, FetchInvoices.PAID);
    },

    forDataGridInvoicesSendReminder({ queryParameters },
    ) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters, FetchInvoices.UNPAID_REMINDER);
    },

    forDataGridInvoicesSendOpen({ queryParameters },
    ) {
        const service = new InvoiceService();

        return service.fetchInvoices(queryParameters, FetchInvoices.OPEN_REMINDER);
    },
};
export default FetchActions;
