import type { SurveyListOption } from './SurveyListOption';
import type { SurveyListAnswer } from '~/models/SurveyListAnswer';

export class SurveyListQuestion {
    public id: number | null = null;

    public no: number | null = null;

    public parentQuestion: number | null = null;

    public parentQuestionChoice: string | null = null;

    public typeText: string | null = null;

    public required: string | null = null;

    public optionSet: number | null = null;

    public options: SurveyListOption[] | null = null;

    public statement: string | null = null;

    public pdfIntro: string | null = null;

    public question: string | null = null;

    public explanation: string | null = null;

    public childQuestion: boolean | null = null;

    // @TODO[NRVT-2213]: This is a circular reference
    // eslint-disable-next-line no-use-before-define
    public childQuestions: SurveyListQuestion[] | null = null;

    public questionType: string | null = null;

    public answer: SurveyListAnswer | null = null;

    public validation: string | null = null;

    public yesnoInapplicable: boolean | null = null;
}
