import { BaseFactory } from './BaseFactory';
import { SurveyListFactory } from './SurveyListFactory';
import { ExtranetRelationSurveyListAnswersFactory } from './ExtranetRelationSurveyListAnswersFactory';
import type { ReadExtranetRelationSurveyListDto } from '~/types/ExtranetRelationSurveyList';
import { ExtranetRelationSurveyList } from '~/models/ExtranetRelationSurveyList';

// eslint-disable-next-line max-len
export class ExtranetRelationSurveyListsFactory extends BaseFactory<ReadExtranetRelationSurveyListDto, ExtranetRelationSurveyList> {
    public toModel(dto: ReadExtranetRelationSurveyListDto): ExtranetRelationSurveyList {
        const model = new ExtranetRelationSurveyList();

        model.id = Number(dto.id);

        model.uuid = dto.uuid || null;
        model.startedDate = dto.startedDate || null;
        model.endedDate = dto.endedDate || null;

        if (dto.surveylist) {
            model.surveylist = (new SurveyListFactory()).toModel(dto.surveylist.data);
        }

        if (dto.allAnswers) {
            model.allAnswers = (new ExtranetRelationSurveyListAnswersFactory()).toModels(dto.allAnswers.data);
        }

        return model;
    }
}
