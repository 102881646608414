import { BaseFactory } from './BaseFactory';
import type { ReadExtranetRelationSurveyListQuestionAndAnswerDto } from '~/types/ExtranetRelationSurveyListAnswer';
import { ExtranetRelationSurveyListQuestionAndAnswer } from '~/models/ExtranetRelationSurveyListQuestionAndAnswer';
import { SurveyListOptionFactory } from '~/models/factories/SurveyListOptionFactory';

export class ExtranetRelationSurveyListQuestionAndAnswerFactory
    extends BaseFactory<
        ReadExtranetRelationSurveyListQuestionAndAnswerDto,
        ExtranetRelationSurveyListQuestionAndAnswer
    > {
    // eslint-disable-next-line max-lines-per-function, max-statements, complexity
    public toModel(
        dto: ReadExtranetRelationSurveyListQuestionAndAnswerDto,
    ): ExtranetRelationSurveyListQuestionAndAnswer {
        const model = new ExtranetRelationSurveyListQuestionAndAnswer();

        model.id = Number(dto.id);

        model.explanation = dto.explanation || null;

        model.attachments = dto.attachments || null;

        model.isChild = dto.isChild || null;

        model.no = dto.no || null;

        model.parentQuestionChoice = dto.parentQuestionChoice || null;

        model.question = dto.question || null;

        model.questionType = dto.questionType || null;

        model.typeText = dto.typeText || null;

        model.required = dto.required ?? null;

        model.yesnoInapplicable = dto.yesnoInapplicable ?? null;

        // Refactoring this is tech debt and will change when the new surveylist creation flow is implemented
        // Requires fundamental changes in the FE and BE
        // Backlog ticket for this is: https://bitf.atlassian.net/browse/NRVT-2065

        // Transform the answer to a single value instead of array when the question type is selectone
        switch (dto.questionType) {
            case 'selectone': {
                model.answer = {
                    ...dto.answer.data,
                    answer: dto.answer.data.answer ? (
                        dto.answer.data.else ? dto.answer.data.answer : (dto.answer.data.answer as string[])[0]
                    ) : null,
                };

                break;
            }
            // Default the answer to an empty array when the question type is selectmore
            case 'selectmore': {
                model.answer = {
                    ...dto.answer.data,
                    answer: dto.answer.data.answer ? (
                        dto.answer.data.answer === 'nvt' ? [] : dto.answer.data.answer
                    ) : [],
                };

                break;
            }
            // Transform the answer to a boolean value instead of string when the question type is check
            // @TODO: Afstemmen met backend, aanleveren als boolean zou beter zijn.
            case 'check': {
                model.answer = {
                    ...dto.answer.data,
                    answer: dto.answer.data.answer ? dto.answer.data.answer === 'true' : null,
                };

                break;
            }
            default: {
                model.answer = dto.answer ? {
                    ...dto.answer.data,
                } : null;
            }
        }

        if (dto.options) {
            model.options = (new SurveyListOptionFactory()).toModels(dto.options.data);
        }

        if (dto.childQuestions) {
            model.childQuestions = new ExtranetRelationSurveyListQuestionAndAnswerFactory().toModels(
                dto.childQuestions.data,
            );
        }

        return model;
    }
}
