export class RelationAttachment {
    public id: number | null = null;

    public createdAt: string | null = null;

    public filename: string | null = null;

    public surveylistId: number | null = null;

    public surveylistName: string | null = null;

    public type: string | null = null;

    public visibleOnExtranet: boolean | null = false;
}
