export class CompanyMovement {
    public fromCompanyId: number | null = null;

    public name: string | null = null;

    public street: string | null = null;

    public status: string | null = null;

    public houseNumber: string | null = null;

    public houseNumberSuffix: string | null = null;

    public postal: string | null = null;

    public city: string | null = null;

    public phone: string | null = null;

    public per: string | null = null;

    public personal: string | null = null;

    public email: string | null = null;

    public website: string | null = null;

    public mailbox: string | null = null;

    public mailboxPostal: string | null = null;

    public mailboxCity: string | null = null;

    public movementDate: string | null = null;

    public cocFile: string | null = null;
}
