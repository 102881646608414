import { BaseFactory } from './BaseFactory';
import type { ReadRelationDepartmentPointsDto } from '~/types/RelationDepartmentPoints';
import { RelationDepartmentPoints } from '~/models/RelationDepartmentPoints';

export class RelationDepartmentPointsFactory
    extends BaseFactory<ReadRelationDepartmentPointsDto, RelationDepartmentPoints> {
    public toModel(dto: ReadRelationDepartmentPointsDto): RelationDepartmentPoints {
        const model = new RelationDepartmentPoints();

        model.mandatory = dto?.points?.mandatory || null;

        model.total = dto?.points?.total || null;

        model.year = dto?.points?.year || null;

        return model;
    }
}
