import { BaseFactory } from './BaseFactory';
import { PointsCombinationChamberFactory } from './PointsCombinationChambersFactory';
import type { ReadPointsRequiredYearDto } from '~/types/PointsRequiredYear';
import { PointsRequiredYear } from '~/models/PointsRequiredYear';

export class PointsRequiredYearFactory extends BaseFactory<ReadPointsRequiredYearDto, PointsRequiredYear> {
    public toModel(dto: ReadPointsRequiredYearDto): PointsRequiredYear {
        const model = new PointsRequiredYear();

        model.id = dto.id || null;

        model.year = dto.year || null;

        model.pointToOverflow = dto.pointToOverflow || null;

        model.elearningCourses = dto.elearningCourses || [];

        // eslint-disable-next-line no-undefined
        if (dto.peCombinationChambers !== undefined && dto.peCombinationChambers !== null) {
            model.peCombinationChambers = (new PointsCombinationChamberFactory())
                .toModels(dto.peCombinationChambers.data);
        }

        return model;
    }
}
