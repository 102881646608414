export class CompanyKvk {
    public city: string | null = null;

    public cocNumber: string | null = null;

    public establishmentNumber: string | null = null;

    public houseNumber: number | null = null;

    public houseNumberPostfix: string | null = null;

    public name: string | null = null;

    public postal: string | null = null;

    public street: string | null = null;

    public website: string | null = null;

    public email: string | null = null;

    public mailbox: string | null = null;

    public mailboxCity: string | null = null;

    public mailboxPostal: string | null = null;

    public phone: string | null = null;

    public province: string | null = null;

    public tradeNames: string[] = [];
}
