import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type PointsCombinationResponse,
    type PointsCombinationsOverview,
    type PointsCombinationsOverviewResponse,
    type WritePointsCombinationDto,
} from '~/types/PointsCombination';
import { PointsCombinationFactory } from '~/models/factories/PointsCombinationFactory';
import { type PointsCombination } from '~/models/PointsCombination';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';

export default class PointsCombinationService extends BaseApiService {
    public static basePath = 'pe-combination';

    /**
     * @description Create a new PointsCombination.
     * @param {WritePointsCombinationDto} data Form data to post
     * @returns {Promise<PointsCombination>} Api response
     */
    async create(data: WritePointsCombinationDto): Promise<PointsCombination> {
        const response = await this.basePost<PointsCombinationResponse>(
            this.createPath(PointsCombinationService.basePath),
            data,
        );

        return (new PointsCombinationFactory()).toModel(response.data);
    }

    /**
     * @description Fetch multiple PointsCombinations depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<PointsCombinationsOverview>} Promise with the PointsCombinationOverview as payload
     */
    async fetchPointsCombinations(queryParameters = null): Promise<PointsCombinationsOverview> {
        try {
            const response = await this.baseGet<PointsCombinationsOverviewResponse>(
                this.createPath(PointsCombinationService.basePath),
                queryParameters,
            );
            const data = (new PointsCombinationFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single PointsCombination by PointsCombination id.
     * @param {number} pointscombinationId Identifier of the PointsCombination to fetch
     * @returns {Promise<PointsCombination>} Promise with the PointsCombination model as payload
     */
    async fetchPointsCombination(pointscombinationId: number): Promise<PointsCombination> {
        try {
            const response = await this.baseGet<PointsCombinationResponse>(
                `${this.createPath(PointsCombinationService.basePath)}/${pointscombinationId}`,
            );

            return (new PointsCombinationFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a PointsCombination.
     * @param {number} pointscombinationId Id of the resource to save
     * @param {WritePointsCombinationDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(pointscombinationId: number, data: WritePointsCombinationDto): Promise<null> {
        return this.basePut<null>(
            `${this.createPath(PointsCombinationService.basePath)}/${pointscombinationId}`,
            data,
        );
    }
}
