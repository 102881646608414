import { SurveyListAnswer } from '../SurveyListAnswer';
import { BaseFactory } from './BaseFactory';
import { AttachmentFactory } from './AttachmentFactory';
import type { ReadSurveyListAnswerDto } from '~/types/SurveyListAnswer';

export class SurveyListAnswerFactory extends BaseFactory<ReadSurveyListAnswerDto, SurveyListAnswer> {
    // eslint-disable-next-line max-statements
    public toModel(dto: ReadSurveyListAnswerDto): SurveyListAnswer {
        const model = new SurveyListAnswer();

        model.no = Number(dto.no);
        model.questionId = Number(dto.questionId);
        model.question = dto.question;
        model.type = dto.type;
        model.isChild = dto.isChild;
        model.else = dto.else;
        model.parentQuestionChoice = dto.parentQuestionChoice;
        model.answer = dto.answer;

        if (dto?.attachments && dto?.attachments.length) {
            model.attachments = (new AttachmentFactory()).toModels(dto.attachments);
        }

        return model;
    }
}
