/* eslint-disable complexity, max-statements */
import { BaseFactory } from './BaseFactory';
import type { ReadRelationPoints2022Dto } from '~/types/RelationPoints2022';
import { RelationPoints2022 } from '~/models/RelationPoints2022';

export class RelationPoints2022Factory extends BaseFactory<ReadRelationPoints2022Dto, RelationPoints2022> {
    public toModel(dto: ReadRelationPoints2022Dto): RelationPoints2022 {
        const model = new RelationPoints2022();

        model.required = dto.points?.required || null;

        model.facultative = dto.points?.facultative || null;

        model.total = dto.points?.total || null;

        model.year = dto.points?.year || null;

        model.totalPoints = dto.points?.totalPoints || null;

        model.totalPointsNeeded = dto.points?.totalPointsNeeded || null;

        model.extraPointsNextYear = dto.points?.extraPointsNextYear || null;

        model.shortageNextYear = dto.points?.shortageNextYear || null;

        model.extraNextYear = dto.points?.extraNextYear || null;

        model.unsubscribedPoints = dto.points?.unsubscribedPoints || null;

        model.requiredElearningPassed = dto.requiredElearningPassed || false;

        return model;
    }
}
