import { BaseFactory } from './BaseFactory';
import { PointsChamberCombinationFactory } from './PointsChamberCombinationFactory';
import { PointsRequiredYearFactory } from './PointsRequiredYearFactory';
import { PointsCombination } from '~/models/PointsCombination';
import type { ReadPointsCombinationDto } from '~/types/PointsCombination';

export class PointsCombinationFactory extends BaseFactory<ReadPointsCombinationDto, PointsCombination> {
    public toModel(dto: ReadPointsCombinationDto): PointsCombination {
        const model = new PointsCombination();

        model.id = dto.id || null;

        model.peRequiredYearId = dto.peRequiredYearId || null;

        model.facultative = dto.facultative || null;

        model.free = dto.free || null;

        // eslint-disable-next-line no-undefined
        if (dto.peRequiredYear !== undefined && dto.peRequiredYear !== null) {
            model.peRequiredYear = (new PointsRequiredYearFactory()).toModel(dto.peRequiredYear.data);
        }

        // eslint-disable-next-line no-undefined
        if (dto.chamberPeCombination !== undefined && dto.chamberPeCombination !== null) {
            model.chamberPeCombination = (new PointsChamberCombinationFactory())
                .toModels(dto.chamberPeCombination.data);
        }

        return model;
    }
}
