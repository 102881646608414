export class RelationPoints2020 {
    public mandatory: {
        [key: string]: {
            needed: number | null;
            achieved: number | null;
            toAchieve: number | null;
            extra: number | null;
            passed: boolean | null;
        };
    } | null = null;

    public optional: {
        [key: string]: {
            needed: number | null;
            achieved: number | null;
            toAchieve: number | null;
            extra: number | null;
            passed: boolean | null;
        };
    } | null = null;

    public free: {
        needed: number | null;
        achieved: number | null;
        toAchieve: number | null;
        extra: number | null;
        passed: boolean | null;
    } | null = null;

    public total: {
        mandatory: {
            needed: number | null;
            achieved: number | null;
            passed: boolean | null;
            toAchieve: number | null;
        };
        optional: {
            needed: number | null;
            achieved: number | null;
            passed: boolean | null;
            // chambers: string[] | [] // Niet gebruikt,
            toAchieve: number | null;
        };
        free: {
            needed: number | null;
            achieved: number | null;
            passed: boolean | null;
            toAchieve: number | null;
        };
        shortage: {
            chambers: string | null;
            optional: string | null;
            free: string | null;
        };
        shortageTotal: number | null;
        extra: number | null;
        needed: number | null;
        achieved: number | null;
        passed: boolean | false;
        shortageBefore: string[] | null;
        shortageTotalBefore: number | null;
        extraNextYear: number | null;
        shortageNextYear: {
            shortage: number | null;
        } | [];
    } | null = null;

    public year: number | null = null;

    public requiredElearningPassed: boolean | null = false;
}
