import { SurveyListOption } from '../SurveyListOption';
import { BaseFactory } from './BaseFactory';
import type { ReadSurveyListOptionDto } from '~/types/SurveyListOption';

export class SurveyListOptionFactory extends BaseFactory<ReadSurveyListOptionDto, SurveyListOption> {
    public toModel(dto: ReadSurveyListOptionDto): SurveyListOption {
        const model = new SurveyListOption();

        model.id = Number(dto.id);
        model.name = dto.name;
        model.else = Boolean(dto.else);

        return model;
    }
}
