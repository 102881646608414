import type { PointsChamberCombination } from './PointsChamberCombination';
import type { PointsRequiredYear } from './PointsRequiredYear';

export class PointsCombination {
    public id: number | null = null;

    public peRequiredYearId: number | null = null;

    public facultative: number | null = null;

    public free: number | null = null;

    public peRequiredYear: PointsRequiredYear | null = null;

    public chamberPeCombination: PointsChamberCombination[] | null = null;
}
