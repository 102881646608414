export class RelationCertificate {
    public id: number | null = null;

    public periodId: number | null = null;

    public chamber: string | null = null;

    public period: string | null = null;

    public status: string | null = null;

    public unsubscribeDate: string | null = null;
}
