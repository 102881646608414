import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type ReportResponse,
    type ReportsOverview,
    type ReportsOverviewResponse,
    type WriteReportDto,
} from '~/types/Report';
import { ReportFactory } from '~/models/factories/ReportFactory';
import { type Report } from '~/models/Report';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';

export default class ReportService extends BaseApiService {
    public static basePath = 'reports';

    /**
     * @description Create a new Report.
     * @param {WriteReportDto} data Form data to post
     * @returns {Promise<Report>} Api response
     */
    async generate(data: WriteReportDto): Promise<Report> {
        const response = await this.basePost<ReportResponse>(
            `${this.createPath(ReportService.basePath)}/generate`,
            data,
        );

        return (new ReportFactory()).toModel(response.data);
    }

    /**
     * @description Fetch multiple Reports depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<ReportsOverview>} Promise with the ReportOverview as payload
     */
    async fetchReports(queryParameters = null): Promise<ReportsOverview> {
        try {
            const response = await this.baseGet<ReportsOverviewResponse>(
                this.createPath(ReportService.basePath),
                queryParameters,
            );
            const reportData = (new ReportFactory()).toModels(response.data);
            const metaData = response.meta;

            return {
                data: reportData,
                meta: metaData,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Download report by report id.
     * @param {number} reportId Identifier of the report to download
     * @returns {Promise<void>} Returns the attachment file
     */
    async downloadReport(reportId: number): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(ReportService.basePath)}/${reportId}/download`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete a report.
     * @param {number} reportId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(reportId: number): Promise<null> {
        return this.baseDelete(`${this.createPath(ReportService.basePath)}/${reportId}`);
    }
}
