/* eslint-disable max-statements */
import { BaseFactory } from './BaseFactory';
import type { ReadSurveyListDto } from '~/types/SurveyList';
import { SurveyList } from '~/models/SurveyList';

export class SurveyListFactory extends BaseFactory<ReadSurveyListDto, SurveyList> {
    public toModel(dto: ReadSurveyListDto): SurveyList {
        const model = new SurveyList();

        model.id = Number(dto.id);
        model.name = dto.name;
        model.type = dto.type;
        model.amountOfParticipants = Number(dto.amountOfParticipants);
        model.amountFinished = Number(dto.amountFinished);
        model.intro = dto.intro || null;
        model.outro = dto.outro || null;
        model.mailReminder = dto.mailReminder || null;
        model.reminderMailSubject = dto.reminderMailSubject || null;
        model.mail = dto.mail || null;
        model.steps = dto.steps || null;

        return model;
    }
}
