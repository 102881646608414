import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type ExtranetCompaniesOverview,
    type ExtranetCompaniesOverviewResponse,
    type ExtranetCompanyResponse,
} from '~/types/ExtranetCompany';
import { ExtranetCompanyFactory } from '~/models/factories/ExtranetCompanyFactory';
import { type ExtranetCompany } from '~/models/ExtranetCompany';
import { type ListResponse } from '~/types/List';
import { type List } from '~/models/List';
import { ListFactory } from '~/models/factories/ListFactory';

export default class CompanyService extends BaseApiService {
    public static basePath = 'companies';

    /**
     * @description Fetch multiple companies depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<ExtranetCompaniesOverview>} Promise with the ExtranetCompaniesOverview as payload
     */
    async fetchCompanies(queryParameters = null): Promise<ExtranetCompaniesOverview> {
        try {
            const response = await this.baseGet<ExtranetCompaniesOverviewResponse>(
                this.createPath(CompanyService.basePath),
                queryParameters,
            );
            const data = (new ExtranetCompanyFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch single company by company id.
     * @param {number} companyId Identifier of the company to fetch
     * @returns {Promise<ExtranetCompany>} Promise with the ExtranetCompany model as payload
     */
    async fetchCompany(companyId: number): Promise<ExtranetCompany> {
        try {
            const response = await this.baseGet<ExtranetCompanyResponse>(
                `${this.createPath(CompanyService.basePath)}/${companyId}`,
            );

            return (new ExtranetCompanyFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Get all the comapnies that are available.
     * @returns {Promise<List[]>} Promise with an array List models as payload
     */
    async fetchCompaniesList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(CompanyService.basePath)}/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }
}
