import { RelationAttachment } from '../RelationAttachment';
import { BaseFactory } from './BaseFactory';

export class RelationAttachmentFactory extends BaseFactory<RelationAttachment, RelationAttachment> {
    public toModel(dto: RelationAttachment): RelationAttachment {
        const model = new RelationAttachment();

        model.id = Number(dto.id);
        model.createdAt = dto.createdAt;
        model.filename = dto.filename;
        model.surveylistId = Number(dto.surveylistId);
        model.surveylistName = dto.surveylistName;
        model.type = dto.type;
        model.visibleOnExtranet = dto.visibleOnExtranet;

        return model;
    }
}
