
import { QuestionStep } from '../QuestionStep';
import { BaseFactory } from './BaseFactory';
import { ExtranetSurveyListQuestionFactory } from './ExtranetSurveyListQuestionFactory';
import { SurveyListFactory } from './SurveyListFactory';
import { RelationFactory } from './RelationFactory';
import { CompanyFactory } from './CompanyFactory';
import type { ReadQuestionStepDto } from '~/types/QuestionStep';

export class QuestionStepFactory extends BaseFactory<ReadQuestionStepDto, QuestionStep> {
    // eslint-disable-next-line max-statements
    public toModel(dto: ReadQuestionStepDto): QuestionStep {
        const model = new QuestionStep();

        model.id = Number(dto.id);
        model.endedDate = String(dto.endedDate);
        model.startedDate = String(dto.startedDate);
        model.step = Number(dto.step) || dto.step;
        model.uuid = String(dto.uuid);
        model.progress = Number(dto.progress);
        model.surveyProgress = Number(dto.surveyProgress);

        if (dto.questionAndAnswer) {
            model.questionAndAnswer = (new ExtranetSurveyListQuestionFactory()).toModel(dto.questionAndAnswer.data);
        }

        // eslint-disable-next-line no-undefined
        if (dto.surveylist !== undefined && dto.surveylist !== null) {
            model.surveylist = (new SurveyListFactory()).toModel(dto.surveylist.data);
        }

        // eslint-disable-next-line no-undefined
        if (dto.relation !== undefined && dto.relation !== null) {
            model.relation = (new RelationFactory()).toModel(dto.relation.data);
        }

        // eslint-disable-next-line no-undefined
        if (dto.companies !== undefined && dto.companies !== null) {
            model.companies = (new CompanyFactory()).toModels(dto.companies.data);
        }

        return model;
    }
}
