import { SurveyListParticipants } from '../SurveyListParticipants';
import { BaseFactory } from './BaseFactory';
import { SurveyListFactory } from './SurveyListFactory';
import type { ReadSurveylistParticipantsDto } from '~/types/SurveylistParticipant';

export class SurveyListParticipantsFactory extends BaseFactory<ReadSurveylistParticipantsDto, SurveyListParticipants> {
    public toModel(dto: ReadSurveylistParticipantsDto): SurveyListParticipants {
        const model = new SurveyListParticipants();

        model.id = Number(dto.id);
        model.endedDate = dto.endedDate;
        model.startedDate = dto.startedDate;
        model.step = Number(dto.step);
        model.surveyProgress = Number(dto.surveyProgress);
        model.uuid = dto.uuid;

        // eslint-disable-next-line no-undefined
        if (dto.surveylist !== undefined && dto.surveylist !== null) {
            model.surveylist = (new SurveyListFactory()).toModel(dto.surveylist.data);
        }

        return model;
    }
}
