import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { type PeriodResponse } from '~/types/Period';
import { PeriodFactory } from '~/models/factories/PeriodFactory';
import { type Period } from '~/models/Period';
import { type PeriodCoursesOverview, type PeriodCoursesOverviewResponse } from '~/types/PeriodCourse';
import { PeriodCourseFactory } from '~/models/factories/PeriodCourseFactory';
import { type PeriodActivitiesOverview, type PeriodActivitiesOverviewResponse } from '~/types/PeriodActivity';
import { PeriodActivityFactory } from '~/models/factories/PeriodActivityFactory';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';

export default class PeriodService extends BaseApiService {
    public static basePath = 'certificates';

    /**
     * @description Fetch single Period by period and certificate id.
     * @param {number} certificateId Identifier of the Certificate to fetch
     * @param {number} periodId Identifier of the Period to fetch
     * @returns {Promise<Period>} Promise with the Period model as payload
     */
    async fetchPeriod(certificateId: number, periodId: number): Promise<Period> {
        try {
            const response = await this.baseGet<PeriodResponse>(
                `${this.createPath(PeriodService.basePath)}/${certificateId}/periods/${periodId}`,
            );

            return (new PeriodFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch courses by certificate and period id.
     * @param {number} certificateId Id of the certificate to fetch the linked courses for
     * @param {number} periodId Id of the period to fetch the linked courses for
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<PeriodCoursesOverview>} Promise with the CoursesOverview as payload
     */
    async fetchCourses(
        certificateId: number,
        periodId: number,
        queryParameters = null,
    ): Promise<PeriodCoursesOverview> {
        try {
            const response = await this.baseGet<PeriodCoursesOverviewResponse>(
                `${this.createPath(PeriodService.basePath)}/${certificateId}/periods/${periodId}/courses`,
                queryParameters,
            );

            const data = (new PeriodCourseFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch activities by certificate and period id.
     * @param {number} certificateId Id of the certificate to fetch the linked activities for
     * @param {number} periodId Id of the period to fetch the linked activities for
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<PeriodActivitiesOverview>} Promise with the PeriodActivitiesOverview as payload
     */
    async fetchActivities(
        certificateId: number,
        periodId: number,
        queryParameters = null,
    ): Promise<PeriodActivitiesOverview> {
        try {
            const response = await this.baseGet<PeriodActivitiesOverviewResponse>(
                `${this.createPath(PeriodService.basePath)}/${certificateId}/periods/${periodId}/activities`,
                queryParameters,
            );

            const data = (new PeriodActivityFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
