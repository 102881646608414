import { BaseFactory } from './BaseFactory';
import type { ReadPointsChamberCombinationDto } from '~/types/PointsChamberCombination';
import { PointsChamberCombination } from '~/models/PointsChamberCombination';

export class PointsChamberCombinationFactory
    extends BaseFactory<ReadPointsChamberCombinationDto, PointsChamberCombination> {
    public toModel(dto: ReadPointsChamberCombinationDto): PointsChamberCombination {
        const model = new PointsChamberCombination();

        model.id = dto.id || null;

        model.name = dto.name || null;

        model.code = dto.code || null;

        model.peCombinationId = dto.peCombinationId || null;

        model.chamberId = dto.chamberId || null;

        model.required = dto.required || null;

        model.free = dto.free || null;

        model.facultative = dto.facultative || null;

        return model;
    }
}
