import type { AttachmentsResponse } from '~/types/ExtranetAttachment';

export class ExtranetCourse {
    public courseCode: string | null = null;

    public courseId: number | null = null;

    public courseName: string | null = null;

    public chamberCodes: string | null = null;

    public points: number | null = null;

    public courseDate: boolean | null = null;

    public applicableYear: number | null = null;

    public trainerName: string[] = [];

    public isActivity: string | null = null;

    public attachments: AttachmentsResponse | null = null;
}
