import DateFormatUtils from '~/utils/DateFormatUtils';
import type { Attachment } from '~/models/Attachment';

export class DisciplinaryLaw {
    public comment: string | null = null;

    public createdAt: string | null = null;

    public disciplinaryLawStatusId: number | null = null;

    public disciplinaryLawStatusName: string | null = null;

    public fromDate: string | null = null;

    public id: number | null = null;

    public relationId: number | null = null;

    public tillDate: string | null = null;

    public attachments: Attachment[] | null = null;

    public getCreatedDate(): string {
        return this.createdAt ? DateFormatUtils.dateFormatToDisplay(this.createdAt) : '';
    }

    public getFromDate(): string {
        return this.fromDate ? DateFormatUtils.dateFormatToDisplay(this.fromDate) : '';
    }

    public getTillDate(): string {
        return this.tillDate ? DateFormatUtils.dateFormatToDisplay(this.tillDate) : '';
    }
}
