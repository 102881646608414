import { BaseFactory } from './BaseFactory';
import { CompanyTypeFactory } from './CompanyTypeFactory';
import type { ReadCompanyDto } from '~/types/Company';
import { Company } from '~/models/Company';
import { secureUrl } from '~/utils/TextUtils';

export class CompanyFactory extends BaseFactory<ReadCompanyDto, Company> {
    /* eslint-disable-next-line max-statements, complexity */
    public toModel(dto: ReadCompanyDto): Company {
        const model = new Company();

        model.active = Boolean(dto.active);
        model.city = dto.city;
        model.cocNumber = dto.cocNumber || null;

        // eslint-disable-next-line no-undefined
        if (dto.companyType !== undefined) {
            model.companyType = (new CompanyTypeFactory()).toModel(dto.companyType.data);
            model.companyTypeId = model.companyType.id;
        }

        model.createdAt = dto.createdAt || null;
        model.email = dto.email;
        model.establishmentNumber = dto.establishmentNumber || null;
        model.houseNumber = dto.houseNumber || null;
        model.houseNumberPostfix = dto.houseNumberPostfix || null;
        model.id = Number(dto.id);
        model.lastCocCheck = dto.lastCocCheck || null;
        model.lat = dto.lat || null;
        model.linkedin = dto.linkedin || null;
        model.lng = dto.lng || null;
        model.mailbox = dto.mailbox || null;
        model.mailboxCity = dto.mailboxCity || null;
        model.mailboxPostal = dto.mailboxPostal || null;
        model.name = dto.name;
        model.phone = dto.phone;
        model.postal = dto.postal || null;
        model.province = dto.province || null;
        model.street = dto.street || null;

        // eslint-disable-next-line no-undefined
        if (dto.tradeNames !== undefined) {
            model.tradeNames = dto.tradeNames.data.map(tradeNames => tradeNames.label);
        }

        model.updatedAt = dto.updatedAt || null;
        model.uuid = dto.uuid || null;
        model.website = secureUrl(dto.website);

        return model;
    }
}
