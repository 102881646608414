export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

export const camelToSnake = (string: string): string => string.replaceAll(
    /[A-Z]/g,
    (letter): string => `_${letter.toLowerCase()}`,
);

export const kebabToSnake = (string: string): string => string.replaceAll('-', '_');

export const snakeToCamel = (string: string): string => string.replaceAll(/([_-]\w)/g, g => g[1].toUpperCase());

export const snakeToKebab = (string: string): string => string.replaceAll('_', '-');

export const snakeToPascal = (string: string): string => {
    const camelCase = snakeToCamel(string);
    const pascalCase = camelCase[0].toUpperCase() + camelCase.slice(1);

    return pascalCase;
};

export const formatCurrency = (number: number): string => {
    const formatter = new Intl.NumberFormat('nl-NL', {
        currency: 'EUR',
        style: 'currency',
    });

    return formatter.format(number);
};

// eslint-disable-next-line max-len
export const truncate = (string: string, length = 22): string => (string.length > length ? `${string.slice(0, Math.max(0, length))}…` : string);

export const secureUrl = (url: string | null): string | null => {
    const pattern = /^((http|https):\/\/)/;

    if (!url) {
        return url;
    }

    return pattern.test(url) ? url : `https://${url}`;
};

export const truncateWithSuffix = (
    text: string,
    maximumLength: number,
    suffix: string,
    showExtension = false,
// eslint-disable-next-line max-params
) => {
    const textArray = text.split('.');
    const extension = textArray.at((-1));

    // eslint-disable-next-line max-len
    return text.length > maximumLength ? `${text.slice(0, Math.max(0, maximumLength))}${suffix}${showExtension ? `.${extension}` : ''}` : text;
};
