import { BaseFactory } from './BaseFactory';
import type { ReadCertificateStateDto } from '~/types/CertificateState';
import { CertificateState } from '~/models/CertificateState';

export class CertificateStateFactory extends BaseFactory<ReadCertificateStateDto, CertificateState> {
    public toModel(dto: ReadCertificateStateDto): CertificateState {
        const model = new CertificateState();

        model.id = Number(dto.id);
        model.name = dto.name;
        model.translated = dto.translated;

        return model;
    }
}
