import { BaseFactory } from './BaseFactory';
import type { ReadRelationDepartmentDto } from '~/types/RelationDepartment';
import { RelationDepartment } from '~/models/RelationDepartment';

export class RelationDepartmentFactory extends BaseFactory<ReadRelationDepartmentDto, RelationDepartment> {
    public toModel(dto: ReadRelationDepartmentDto): RelationDepartment {
        const model = new RelationDepartment();

        model.id = dto.id ?? Number(dto.id);
        model.periodId = dto.periodId ?? Number(dto.periodId);

        model.department = dto.department || null;
        model.period = dto.period || null;
        model.status = dto.status || null;

        return model;
    }
}
