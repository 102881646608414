import { BaseFactory } from './BaseFactory';
import type { ReadMasterDto } from '~/types/MasterResponse';
import { Master } from '~/models/Master';

export class MasterFactory extends BaseFactory<ReadMasterDto, Master> {
    // eslint-disable-next-line max-statements
    public toModel(dto: ReadMasterDto): Master {
        const model = new Master();

        model.id = Number(dto.id);
        model.accountNumber = dto.accountNumber;
        model.lastName = dto.lastName;
        model.initials = dto.initials;
        model.middleName = dto.middleName;
        model.gender = dto.gender;
        model.dateOfBirth = dto.dateOfBirth;
        model.rtNumber = dto.rtNumber;
        model.company = dto.company;
        model.companyId = dto.companyId;
        model.noAnnualInvoice = Boolean(dto.noAnnualInvoice);
        model.excludeFromAddresscheckInvitation = dto.excludeFromAddresscheckInvitation;
        model.totalCompanions = dto.totalCompanions;
        model.certificateDate = dto.certificateDate;
        model.openForMaster = dto.openForMaster;

        return model;
    }
}
