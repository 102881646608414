import type { Relation } from './Relation';
import type { Reminder } from './Reminder';

export class SurveylistParticipant {
    public id: number | null = null;

    public mailSend: string | null = null;

    public startedDate: string | null = null;

    public endedDate: string | null = null;

    public relation: Relation | null = null;

    public reminder: Reminder | null = null;
}
