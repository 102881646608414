/* eslint-disable complexity */
import { BaseFactory } from './BaseFactory';
import { ChamberFactory } from './ChamberFactory';
import { CompanyFactory } from './CompanyFactory';
import { DepartmentFactory } from './DepartmentFactory';
import { Course } from '~/models/Course';
import type { ReadCourseDto } from '~/types/Course';

export class CourseFactory extends BaseFactory<ReadCourseDto, Course> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadCourseDto): Course {
        const model = new Course();

        model.approved = dto.approved || null;

        // eslint-disable-next-line no-undefined
        if (dto.chambers !== undefined) {
            model.chambers = (new ChamberFactory()).toModels(dto.chambers);
        }

        // eslint-disable-next-line no-undefined
        if (dto.companies !== undefined && dto.companies !== null) {
            model.companies = (new CompanyFactory()).toModels(dto.companies);
        }

        model.code = dto.code;
        model.description = dto.description || null;

        // eslint-disable-next-line no-undefined
        if (dto.departments !== undefined && dto.departments !== null) {
            model.departments = (new DepartmentFactory()).toModels(dto.departments);
        }

        model.id = Number(dto.id);

        // eslint-disable-next-line no-undefined
        if (dto.mainChamber !== undefined && dto.mainChamber !== null) {
            model.mainChamber = (new ChamberFactory()).toModel(dto.mainChamber);
        }

        model.link = dto.link || null;
        model.mainChamberCode = dto.mainChamberCode || null;
        model.name = dto.name;
        model.trainingPartner = dto.trainingPartner || null;
        model.year = Number(dto.year);
        model.points = Number(dto.points);

        return model;
    }
}
