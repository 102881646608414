export class RelationDepartment {
    public id: number | null = null;

    public periodId: number | null = null;

    public department: string | null = null;

    public period: string | null = null;

    public status: string | null = null;
}
