export class SurveyList {
    public id: number | null = null;

    public name: string | null = null;

    public type: string | null = null;

    public amountOfParticipants: number | null = null;

    public amountFinished: number | null = null;

    public intro: string | null = null;

    public outro: string | null = null;

    public mail: string | null = null;

    public mailReminder: string | null = null;

    public reminderMailSubject: string | null = null;

    public steps: number | null = null;
}
