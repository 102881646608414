import { BaseFactory } from './BaseFactory';
import { AttachmentFactory } from './AttachmentFactory';
import type { ReadPeriodCourseDto } from '~/types/PeriodCourse';
import { PeriodCourse } from '~/models/PeriodCourse';

export class PeriodCourseFactory extends BaseFactory<ReadPeriodCourseDto, PeriodCourse> {
    /* eslint-disable-next-line max-statements, complexity */
    public toModel(dto: ReadPeriodCourseDto): PeriodCourse {
        const model = new PeriodCourse();

        model.courseId = dto.courseId ?? Number(dto.courseId);
        model.coursePeriodId = dto.coursePeriodId ?? Number(dto.coursePeriodId);
        model.trainerId = dto.trainerId ?? Number(dto.trainerId);
        model.points = dto.points ?? Number(dto.points);

        model.chamberCode = dto.chamberCode || null;
        model.code = dto.code || null;
        model.date = dto.date || null;
        model.name = dto.name || null;
        model.trainerName = dto.trainerName || null;

        if (dto.attachments) {
            model.attachments = (new AttachmentFactory()).toModels(dto.attachments.data);
        }

        return model;
    }
}
