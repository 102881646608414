import { BaseFactory } from './BaseFactory';
import type { ReadReportDto } from '~/types/Report';
import { Report } from '~/models/Report';

export class ReportFactory extends BaseFactory<ReadReportDto, Report> {
    public toModel(dto: ReadReportDto): Report {
        const model = new Report();

        model.name = dto.name || null;

        model.typeString = dto.typeString || null;

        model.filename = dto.filename || null;

        model.createdAt = dto.createdAt || null;

        model.generated = Boolean(dto.generated) || null;

        model.id = Number(dto.id) || null;

        return model;
    }
}
