export class Report {
    public name: string | null = null;

    public id: number | null = null;

    public generated: boolean | null = null;

    public typeString: string | null = null;

    public filename: string | null = null;

    public createdAt: string | null = null;
}
