import type { CompanyType } from './CompanyType';
import type { CompanyLog } from './CompanyLog';

export class Company {
    public active: boolean | null = null;

    public city: string | null = null;

    public cocNumber: string | null = null;

    public companyLog: CompanyLog | null = null;

    public companyType: CompanyType | null = null;

    public companyTypeId: number | null = null;

    public createdAt: string | null = null;

    public email: string | null = null;

    public establishmentNumber: string | null = null;

    public houseNumber: string | null = null;

    public houseNumberPostfix: string | null = null;

    public id: number | null = null;

    public lastCocCheck: string | null = null;

    public lat: string | null = null;

    public linkedin: string | null = null;

    public lng: string | null = null;

    public mailbox: string | null = null;

    public mailboxCity: string | null = null;

    public mailboxPostal: string | null = null;

    public name: string | null = null;

    public phone: string | null = null;

    public postal: string | null = null;

    public province: string | null = null;

    public street: string | null = null;

    public tradeNames: string[] = [];

    public updatedAt: string | null = null;

    public uuid: string | null = null;

    public website: string | null = null;

    public getActive(): string {
        return this.active ? 'Ja' : 'Nee';
    }
}
