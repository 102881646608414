import { Attachment } from '../Attachment';
import { BaseFactory } from './BaseFactory';
import type { ReadAttachmentDto } from '~/types/ExtranetAttachment';

export class ExtranetAttachmentFactory extends BaseFactory<ReadAttachmentDto, Attachment> {
    public toModel(dto: ReadAttachmentDto): Attachment {
        const model = new Attachment();

        model.id = Number(dto.id);
        model.filename = dto.filename;
        model.documentDescription = dto.description;
        model.documentType = dto.documentType;
        model.createdAt = dto.createdAt;

        return model;
    }
}
