import { GET_BREADCRUMBS } from './-getter-types';
import { SET_BREADCRUMBS, SET_ROUTE_NAMESPACE } from './-mutation-types';
import type { Breadcrumb } from '~/models/Breadcrumb';
import { useApplicationStore } from '~/stores/application';

export const Actions = {
    // eslint-disable-next-line max-statements
    enrichBreadcrumb({ index, name }: Breadcrumb) {
        if (!index || index < 0) {
            // eslint-disable-next-line no-console
            console.warn('Application::enrichBreadcrumb index is empty');

            return;
        }

        // We know Nuxt defines names as strings so this is merely to satisfy TypeScript
        if (!name || typeof name !== 'string' || name.length === 0) {
            // eslint-disable-next-line no-console
            console.warn('Application::enrichBreadcrumb name is empty');

            return;
        }

        const applicationStore = useApplicationStore();

        const originalBreadcrumbs = [...applicationStore[GET_BREADCRUMBS]];

        if (!originalBreadcrumbs[index]) {
            // eslint-disable-next-line no-console
            console.warn(`Application::enrichBreadcrumb originalBreadcrumb with index ${index} is not found`);

            return;
        }

        const { name: originalName = '', ...originalBreakpoint } = originalBreadcrumbs[index];

        // We know Nuxt defines names as strings so this is merely to satisfy TypeScript
        if (typeof originalName !== 'string') {
            // eslint-disable-next-line no-console
            console.warn('Application::enrichBreadcrumb originalName is empty');

            return;
        }

        originalBreadcrumbs[index] = {
            ...originalBreakpoint,
            name: originalName.replace('…', name),
        };

        applicationStore[SET_BREADCRUMBS](originalBreadcrumbs);
    },
    removeBreadcrumb(index: number) {
        const applicationStore = useApplicationStore();
        applicationStore[SET_BREADCRUMBS]([...applicationStore[GET_BREADCRUMBS]]
            .filter((_, itemIndex) => itemIndex !== index));
    },
    // eslint-disable-next-line max-statements
    replaceBreadcrumb({ index, name, to = false }: Breadcrumb) {
        const applicationStore = useApplicationStore();
        const originalBreadcrumbs = [...applicationStore[GET_BREADCRUMBS]];

        if (!index || index < 0) {
            // eslint-disable-next-line no-console
            console.warn('Application::replaceBreadcrumb index is empty');

            return;
        }

        const breakpoint = {
            ...originalBreadcrumbs[index],
        };

        if (name) {
            breakpoint.name = name;
        }

        if (to) {
            breakpoint.to = to;
        }

        originalBreadcrumbs[index] = breakpoint;

        applicationStore[SET_BREADCRUMBS](originalBreadcrumbs);
    },
    setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
        const applicationStore = useApplicationStore();
        applicationStore[SET_BREADCRUMBS](breadcrumbs);
    },
    setRouteNamespace(routeNamespace: string) {
        const applicationStore = useApplicationStore();
        applicationStore[SET_ROUTE_NAMESPACE](routeNamespace);
    },
    setSaveUrl(url: string) {
        const applicationStore = useApplicationStore();
        applicationStore.saveUrl = url;
    },
};

export default Actions;
