export class ExtranetInvoice {
    public id: number | null = null;

    public invoiceNumber: string | null = null;

    public description: string | null = null;

    public totalAmount: string | null = null;

    public date: string | null = null;

    public status: string | null = null;

    public paymentLink: string | null = null;
}
