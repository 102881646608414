import type { Chamber } from './Chamber';
import type { Company } from './Company';
import type { Department } from './Department';
import type { ReadTrainingsPartnerDto } from '~/types/Course';

export class Course {
    public approved: boolean | null = null;

    public chambers: Chamber[] = [];

    public code: string | null = null;

    public companies: Company[] = [];

    public description: string | null = null;

    public departments: Department[] = [];

    public id: number | null = null;

    public link: string | null = null;

    public mainChamber: Chamber | null = null;

    public mainChamberCode: string | null = null;

    public name: string | null = null;

    public trainingPartner: ReadTrainingsPartnerDto | null = null;

    public points: number | null = null;

    public year: number | null = null;

    public getApproved(): string {
        return this.approved ? 'Ja' : 'Nee';
    }

    public getChambers(property: 'code' | 'name' = 'code'): string {
        if (this.chambers.length > 0) {
            return this.chambers
                // eslint-disable-next-line max-len
                .map(chamber => `${chamber[property]} - Verplicht: ${chamber.mandatory ? 'Ja' : 'Nee'} | Activiteit: ${chamber.activity ? 'Ja' : 'Nee'}`)
                .join('<br>');
        }

        if (this.mainChamber !== null) {
            return this.mainChamber[property] || '';
        }

        return this.mainChamberCode || '';
    }

    public getTrainingPartners(): string {
        if (this.companies.length > 0) {
            return this.companies
                .map(company => `${company.name}`)
                .join('<br>');
        }

        return this.trainingPartner?.name || '';
    }
}
