import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type ExtranetCertificatesOverview,
    type ExtranetCertificatesOverviewResponse,
    type ExtranetCertificatesUnsubscribeDateResponse,
    type ExtranetCertificatesUnsubscribeDatesResponse,
    type ReadExtranetCertificateDetailResponse,
    type ReadExtranetCertificateUnsubscribeDateDto,
    type ReadExtranetCertificateUnsubscribeDatesDto,
    type WriteUnsubscribeDto,
} from '~/types/ExtranetCertificate';
import { ExtranetCertificateFactory } from '~/models/factories/ExtranetCertificateFactory';
import { ExtranetCertificateDetailFactory } from '~/models/factories/ExtranetCertificateDetailFactory';
import { type ExtranetCertificateDetail } from '~/models/ExtranetCertificateDetail';
import { type WriteAttachmentDto } from '~/types/ExtranetAttachment';

export default class CertificateService extends BaseApiService {
    public static basePath = 'certificates';

    /**
     * @description Fetch certificates minimum unsubscribe date for the extranet user.
     * @returns {Promise<ReadExtranetCertificateUnsubscribeDateDto>} Promise with the minimum unsubscribe date
     */
    async fetchUnsubscribeDate(): Promise<ReadExtranetCertificateUnsubscribeDateDto> {
        try {
            const { data } = await this.baseGet<ExtranetCertificatesUnsubscribeDateResponse>(
                `${this.createPath(CertificateService.basePath)}/unsubscribe-date`,
            );

            return data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch certificates minimum unsubscribe dates for the extranet user.
     * @returns {Promise<ReadExtranetCertificateUnsubscribeDateDto>} Promise with the minimum unsubscribe date
     */
    async fetchUnsubscribeDates(): Promise<ReadExtranetCertificateUnsubscribeDatesDto> {
        try {
            const { data } = await this.baseGet<ExtranetCertificatesUnsubscribeDatesResponse>(
                `${this.createPath(CertificateService.basePath)}/unsubscribe-dates-list`,
            );

            return data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch multiple certificates for the extranet user.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<ExtranetCertificatesOverview>} Promise with the ExtranetCertificatesOverview as payload
     */
    async fetchCertificates(queryParameters = null): Promise<ExtranetCertificatesOverview> {
        try {
            const response = await this.baseGet<ExtranetCertificatesOverviewResponse>(
                `${this.createPath(CertificateService.basePath)}`,
                queryParameters,
            );

            const data = (new ExtranetCertificateFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch multiple certificates for the extranet user.
     * @param {number} certificateId Array of GET Parameters
     * @returns {Promise<ExtranetCertificatesOverview>} Promise with the ExtranetCertificatesOverview as payload
     */
    async fetchCertificate(certificateId: number): Promise<ExtranetCertificateDetail> {
        try {
            const response = await this.baseGet<ReadExtranetCertificateDetailResponse>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}`,
            );

            return (new ExtranetCertificateDetailFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch the proof of certificate file.
     * @returns {Promise<void>} Returns the certificate file
     */
    async fetchAttachment(): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(CertificateService.basePath)}/download`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch single attachment by attachment id.
     * @param {number} certificateId Identifier of the attachment to update
     * @param {WriteAttachmentDto} data Content of the attachment to be uploaded
     * @returns {Promise<null>} Returns the attachment file
     */
    async uploadAttachment(certificateId: number, data: WriteAttachmentDto): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/upload`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Unsubscribe Certificate.
     * @param {number} certificateId Identifier of the attachment to update
     * @param {WriteUnsubscribeDto} data certificate unsubscribe data
     * @returns {Promise<null>} Returns the attachment file
     */
    async unsubscribeCertificate(certificateId: number, data: WriteUnsubscribeDto): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/unsubscribe`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Unsubscribe all Certificate.
     * @param {WriteUnsubscribeDto} data certificate unsubscribe data
     * @returns {Promise<null>} Returns the attachment file
     */
    async unsubscribeAll(data: WriteUnsubscribeDto): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(CertificateService.basePath)}/unsubscribe`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }
}
