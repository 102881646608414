import { BaseFactory } from './BaseFactory';
import { RelationSurveyList } from '~/models/RelationSurveyList';
import type { ReadRelationSurveyListDto } from '~/types/RelationSurveyList';

export class RelationSurveyListFactory extends BaseFactory<ReadRelationSurveyListDto, RelationSurveyList> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadRelationSurveyListDto): RelationSurveyList {
        const model = new RelationSurveyList();

        model.id = Number(dto.id);
        model.surveyListId = dto.surveylistId;

        model.surveyListName = dto.surveylistName || null;
        model.mailSend = dto.mailSend || null;
        model.startedDate = dto.startedDate || null;
        model.endedDate = dto.endedDate || null;
        model.reminderSent = dto.reminderSent || null;
        model.reminderCount = dto.reminderCount;
        model.lastReminderSentDate = dto.lastReminderSentDate || null;

        return model;
    }
}
