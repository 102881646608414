import { format } from 'date-fns';

export class CompanyLog {
    public id: number | null = null;

    public username: string | null = null;

    public createdAt: string | null = null;

    public logMessage: string | null = null;

    public getFormatedCreatedDate(): string {
        return format(new Date(this.createdAt ?? ''), 'd MMMM y');
    }
}
