import { Reminder } from '../Reminder';
import { BaseFactory } from './BaseFactory';
import type { ReadReminderDto } from '~/types/Reminder';

export class ReminderFactory extends BaseFactory<ReadReminderDto, Reminder> {
    public toModel(dto: ReadReminderDto): Reminder {
        const model = new Reminder();

        model.lastSent = dto.lastSent;
        model.shouldSend = Number(dto.shouldSend);
        model.totalSent = Number(dto.totalSent);

        return model;
    }
}
