import { SurveyListOptionSet } from '../SurveyListOptionSet';
import { BaseFactory } from './BaseFactory';
import { SurveyListOptionFactory } from './SurveyListOptionFactory';
import type { ReadSurveyListOptionSetDto } from '~/types/SurveyListOptionSet';

export class SurveyListOptionSetFactory extends BaseFactory<ReadSurveyListOptionSetDto, SurveyListOptionSet> {
    public toModel(dto: ReadSurveyListOptionSetDto): SurveyListOptionSet {
        const model = new SurveyListOptionSet();

        model.id = Number(dto.id);
        model.name = dto.name;

        // eslint-disable-next-line no-undefined
        if (dto.options !== undefined) {
            model.options = (new SurveyListOptionFactory()).toModels(dto.options.data);
        }

        return model;
    }
}
