import { BaseFactory } from './BaseFactory';
import type { ReadExtranetCertificateDetailDto } from '~/types/ExtranetCertificate';
import { ExtranetCertificateDetail } from '~/models/ExtranetCertificateDetail';
import { ExtranetAttachmentFactory } from '~/models/factories/ExtranetAttachmentFactory';

export class ExtranetCertificateDetailFactory
    extends BaseFactory<ReadExtranetCertificateDetailDto, ExtranetCertificateDetail> {
    public toModel(dto: ReadExtranetCertificateDetailDto): ExtranetCertificateDetail {
        const model = new ExtranetCertificateDetail();

        model.id = dto.id;
        model.chamberName = dto.chamberName;
        model.certificateStatus = dto.certificateStatus;
        if (dto.attachments && dto.attachments?.data) {
            model.attachments = (new ExtranetAttachmentFactory()).toModels(dto.attachments.data);
        }

        return model;
    }
}
