import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type ExtranetCoursesOverview,
    type ExtranetCoursesOverviewResponse,
} from '~/types/ExtranetCourse';
import { ExtranetCourseFactory } from '~/models/factories/ExtranetCourseFactory';
import { type List } from '~/models/List';
import { type ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';
import { type WriteAttachmentDto } from '~/types/Attachment';

export default class ExtranetCourseService extends BaseApiService {
    public static basePath = 'courses';

    /**
     * @description Fetch multiple ExtranetCourses depending on values in queryString.
     * @param {number} year The year that is being fetched
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<ExtranetCoursesOverview>} Promise with the ExtranetCourseOverview as payload
     */
    async fetchExtranetCourses(year: number, queryParameters = null): Promise<ExtranetCoursesOverview> {
        try {
            const response = await this.baseGet<ExtranetCoursesOverviewResponse>(
                `${this.createPath(ExtranetCourseService.basePath)}?year=${year}`,
                queryParameters,
            );
            const data = (new ExtranetCourseFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch list of years for Extranet courses.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchExtranetCoursesYears(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(ExtranetCourseService.basePath)}/years-list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Create and link an attachment.
     * @param {number} courseId The resource to save
     * @param {WriteAttachmentDto} writeAttachmentDto The resource to save
     * @returns {Promise<null>} Api response
     */
    linkAndCreateAttachment(courseId: number, writeAttachmentDto: WriteAttachmentDto): Promise<null> {
        const formData = new FormData();
        formData.append('file', writeAttachmentDto.file);

        try {
            return this.basePost<null>(
                `${this.createPath(ExtranetCourseService.basePath)}/${courseId}/upload`,
                formData,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }
}
