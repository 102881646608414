import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type ExtranetActivitiesOverview,
    type ExtranetActivitiesOverviewResponse,
    type ExtranetActivityResponse,
    type WriteExtranetActivityDto,
} from '~/types/ExtranetActivity';
import { ExtranetActivityFactory } from '~/models/factories/ExtranetActivityFactory';
import { type List } from '~/models/List';
import { type ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';
import { type ExtranetActivity } from '~/models/ExtranetActivity';

export default class ActivityService extends BaseApiService {
    public static basePath = 'activities';

    /**
     * @description Fetch multiple ExtranetActivity depending on values in queryString.
     * @param {number} year The year that is being fetched
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<ExtranetActivitiesOverview>} Promise with the ExtranetActivitiesOverview as payload
     */
    async fetchActivities(year: number, queryParameters = null): Promise<ExtranetActivitiesOverview> {
        try {
            const response = await this.baseGet<ExtranetActivitiesOverviewResponse>(
                `${this.createPath(ActivityService.basePath)}?year=${year}`,
                queryParameters,
            );
            const data = (new ExtranetActivityFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch ExtranetActivity.
     * @param {number} activityId The year that is being fetched
     * @returns {Promise<ExtranetActivity>} Promise with the ExtranetActivity as payload
     */
    async fetchActivity(activityId: number): Promise<ExtranetActivity> {
        try {
            const response = await this.baseGet<ExtranetActivityResponse>(
                `${this.createPath(ActivityService.basePath)}/${activityId}`,
            );

            return (new ExtranetActivityFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch list of years for Extranet courses.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchYears(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(ActivityService.basePath)}/years-list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch list of years for Extranet courses.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchActivityTypeList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(ActivityService.basePath)}/types-list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Create activity for Extranet.
     * @param {WriteExtranetActivityDto} data Object of activity
     * @returns {Promise<ExtranetActivity>} Promise with the ExtranetActivity as payload
     */
    async createActivity(data: WriteExtranetActivityDto): Promise<ExtranetActivity> {
        const response = await this.basePost<ExtranetActivityResponse>(
            `${this.createPath(ActivityService.basePath)}`,
            data,
        );

        return (new ExtranetActivityFactory()).toModel(response.data);
    }

    /**
     * @description Update activity for Extranet.
     * @param {number} activityId Object of activity
     * @param {WriteExtranetActivityDto} data Object of activity
     * @returns {Promise<ExtranetActivity>} Promise with the ExtranetActivity as payload
     */
    async updateActivity(activityId: number, data: WriteExtranetActivityDto): Promise<ExtranetActivity> {
        const response = await this.basePut<ExtranetActivityResponse>(
            `${this.createPath(ActivityService.basePath)}/${activityId}`,
            data,
        );

        return (new ExtranetActivityFactory()).toModel(response.data);
    }

    /**
     * @description Delete activity for Extranet.
     * @param {number} activityId Object of activity
     * @returns {Promise<null>} Promise with the null as payload
     */
    async deleteActivity(activityId: number): Promise<null> {
        try {
            return await this.baseDelete(
                `${this.createPath(ActivityService.basePath)}/${activityId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Create and link an attachment.
     * @param {number} activityId The activity id
     * @param {FormData} data The form data
     * @returns {Promise<null>} Api response
     */
    async uploadAttachment(activityId: number, data: FormData): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(ActivityService.basePath)}/${activityId}/upload`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }
}
