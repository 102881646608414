import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type InvoiceCodeResponse,
    type InvoiceCodesOverview,
    type InvoiceCodesOverviewResponse,
    type WriteInvoiceCodeDto,
} from '~/types/InvoiceCode';
import { InvoiceCodeFactory } from '~/models/factories/InvoiceCodeFactory';
import { type InvoiceCode } from '~/models/InvoiceCode';
import { type List } from '~/models/List';
import { type ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';

export default class InvoiceCodeService extends BaseApiService {
    private basePath = 'products';

    /**
     * @description Delete a InvoiceCode.
     * @param {number} invoiceCodeId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(invoiceCodeId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(this.basePath)}/${invoiceCodeId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single InvoiceCode by InvoiceCode id.
     * @param {number} invoiceCodeId Identifier of the InvoiceCode to fetch
     * @returns {Promise<InvoiceCode>} Promise with the InvoiceCode model as payload
     */
    async fetchInvoiceCode(invoiceCodeId: number): Promise<InvoiceCode> {
        try {
            const response = await this.baseGet<InvoiceCodeResponse>(
                `${this.createPath(this.basePath)}/${invoiceCodeId}`,
            );

            return (new InvoiceCodeFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple InvoiceCodes depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<InvoiceCodesOverview>} Promise with the InvoiceCodesOverview as payload
     */
    async fetchInvoiceCodes(queryParameters = null): Promise<InvoiceCodesOverview> {
        try {
            const response = await this.baseGet<InvoiceCodesOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new InvoiceCodeFactory()).toModels(response.data);

            return {
                data,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new InvoiceCode.
     * @param {WriteInvoiceCodeDto} data Form data to post
     * @returns {Promise<InvoiceCode>} Api response
     */
    async create(data: WriteInvoiceCodeDto): Promise<InvoiceCode> {
        try {
            const response = await this.basePost<InvoiceCodeResponse>(
                this.createPath(this.basePath),
                data,
            );

            return (new InvoiceCodeFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of all InvoiceCodes.
     * @returns {Promise<List[]>} Promise with an array List models as payload
     */
    async fetchInvoiceCodesList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(this.basePath)}/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a InvoiceCode.
     * @param {number} invoiceCodeId ID of the resource to update
     * @param {WriteInvoiceCodeDto} data Form data to post
     * @returns {Promise<InvoiceCode>} Api response
     */
    async update(invoiceCodeId: number, data: WriteInvoiceCodeDto): Promise<InvoiceCode> {
        try {
            const response = await this.basePut<InvoiceCodeResponse>(
                `${this.createPath(this.basePath)}/${invoiceCodeId}`,
                data,
            );

            return (new InvoiceCodeFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
