export class RelationMovementList {
    public city: string | null = null;

    public email: string | null = null;

    public fullName: string | null = null;

    public id: number | null = null;

    public per: string | null = null;

    public phone: string | null = null;

    public relationId: number | null = null;

    public status: string | null = null;

    public statusText: string | null = null;
}
