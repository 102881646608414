import { BaseFactory } from './BaseFactory';
import type { ReadAssessmentDto } from '~/types/Assessment';
import { Assessment } from '~/models/Assessment';

export class AssessmentFactory extends BaseFactory<ReadAssessmentDto, Assessment> {
    // eslint-disable-next-line max-statements
    public toModel(dto: ReadAssessmentDto): Assessment {
        const model = new Assessment();

        model.id = Number(dto.id);
        model.relationId = dto.relationId ? Number(dto.relationId) : null;
        model.relationName = dto.relationName ?? null;

        model.importDate = dto.importDate;
        model.checkedDate = dto.checkedDate;
        model.year = dto.year;
        model.quarter = dto.quarter;
        model.createdAt = dto.createdAt;
        model.updatedAt = dto.updatedAt;

        return model;
    }
}
