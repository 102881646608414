import { BaseFactory } from './BaseFactory';
import { UserFactory } from './UserFactory';
import type { ReadRemarkDto } from '~/types/Remark';
import { Remark } from '~/models/Remark';

export class RemarkFactory extends BaseFactory<ReadRemarkDto, Remark> {
    public toModel(dto: ReadRemarkDto): Remark {
        const model = new Remark();

        // eslint-disable-next-line no-undefined
        if (dto.user !== undefined) {
            model.user = (new UserFactory()).toModel(dto.user.data);
            model.userEmail = model.user.email || null;
        }

        model.id = Number(dto.id);
        model.text = dto.text || null;
        model.important = dto.important || null;
        model.createdAt = dto.createdAt || null;

        return model;
    }
}
