import type { Attachment } from './Attachment';

export class ExtranetRelationSurveyListAnswer {
    public no: number | null = null;

    public question: string | null = null;

    public type: string | null = null;

    public else: boolean | null = null;

    public questionId: number | null = null;

    public isChild: boolean | null = false;

    public parentQuestionChoice: string | null = null;

    public answer: boolean | number | string | string[] | null = null;

    public attachments: Attachment[] | null = null;
}
