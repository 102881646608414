import type { Attachment } from './Attachment';

export class SurveyListAnswer {
    public questionId: number| null = null;

    public no: number| null = null;

    public question: string | null = null;

    public type: string | null = null;

    public isChild: boolean | null = null;

    public else: boolean | null = null;

    public parentQuestionChoice: string | null = null;

    public answer: string | null = null;

    public attachments: Attachment[] | null = null;
}
