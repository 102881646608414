/* eslint-disable max-lines */
import { type FetchError } from 'ofetch';
import { type Activity } from '~/models/Activity';
import { ActivityFactory } from '~/models/factories/ActivityFactory';
import { type ActivityResponse, type WriteActivityDto } from '~/types/Activity';
import { RelationAttachmentFactory } from '~/models/factories/RelationAttachmentFactory';
import { type RelationAttachmentsOverview, type RelationAttachmentsOverviewResponse } from '~/types/RelationAttachment';
import BaseApiService from '~/services/api/BaseApiService';
import { RelationFactory } from '~/models/factories/RelationFactory';
import { type WriteRelationDto } from '~/types/Relation';
import { List } from '~/models/List';
import { type ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';
import { type Relation } from '~/models/Relation';
import { CompanyFactory } from '~/models/factories/CompanyFactory';
import { type Company } from '~/models/Company';
import { type CompaniesOverview, type CompaniesOverviewResponse, type CompaniesResponse } from '~/types/Company';
import {
    type RelationResponse,
    type RelationsOverview,
    type RelationsOverviewResponse,
} from '~/types/RelationResponse';
import { DispensationFactory } from '~/models/factories/DispensationFactory';
import {
    type DispensationsOverview,
    type DispensationsOverviewResponse,
    type WriteDispensationDto,
} from '~/types/Dispensation';
import { InvoiceFactory } from '~/models/factories/InvoiceFactory';
import { type InvoicesOverview, type InvoicesOverviewResponse } from '~/types/Invoice';
import { LogFactory } from '~/models/factories/LogFactory';
import { type LogsOverview, type LogsOverviewResponse } from '~/types/Log';
import { MailFactory } from '~/models/factories/MailFactory';
import { type MailsOverview, type MailsOverviewResponse } from '~/types/Mail';
import { RelationActivityFactory } from '~/models/factories/RelationActivityFactory';
import { type RelationActivitiesOverview, type RelationActivitiesOverviewResponse } from '~/types/RelationActivity';
import { RelationCertificateFactory } from '~/models/factories/RelationCertificateFactory';
import {
    type RelationCertificatesOverview,
    type RelationCertificatesOverviewResponse,
} from '~/types/RelationCertificate';
import { RelationDepartmentFactory } from '~/models/factories/RelationDepartmentFactory';
import { type RelationDepartmentsOverview, type RelationDepartmentsOverviewResponse } from '~/types/RelationDepartment';
import { RelationSurveyListFactory } from '~/models/factories/RelationSurveyListFactory';
import { type RelationSurveyListsOverview, type RelationSurveyListsOverviewResponse } from '~/types/RelationSurveyList';
import { RelationTradeOrganisationFactory } from '~/models/factories/RelationTradeOrganisationFactory';
import {
    type RelationTradeOrganisationsOverview,
    type RelationTradeOrganisationsOverviewResponse,
    type WriteRelationTradeOrganisationDto,
} from '~/types/RelationTradeOrganisation';
import { RemarkFactory } from '~/models/factories/RemarkFactory';
import { type RemarksOverview, type RemarksOverviewResponse, type WriteRemarkDto } from '~/types/Remark';
import {
    type RelationMovementResponse,
    type RelationsMovementListOverview,
    type RelationsMovementOverviewListResponse,
    type WriteRelationMovementDto,
    type WriteRelationsMovementTransferDto,
} from '~/types/RelationMovement';
import { type RelationMovement } from '~/models/RelationMovement';
import { RelationMovementFactory } from '~/models/factories/RelationMovementFactory';
import { RelationMovementListFactory } from '~/models/factories/RelationMovementListFactory';
import { RelationCourseFactory } from '~/models/factories/RelationCourseFactory';
import {
    type RelationCourseResponse,
    type RelationCoursesOverview,
    type RelationCoursesOverviewResponse,
    type WriteRelationCourseDto,
} from '~/types/RelationCourse';
import { type RelationCourse } from '~/models/RelationCourse';
import { RelationPoints2018Factory } from '~/models/factories/RelationPoints2018Factory';
import { RelationPoints2019Factory } from '~/models/factories/RelationPoints2019Factory';
import { RelationPoints2020Factory } from '~/models/factories/RelationPoints2020Factory';
import { RelationPoints2021Factory } from '~/models/factories/RelationPoints2021Factory';
import { RelationPoints2022Factory } from '~/models/factories/RelationPoints2022Factory';
import { DisciplinaryLawFactory } from '~/models/factories/DisciplinaryLawFactory';
import {
    type DisciplinaryLawsOverview,
    type DisciplinaryLawsOverviewResponse,
    type WriteDisciplinaryLawDto,
} from '~/types/DisciplinaryLaw';
import { PointsYearFactory } from '~/models/factories/PointsYearFactory';
import { type PointsYear } from '~/models/PointsYear';
import { type PointsYearsResponse } from '~/types/PointsYear';
import { RelationDepartmentPointsFactory } from '~/models/factories/RelationDepartmentPointsFactory';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import { type MailLogsOverview, type MailLogsOverviewResponse } from '~/types/MailLog';
import { MailLogFactory } from '~/models/factories/MailLogFactory';
import type { MastersOverview, MastersOverviewResponse } from '~/types/MasterResponse';
import { MasterFactory } from '~/models/factories/MasterFactory';
import { RelationPoints2024Factory } from '~/models/factories/RelationPoints2024Factory';

export default class RelationService extends BaseApiService {
    public static basePath = 'relations';

    /**
     * @description Delete a relation.
     * @param {number} relationId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(relationId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(RelationService.basePath)}/${relationId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Transfer a relation company address.
     * @param {number} movementId Id of the resource to transfer
     * @param {WriteRelationsMovementTransferDto} data content to applay the transfer
     * @returns {Promise<null>} Api response
     */
    movementTransfer(movementId: number, data: WriteRelationsMovementTransferDto): Promise<null> {
        try {
            if (data.action === 'decline') {
                return this.baseDelete(
                    `${this.createPath(RelationService.basePath)}/movements/${movementId}`,
                );
            }

            return this.basePut<null>(
                `${this.createPath(RelationService.basePath)}/movements/${movementId}`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new relation.
     * @param {WriteRelationDto} data Form data to post
     * @returns {Promise<Relation>} Api response
     */
    async create(data: WriteRelationDto): Promise<Relation> {
        try {
            const response = await this.basePost<RelationResponse>(
                this.createPath(RelationService.basePath),
                data,
            );

            return (new RelationFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single relation by relation id.
     * @param {number} relationId Identifier of the relation to fetch
     * @returns {Promise<Relation>} Promise with the Relation model as payload
     */
    async fetchRelation(relationId: number): Promise<Relation> {
        try {
            const response = await this.baseGet<RelationResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}`,
            );

            return (new RelationFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single relation activity by relation & activity id.
     * @param {number} relationId Identifier of the relation to fetch
     * @param {number} activityId Identifier of the activity to fetch
     * @returns {Promise<Activity>} Promise with the Activity model as payload
     */
    async fetchRelationActivity(relationId: number, activityId: number): Promise<Activity> {
        try {
            const response = await this.baseGet<ActivityResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/activities/${activityId}`,
            );

            return (new ActivityFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple relations depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @param {string} path optional sub path for fetch relations
     * @returns {Promise<RelationsOverview>} Promise with the RelationOverview as payload
     */
    async fetchRelations(queryParameters = null, path = ''): Promise<RelationsOverview> {
        try {
            const response = await this.baseGet<RelationsOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${path}`,
                queryParameters,
            );

            const data = (new RelationFactory()).toModels(response.data);

            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple relations depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @param {string} path optional sub path for fetch relations
     * @returns {Promise<RelationsMovementListOverview>} Promise with the RelationOverview as payload
     */
    async fetchRelationsMovements(queryParameters = null, path = ''): Promise<RelationsMovementListOverview> {
        try {
            const response = await this.baseGet<RelationsMovementOverviewListResponse>(
                `${this.createPath(RelationService.basePath)}/${path}`,
                queryParameters,
            );

            const data = (new RelationMovementListFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of relations.
     * @param {object} queryParameters Object of query parameters
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchRelationsList(queryParameters = null): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(RelationService.basePath)}/list`,
                queryParameters,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple masters depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<MastersOverview>} Promise with the MastersOverview as payload
     */
    async fetchMasters(queryParameters = null): Promise<MastersOverview> {
        try {
            const response = await this.baseGet<MastersOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/masters`,
                queryParameters,
            );

            const data = (new MasterFactory()).toModels(response.data);

            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of masters.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchMastersList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(RelationService.basePath)}/masters/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of relations.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchGenderList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(RelationService.basePath)}/genders/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of relation companions.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchCompanionList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(RelationService.basePath)}/masters/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple activities depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked activities of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<RelationActivitiesOverview>} Promise with the RelationActivitiesOverviewResponse as payload
     */
    async fetchLinkedActivities(relationId: number, queryParameters = null): Promise<RelationActivitiesOverview> {
        try {
            const response = await this.baseGet<RelationActivitiesOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/activities`,
                queryParameters,
            );

            const data = (new RelationActivityFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple attachments depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked attachments of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<RelationAttachmentsOverview>} Promise with the RelationAttachmentsOverviewResponse as payload
     */
    async fetchLinkedAttachments(relationId: number, queryParameters = null): Promise<RelationAttachmentsOverview> {
        try {
            const response = await this.baseGet<RelationAttachmentsOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/attachments`,
                queryParameters,
            );

            const data = (new RelationAttachmentFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple companies depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked companies of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CompaniesOverview>} Promise with the CompaniesOverview as payload
     */
    async fetchLinkedCompanies(relationId: number, queryParameters = null): Promise<CompaniesOverview> {
        try {
            const response = await this.baseGet<CompaniesOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/companies`,
                queryParameters,
            );

            const data = (new CompanyFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple dispensations depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked dispensations of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<DispensationsOverview>} Promise with the DispensationsOverview as payload
     */
    async fetchLinkedDispensations(relationId: number, queryParameters = null): Promise<DispensationsOverview> {
        try {
            const response = await this.baseGet<DispensationsOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/dispensations`,
                queryParameters,
            );

            const data = (new DispensationFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple certificates depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked certificates of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<RelationCertificatesOverview>} Promise with the RelationCertificatesOverview as payload
     */
    async fetchLinkedCertificates(relationId: number, queryParameters = null): Promise<RelationCertificatesOverview> {
        try {
            const response = await this.baseGet<RelationCertificatesOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/certificates`,
                queryParameters,
            );

            const data = (new RelationCertificateFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple departments depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked departments of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<RelationDepartmentsOverview>} Promise with the RelationDepartmentsOverview as payload
     */
    async fetchLinkedDepartments(relationId: number, queryParameters = null): Promise<RelationDepartmentsOverview> {
        try {
            const response = await this.baseGet<RelationDepartmentsOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/departments`,
                queryParameters,
            );

            const data = (new RelationDepartmentFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple invoices depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked invoices of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<InvoicesOverview>} Promise with the InvoicesOverview as payload
     */
    async fetchLinkedInvoices(relationId: number, queryParameters = null): Promise<InvoicesOverview> {
        try {
            const response = await this.baseGet<InvoicesOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/invoices`,
                queryParameters,
            );

            const data = (new InvoiceFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple disciplinary laws depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked logs of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<DisciplinaryLawsOverview>} Promise with the LogsOverview as payload
     */
    async fetchLinkedDisciplinaryLaws(
        relationId: number,
        queryParameters = null,
    ): Promise<DisciplinaryLawsOverview> {
        try {
            const response = await this.baseGet<DisciplinaryLawsOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/disciplinary-law`,
                queryParameters,
            );

            const data = (new DisciplinaryLawFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple logs depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked logs of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<LogsOverview>} Promise with the LogsOverview as payload
     */
    async fetchLinkedLogs(relationId: number, queryParameters = null): Promise<LogsOverview> {
        try {
            const response = await this.baseGet<LogsOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/logs`,
                queryParameters,
            );

            const data = (new LogFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple mails depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked mails of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<MailsOverview>} Promise with the MailsOverview as payload
     */
    async fetchLinkedMails(relationId: number, queryParameters = null): Promise<MailsOverview> {
        try {
            const response = await this.baseGet<MailsOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/mails`,
                queryParameters,
            );

            const data = (new MailFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchMailLogs(relationId: number, queryParameters = null): Promise<MailLogsOverview> {
        try {
            const response = await this.baseGet<MailLogsOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/maillogs`,
                queryParameters,
            );

            const data = (new MailLogFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple relation trade organisations depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked relation trade organisations of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<RelationTradeOrganisationsOverview>} Promise with RelationTradeOrganisationsOverview as payload
     */
    async fetchLinkedRelationTradeOrganisations(
        relationId: number,
        queryParameters = null,
    ): Promise<RelationTradeOrganisationsOverview> {
        try {
            const response = await this.baseGet<RelationTradeOrganisationsOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/trade-organisations`,
                queryParameters,
            );

            const data = (new RelationTradeOrganisationFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple remarks depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked remarks of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<RemarksOverview>} Promise with the RemarksOverview as payload
     */
    async fetchLinkedRemarks(relationId: number, queryParameters = null): Promise<RemarksOverview> {
        try {
            const response = await this.baseGet<RemarksOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/remarks`,
                queryParameters,
            );

            const data = (new RemarkFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple surveylists depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked surveylists of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<RelationSurveyListsOverview>} Promise with the RelationSurveyListsOverview as payload
     */
    async fetchLinkedSurveyLists(relationId: number, queryParameters = null): Promise<RelationSurveyListsOverview> {
        try {
            const response = await this.baseGet<RelationSurveyListsOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/surveylists`,
                queryParameters,
            );

            const data = (new RelationSurveyListFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple courses depending on relation id.
     * @param {number} relationId Id of the relation to fetch the linked courses of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<RelationCoursesOverview>} Promise with the RelationCoursesOverview as payload
     */
    async fetchLinkedCourses(relationId: number, queryParameters = null): Promise<RelationCoursesOverview> {
        try {
            const response = await this.baseGet<RelationCoursesOverviewResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/courses`,
                queryParameters,
            );

            const data = (new RelationCourseFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch course by relation and course id.
     * @param {number} relationId Id of the relation to fetch the linked courses of
     * @param {number} courseId Array of GET Parameters
     * @returns {Promise<RelationCourse>} Promise with the CoursesOverview as payload
     */
    async fetchLinkedCourse(relationId: number, courseId: number): Promise<RelationCourse> {
        try {
            const response = await this.baseGet<RelationCourseResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/courses/${courseId}`,
            );

            return (new RelationCourseFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Link course to a relation.
     * @param {number} relationId Id of the resource to save
     * @param {WriteRelationCourseDto} data POST data
     * @returns {Promise<null>} Api response
     */
    async linkCourse(relationId: number, data: WriteRelationCourseDto): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}/courses`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Unlink course from a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} courseId Id of the course to unlink
     * @returns {Promise<null>} Api response
     */
    async unlinkCourse(relationId: number, courseId: number): Promise<null> {
        try {
            return await this.baseDelete(
                `${this.createPath(RelationService.basePath)}/${relationId}/courses/${courseId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update the year of the course.
     * @param {number} relationId Id of the relation
     * @param {number} courseId Id of the course
     * @param {number} applicableYear Year of the course
     * @returns {Promise<null>} Api response
     */
    async updateLinkedCourse(relationId: number, courseId: number, applicableYear: number): Promise<RelationCourse> {
        try {
            const response = await this.basePatch<RelationCourseResponse>(
                // eslint-disable-next-line max-len
                `${this.createPath(RelationService.basePath)}/${relationId}/courses/${courseId}?applicableYear=${applicableYear}`,
            );

            return (new RelationCourseFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Link companies to a relations.
     * @param {number} relationId Id of the resource to save
     * @param {List[] | Company[]} data Array of List or Model of companies to link
     * @returns {Promise<CompaniesResponse>} Api response
     */
    async linkCompanies(relationId: number, data: List[] | Company[]): Promise<Company[]> {
        try {
            const response = await this.basePut<CompaniesResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/companies/sync`,
                {
                    companies: data.map((company: List | Company) => ({
                        id: company instanceof List ? company.value : company.id,
                    })),
                },
            );

            return (new CompanyFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Link company to a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} companyId Id of the company to link
     * @returns {Promise<null>} Api response
     */
    async linkCompany(relationId: number, companyId: number): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}/companies`,
                {
                    companyId,
                },
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create and link a Activity to a relation.
     * @param {number} relationId Id of the resource to save
     * @param {WriteActivityDto} writeActivityDto The resource to save
     * @returns {Promise<null>} Api response
     */
    async linkAndCreateActivity(relationId: number, writeActivityDto: WriteActivityDto): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}/activities`,
                writeActivityDto,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update and link a Activity to a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} activityId Id of the resource to save
     * @param {WriteActivityDto} writeActivityDto The resource to save
     * @returns {Promise<null>} Api response
     */
    async linkAndUpdateActivity(
        relationId: number,
        activityId: number,
        writeActivityDto: WriteActivityDto,
    ): Promise<null> {
        try {
            return await this.basePatch<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}/activities/${activityId}`,
                writeActivityDto,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create and link a disciplinary law to a relation.
     * @param {number} relationId Id of the resource to save
     * @param {writeDisciplinaryLawDto} writeDisciplinaryLawDto The resource to save
     * @returns {Promise<null>} Api response
     */
    async linkAndCreateDisciplinaryLaw(
        relationId: number,
        writeDisciplinaryLawDto: WriteDisciplinaryLawDto,
    ): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}/disciplinary-law`,
                writeDisciplinaryLawDto,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update and link a disciplinary law to a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} disciplinaryLawId Id of the resource to save
     * @param {WriteDisciplinaryLawDto} writeDisciplinaryLawDto The resource to save
     * @returns {Promise<null>} Api response
     */
    async linkAndUpdateDisciplinaryLaw(
        relationId: number,
        disciplinaryLawId: number,
        writeDisciplinaryLawDto: WriteDisciplinaryLawDto,
    ): Promise<null> {
        try {
            return await this.basePut<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}/disciplinary-law/${disciplinaryLawId}`,
                writeDisciplinaryLawDto,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create and link a dispensation to a relation.
     * @param {number} relationId Id of the resource to save
     * @param {WriteDispensationDto} writeDispensationDto The resource to save
     * @returns {Promise<null>} Api response
     */
    async linkAndCreateDispensation(relationId: number, writeDispensationDto: WriteDispensationDto): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}/dispensations`,
                writeDispensationDto,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update and link a dispensation to a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} dispensationId Id of the resource to save
     * @param {WriteDispensationDto} writeDispensationDto The resource to save
     * @returns {Promise<null>} Api response
     */
    async linkAndUpdateDispensation(
        relationId: number, dispensationId: number, writeDispensationDto: WriteDispensationDto): Promise<null> {
        try {
            return await this.basePut<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}/dispensations/${dispensationId}`,
                writeDispensationDto,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create and link a relation trade organisation to a relation.
     * @param {number} relationId Id of the resource to save
     * @param {writeRelationTradeOrganisationDto} writeRelationTradeOrganisationDto The resource to save
     * @returns {Promise<null>} Api response
     */
    async linkAndCreateRelationTradeOrganisation(
        relationId: number,
        writeRelationTradeOrganisationDto: WriteRelationTradeOrganisationDto,
    ): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}/trade-organisations`,
                writeRelationTradeOrganisationDto,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update and link a relation trade organisation to a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} relationTradeOrganisation Id of the resource to save
     * @param {WriteRelationTradeOrganisationDto} writeRelationTradeOrganisationDto The resource to save
     * @returns {Promise<null>} Api response
     */
    async linkAndUpdateRelationTradeOrganisation(
        relationId: number,
        relationTradeOrganisation: number,
        writeRelationTradeOrganisationDto: WriteRelationTradeOrganisationDto,
    ): Promise<null> {
        try {
            return await this.basePatch<null>(
                // eslint-disable-next-line max-len
                `${this.createPath(RelationService.basePath)}/${relationId}/trade-organisations/${relationTradeOrganisation}`,
                writeRelationTradeOrganisationDto,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create and link a remark to a relation.
     * @param {number} relationId Id of the resource to save
     * @param {WriteRemarkDto} writeRemarkDto The resource to save
     * @returns {Promise<null>} Api response
     */
    async linkAndCreateRemark(relationId: number, writeRemarkDto: WriteRemarkDto): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}/remarks`,
                writeRemarkDto,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Unlink company from a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} companyId Id of the company to unlink
     * @returns {Promise<null>} Api response
     */
    async unlinkCompany(relationId: number, companyId: number): Promise<null> {
        try {
            return await this.baseDelete(
                `${this.createPath(RelationService.basePath)}/${relationId}/companies/${companyId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Unlink dispensation from a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} activityId Id of the dispensation to unlink
     * @returns {Promise<null>} Api response
     */
    async unlinkAndDeleteActivity(relationId: number, activityId: number): Promise<null> {
        try {
            return await this.baseDelete(
                `${this.createPath(RelationService.basePath)}/${relationId}/activities/${activityId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Unlink disciplinary law from a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} disciplinaryLawId Id of the disciplinary law to unlink
     * @returns {Promise<null>} Api response
     */
    async unlinkAndDeleteDisciplinaryLaw(relationId: number, disciplinaryLawId: number): Promise<null> {
        try {
            return await this.baseDelete(
                `${this.createPath(RelationService.basePath)}/${relationId}/disciplinary-law/${disciplinaryLawId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Unlink dispensation from a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} dispensationId Id of the dispensation to unlink
     * @returns {Promise<null>} Api response
     */
    async unlinkAndDeleteDispensation(relationId: number, dispensationId: number): Promise<null> {
        try {
            return await this.baseDelete(
                `${this.createPath(RelationService.basePath)}/${relationId}/dispensations/${dispensationId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Unlink dispensation from a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} relationTradeOrganisationId Id of the relation trade organisation to unlink
     * @returns {Promise<null>} Api response
     */
    async unlinkAndDeleteRelationTradeOrganisation(
        relationId: number,
        relationTradeOrganisationId: number,
    ): Promise<null> {
        try {
            return await this.baseDelete(
                // eslint-disable-next-line max-len
                `${this.createPath(RelationService.basePath)}/${relationId}/trade-organisations/${relationTradeOrganisationId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Unlink remark from a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} remarkId Id of the remark to unlink
     * @returns {Promise<null>} Api response
     */
    async unlinkAndDeleteRemark(relationId: number, remarkId: number): Promise<null> {
        try {
            return await this.baseDelete(
                `${this.createPath(RelationService.basePath)}/${relationId}/remarks/${remarkId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Toggle remark important from a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} remarkId Id of the remark to toggle
     * @returns {Promise<null>} Api response
     */
    async toggleRemarkImportant(relationId: number, remarkId: number): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}/remarks/${remarkId}/important`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Restore a relation.
     * @param {number} relationId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    restore(relationId: number): Promise<null> {
        try {
            return this.basePost<null>(`${this.createPath(RelationService.basePath)}/${relationId}/undo`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a relation.
     * @param {number} relationId Id of the resource to save
     * @param {WriteRelationDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(relationId: number, data: WriteRelationDto): Promise<null> {
        try {
            return this.basePut<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new movement for a relation.
     * @param {number} relationId Id of the resource to save
     * @param {WriteRelationDto} data Form data to post
     * @returns {Promise<Relation>} Api response
     */
    async createMovement(relationId: number, data: WriteRelationMovementDto): Promise<RelationMovement> {
        try {
            const response = await this.basePost<RelationMovementResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/movements`,
                data,
            );

            return (new RelationMovementFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single movement by relation & movement id.
     * @param {number} relationId Identifier of the relation to fetch
     * @param {number} movementId Identifier of the relation to fetch
     * @returns {Promise<RelationMovement>} Promise with the Movement model as payload
     */
    async fetchMovement(relationId: number, movementId: number): Promise<RelationMovement> {
        try {
            const response = await this.baseGet<RelationMovementResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/movements/${movementId}`,
            );

            return (new RelationMovementFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update movement of a relation.
     * @param {number} relationId Id of the resource to save
     * @param {number} movementId Id of the resource to save
     * @param {WriteRelationMovementDto} writeRelationMovementDto The resource to save
     * @returns {Promise<null>} Api response
     */
    async updateMovement(
        relationId: number,
        movementId: number,
        writeRelationMovementDto: WriteRelationMovementDto,
    ): Promise<null> {
        try {
            return await this.basePut<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}/movements/${movementId}`,
                writeRelationMovementDto,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch points of a single relation by year.
     * @param {number} relationId Identifier of the relation to fetch
     * @param {number} year The year to fetch for the points
     * @returns {Promise<any>} Promise with the RelationPoints20xx model as payload
     */
    // eslint-disable-next-line max-statements, @typescript-eslint/no-explicit-any
    async fetchPoints(relationId: number, year: number): Promise<any> {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response = await this.baseGet<any>(
                `${this.createPath(RelationService.basePath)}/${relationId}/points/${year}`,
            );

            // I'm so sorry..
            if (year === 2018) {
                return (new RelationPoints2018Factory()).toModel(response.data);
            }
            if (year === 2019) {
                return (new RelationPoints2019Factory()).toModel(response.data);
            }
            if (year === 2020) {
                return (new RelationPoints2020Factory()).toModel(response.data);
            }
            if (year === 2021) {
                return (new RelationPoints2021Factory()).toModel(response.data);
            }
            if (year === 2022 || year === 2023) {
                return (new RelationPoints2022Factory()).toModel(response.data);
            }

            return (new RelationPoints2024Factory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of years for points.
     * @param {number} relationId The id of the relation to fetch
     * @returns {Promise<PointsYear[]>} Promise with the PointsYearsResponse as payload
     */
    async fetchPointsYears(relationId: number): Promise<PointsYear[]> {
        try {
            const response = await this.baseGet<PointsYearsResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/pointsyears`,
            );

            return (new PointsYearFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch points of a single relation by year.
     * @param {number} relationId Identifier of the relation to fetch
     * @param {number} year The year to fetch for the points
     * @returns {Promise<any>} Promise with the RelationPoints20xx model as payload
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async fetchDepartmentsPoints(relationId: number, year: number): Promise<any> {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response = await this.baseGet<any>(
                `${this.createPath(RelationService.basePath)}/${relationId}/departments/${year}`,
            );

            return (new RelationDepartmentPointsFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of years for points.
     * @param {number} relationId The id of the relation to fetch
     * @returns {Promise<PointsYear[]>} Promise with the PointsYearsResponse as payload
     */
    async fetchDepartmentsPointsYears(relationId: number): Promise<PointsYear[]> {
        try {
            const response = await this.baseGet<PointsYearsResponse>(
                `${this.createPath(RelationService.basePath)}/${relationId}/departmentyears`,
            );

            return (new PointsYearFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Relation document check.
     * @param {number} relationId The id of the relation to do document check
     * @returns {Promise<null>} Promise with the PointsYearsResponse as payload
     */
    async documentChecked(relationId: number): Promise<null> {
        try {
            return await this.basePut<null>(
                `${this.createPath(RelationService.basePath)}/${relationId}/documentschecked`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }
}
