export class ExtranetCertificate {
    public chamberName: string | null = null;

    public id: number | null = null;

    public years: string[] = [];

    public unsubscribeDate: string | null = null;

    public requestUnsubscribeDate: string | null = null;

    public getYears(): string {
        return this.years.join(', ');
    }
}
