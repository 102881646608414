import { type PaginationResponse } from '~/types/Meta';

export type DataGridState = {
    dataGrids: {
        [key: string]: {
            identifier: string;
            items: unknown;
            storeModule: string;
            filters: {[key: string]: string | number};
            filtersChanged: number | null;
            loading: boolean;
            orderBy: string | string[] | null;
            page: number;
            perPage: number;
            sortDescending: number | null;
            queryString: string | null;
            pagination: PaginationResponse;
            parameters: {[key: string]: string | number};
        };
    };
}

export const defaultStoreValues = {
    filters: {},
    filtersChanged: null,
    identifier: '',
    items: null,
    loading: false,
    orderBy: null,
    page: 1,
    pagination: {
        count: 0,
        current_page: 0,
        per_page: 0,
        total: 0,
        total_pages: 0,
    },
    parameters: {},
    perPage: 25,
    queryString: null,
    sortDescending: null,
    storeModule: '',
};

export const defaultStateData = (): DataGridState => ({
    dataGrids: {},
});

export const DefaultState = (): DataGridState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
