import type { PointsCombinationChamber } from './PointsCombinationChamber';

export class PointsRequiredYear {
    public id: number | null = null;

    public year: number | null = null;

    public elearningCourses: number[] | null = [];

    public pointToOverflow: number | null = null;

    public peCombinationChambers: PointsCombinationChamber[] = [];
}
