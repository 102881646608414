import { BaseFactory } from './BaseFactory';
import type { ReadDocumentDto } from '~/types/Document';
import { Document } from '~/models/Document';

export class DocumentFactory extends BaseFactory<ReadDocumentDto, Document> {
    public toModel(dto: ReadDocumentDto): Document {
        const model = new Document();

        model.active = Boolean(dto.active);
        model.id = Number(dto.id);
        model.name = dto.name || null;
        model.originalName = dto.originalName || null;

        return model;
    }
}
