import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type CourseResponse,
    type CoursesOverview,
    type CoursesOverviewResponse,
    type WriteCourseDto,
} from '~/types/Course';
import { CourseFactory } from '~/models/factories/CourseFactory';
import { type Course } from '~/models/Course';
import { type List } from '~/models/List';
import { type ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';
import { LogFactory } from '~/models/factories/LogFactory';
import { type LogsOverview, type LogsOverviewResponse } from '~/types/Log';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';

export default class CourseService extends BaseApiService {
    private basePath = 'courses';

    /**
     * @description Delete a course.
     * @param {number} courseId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(courseId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(this.basePath)}/${courseId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Aprove a course.
     * @param {number} courseId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    toggleCourseApproved(courseId: number): Promise<null> {
        try {
            return this.basePatch<null>(`${this.createPath(this.basePath)}/${courseId}/toggleapproved`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single course by course id.
     * @param {number} courseId Identifier of the course to fetch
     * @returns {Promise<Course>} Promise with the Course model as payload
     */
    async fetchCourse(courseId: number): Promise<Course> {
        try {
            const response = await this.baseGet<CourseResponse>(
                `${this.createPath(this.basePath)}/${courseId}`,
            );

            return (new CourseFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple courses depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CoursesOverview>} Promise with the CoursesOverview as payload
     */
    async fetchCourses(queryParameters = null): Promise<CoursesOverview> {
        try {
            const response = await this.baseGet<CoursesOverviewResponse>(
                this.createPath(this.basePath),
                queryParameters,
            );

            const data = (new CourseFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new course.
     * @param {WriteCourseDto} data Form data to post
     * @returns {Promise<Course>} Api response
     */
    async create(data: WriteCourseDto): Promise<Course> {
        try {
            const response = await this.basePost<CourseResponse>(
                this.createPath(this.basePath),
                data,
            );

            return (new CourseFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of all courses.
     * @param {boolean} code Whether to include the code as the label
     * @returns {Promise<List[]>} Promise with an array List models as payload
     */
    async fetchCoursesList(code: false): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(this.basePath)}/list${code ? '?code=true' : ''}`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a course.
     * @param {number} courseId ID of the resource to update
     * @param {WriteCourseDto} data Form data to post
     * @returns {Promise<Course>} Api response
     */
    async update(courseId: number, data: WriteCourseDto): Promise<Course> {
        try {
            const response = await this.basePut<CourseResponse>(
                `${this.createPath(this.basePath)}/${courseId}`,
                data,
            );

            return (new CourseFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of all courses.
     * @returns {Promise<List[]>} Promise with an array List models as payload
     */
    async fetchAttendanceYears(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(this.basePath)}/attendance-years`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Upload a file for presention of a course.
     * @param {FormData} formData formData that is being sent as payload
     * @returns {Promise<null>} Promise with null response
     */
    async uploadAttendance(formData: FormData): Promise<null> {
        try {
            return await this.basePost(
                `${this.createPath(this.basePath)}/attendance`,
                formData,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple logs depending on course id.
     * @param {number} courseId Id of the course to fetch the linked logs of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<LogsOverview>} Promise with the LogsOverview as payload
     */
    async fetchLinkedLogs(courseId: number, queryParameters = null): Promise<LogsOverview> {
        try {
            const response = await this.baseGet<LogsOverviewResponse>(
                `${this.createPath(this.basePath)}/${courseId}/logs`,
                queryParameters,
            );

            const data = (new LogFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
