import { BaseFactory } from './BaseFactory';
import { CompanyLog } from '~/models/CompanyLog';
import type { ReadCompanyLogsDto } from '~/types/CompanyLog';

export class CompanyLogFactory extends BaseFactory<ReadCompanyLogsDto, CompanyLog> {
    public toModel(dto: ReadCompanyLogsDto): CompanyLog {
        const model = new CompanyLog();

        model.id = Number(dto.id);
        model.username = dto.username;
        model.createdAt = dto.createdAt;
        model.logMessage = dto.logMessage;

        return model;
    }
}
