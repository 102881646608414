import { DispensationType } from '../DispensationType';
import { BaseFactory } from './BaseFactory';
import type { ReadDispensationTypeDto } from '~/types/DispensationType';

export class DispensationTypeFactory extends BaseFactory<ReadDispensationTypeDto, DispensationType> {
    public toModel(dto: ReadDispensationTypeDto): DispensationType {
        const model = new DispensationType();

        model.id = Number(dto.id);
        model.name = dto.name;

        return model;
    }
}
