import { BaseFactory } from './BaseFactory';
import type { ReadTradeOrganisationDto } from '~/types/TradeOrganisation';
import { TradeOrganisation } from '~/models/TradeOrganisation';

export class TradeOrganisationFactory extends BaseFactory<ReadTradeOrganisationDto, TradeOrganisation> {
    public toModel(dto: ReadTradeOrganisationDto): TradeOrganisation {
        const model = new TradeOrganisation();

        model.id = Number(dto.id);
        model.name = dto.name;
        model.shortName = dto.shortName;
        model.number = dto.number;

        return model;
    }
}
