import type { ExtranetRelationSurveyListAnswer } from './ExtranetRelationSurveyListAnswer';
import type { SurveyList } from './SurveyList';

export class ExtranetRelationSurveyList {
    public id: number | null = null;

    public uuid: string | null = null;

    public startedDate: string | null = null;

    public endedDate: string | null = null;

    public surveylist: SurveyList | null = null;

    public allAnswers: ExtranetRelationSurveyListAnswer[] | null = null;
}
