/* eslint-disable max-statements */
import { SurveyListQuestion } from '../SurveyListQuestion';
import { BaseFactory } from './BaseFactory';
import { SurveyListOptionFactory } from './SurveyListOptionFactory';
import type { ReadSurveyListQuestionDto } from '~/types/SurveyListQuestion';

export class SurveyListQuestionFactory extends BaseFactory<ReadSurveyListQuestionDto, SurveyListQuestion> {
    public toModel(dto: ReadSurveyListQuestionDto): SurveyListQuestion {
        const model = new SurveyListQuestion();

        model.no = dto.no;
        model.id = Number(dto.id);
        model.question = dto.question;
        model.explanation = dto.explanation;
        model.childQuestion = dto.childQuestion;

        // eslint-disable-next-line no-undefined
        if (dto.childQuestions !== undefined && dto.childQuestions !== null) {
            model.childQuestions = (new SurveyListQuestionFactory()).toModels(dto.childQuestions.data);
        }

        model.parentQuestion = dto.parentQuestionId;
        model.parentQuestionChoice = dto.parentQuestionChoice;
        model.questionType = dto.questionType;
        model.typeText = dto.typeText;
        model.statement = dto.statement;
        model.validation = dto.validation;
        model.required = dto.required;
        model.pdfIntro = dto.pdfIntro;
        model.optionSet = dto.optionSet;
        model.yesnoInapplicable = dto.yesnoInapplicable;

        // eslint-disable-next-line no-undefined
        if (dto.options !== undefined && dto.options !== null) {
            model.options = (new SurveyListOptionFactory()).toModels(dto.options.data);
        }

        return model;
    }
}
