import { BaseFactory } from './BaseFactory';
import { RelationFactory } from './RelationFactory';
import { RoleFactory } from './RoleFactory';
import { User } from '~/models/User';
import type { ReadUserDto } from '~/types/User';

export class UserFactory extends BaseFactory<ReadUserDto, User> {
    // eslint-disable-next-line max-statements
    public toModel(dto: ReadUserDto): User {
        const model = new User();

        model.active = dto.active;
        model.email = dto.email;
        model.emailVerifiedAt = dto.emailVerifiedAt;
        model.fullName = dto.fullName || null;
        model.id = Number(dto.id);

        // eslint-disable-next-line no-undefined
        if (dto.relation !== undefined) {
            model.relation = (new RelationFactory()).toModel(dto.relation.data);
        }

        // eslint-disable-next-line no-undefined
        if (dto.roles !== undefined) {
            model.roles = (new RoleFactory()).toModels(dto.roles.data);
        }

        return model;
    }
}
