export class RelationSurveyList {
    public id: number | null = null;

    public surveyListId: number | null = null;

    public surveyListName: string | null = null;

    public mailSend: string | null = null;

    public startedDate: string | null = null;

    public endedDate: string | null = null;

    public reminderSent: string | null = null;

    public reminderCount: number | null = 0;

    public lastReminderSentDate: string | null = null;
}
