import { BaseFactory } from './BaseFactory';
import { AttachmentFactory } from './AttachmentFactory';
import type { ReadPeriodActivityDto } from '~/types/PeriodActivity';
import { PeriodActivity } from '~/models/PeriodActivity';

export class PeriodActivityFactory extends BaseFactory<ReadPeriodActivityDto, PeriodActivity> {
    public toModel(dto: ReadPeriodActivityDto): PeriodActivity {
        const model = new PeriodActivity();

        model.activityId = dto.activityId ?? Number(dto.activityId);
        model.points = dto.points ?? Number(dto.points);

        model.activityTypeString = dto.activityTypeString || null;
        model.date = dto.date || null;
        model.description = dto.description || null;
        model.other = dto.other || null;

        if (dto.attachments) {
            model.attachments = (new AttachmentFactory()).toModels(dto.attachments.data);
        }

        return model;
    }
}
