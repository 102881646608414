
import { BaseFactory } from './BaseFactory';
import type { ReadInvoiceCodeDto } from '~/types/InvoiceCode';
import { InvoiceCode } from '~/models/InvoiceCode';

export class InvoiceCodeFactory extends BaseFactory<ReadInvoiceCodeDto, InvoiceCode> {
    public toModel(dto: ReadInvoiceCodeDto): InvoiceCode {
        const model = new InvoiceCode();

        model.id = Number(dto.id);
        model.price = Number(dto.price);

        model.code = dto.code;
        model.name = dto.name;

        model.taxRate = dto.taxRate || null;
        model.taxRateId = Number(dto.taxRateId);
        model.ledgerNumber = dto.ledgerNumber || null;

        return model;
    }
}
