export class RelationPoints2021 {
    public mandatory: {
        [key: string]: {
            needed: number | null;
            achieved: number | null;
            toAchieve: number | null;
            extra: number | null;
            passed: boolean | null;
            unsubscribed: boolean | false;
        };
    } | null = null;

    public optional: {
        [key: string]: {
            needed: number | null;
            achieved: number | null;
            toAchieve: number | null;
            extra: number | null;
            passed: boolean | null;
            unsubscribed: boolean | false;
        };
    } | null = null;

    public free: {
        needed: number | null;
        achieved: number | null;
        toAchieve: number | null;
        extra: number | null;
        passed: boolean | null;
    } | null = null;

    public total: {
        mandatory: {
            needed: number | null;
            achieved: number | null;
            passed: boolean | null;
        };
        optional: {
            needed: number | null;
            achieved: number | null;
            passed: boolean | null;
            // chambers: string[] | [] // Niet gebruikt,
        };
        free: {
            needed: number | null;
            achieved: number | null;
            passed: boolean | null;
        };
        shortage: {
            shortage: number | null;
        } | [];
        shortageTotal: number | null;
        extra: number | null;
        needed: number | null;
        achieved: number | null;
        passed: boolean | false;
        shortageBefore: number | null;
        shortageTotalBefore: number | null;
        shortageNextYear: {
            shortage: number | null;
        } | [];
        extraNextYear: number | null;
    } | null = null;

    public year: number | null = null;

    public unsubscribedPoints: {
        mandatory: {
            [key: string]: {
                needed: number | null;
                achieved: number | null;
                toAchieve: number | null;
                extra: number | null;
                passed: boolean | null;
                unsubscribed: boolean | false;
            };
        };
        optional: {
            [key: string]: {
                needed: number | null;
                achieved: number | null;
                toAchieve: number | null;
                extra: number | null;
                passed: boolean | null;
                unsubscribed: boolean | false;
            };
        };
        free: {
            needed: number | null;
            achieved: number | null;
            toAchieve: number | null;
            extra: number | null;
            passed: boolean | null;
        };
        total: {
            mandatory: {
                needed: number | null;
                achieved: number | null;
                passed: boolean | null;
            };
            optional: {
                needed: number | null;
                achieved: number | null;
                passed: boolean | null;
                // chambers: string[] | [] // Niet gebruikt,
            };
            free: {
                needed: number | null;
                achieved: number | null;
                passed: boolean | null;
            };
            shortage: {
                shortage: number | null;
            } | [];
            shortageTotal: number | null;
            extra: number | null;
            needed: number | null;
            achieved: number | null;
            passed: boolean | false;
            shortageBefore: number | null;
            shortageTotalBefore: number | null;
            shortageNextYear: {
                shortage: number | null;
            } | [];
            extraNextYear: number | null;
        };
        year: number;
    } | null = null;

    public requiredElearningPassed: boolean | null = false;
}
