import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type InvoiceResponse,
    type InvoicesOverview,
    type InvoicesOverviewResponse,
    type WriteInvoiceDto, type WriteInvoiceStatusDto,
} from '~/types/Invoice';
import { InvoiceFactory } from '~/models/factories/InvoiceFactory';
import { type Invoice } from '~/models/Invoice';
import { type List } from '~/models/List';
import { type ListResponse } from '~/types/List';
import { ListFactory } from '~/models/factories/ListFactory';
import { type FetchInvoices } from '~/enums/FetchInvoices';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import { type RelationsOverview, type RelationsOverviewResponse, type WriteRelationsBatchDto } from '~/types/Relation';
import { RelationFactory } from '~/models/factories/RelationFactory';

export default class InvoiceService extends BaseApiService {
    public static basePath = 'invoices';

    /**
     * @description Delete a Invoice.
     * @param {number} invoiceId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(invoiceId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(InvoiceService.basePath)}/${invoiceId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new Invoice.
     * @param {WriteInvoiceDto} data Form data to post
     * @returns {Promise<Invoice>} Api response
     */
    async create(data: WriteInvoiceDto): Promise<Invoice> {
        const response = await this.basePost<InvoiceResponse>(
            this.createPath(InvoiceService.basePath),
            data,
        );

        return (new InvoiceFactory()).toModel(response.data);
    }

    /**
     * @description Fetch multiple Invoices depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @param {string} type The type of invoices that have to be fetched
     * @returns {Promise<InvoicesOverview>} Promise with the InvoiceOverview as payload
     */
    async fetchInvoices(queryParameters = null, type: FetchInvoices): Promise<InvoicesOverview> {
        try {
            const response = await this.baseGet<InvoicesOverviewResponse>(
                `${this.createPath(InvoiceService.basePath)}/${type}`,
                queryParameters,
            );
            const data = (new InvoiceFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single Invoice by Invoice id.
     * @param {number} invoiceId Identifier of the Invoice to fetch
     * @returns {Promise<Invoice>} Promise with the Invoice model as payload
     */
    async fetchInvoice(invoiceId: number): Promise<Invoice> {
        try {
            const response = await this.baseGet<InvoiceResponse>(
                `${this.createPath(InvoiceService.basePath)}/${invoiceId}`,
            );

            return (new InvoiceFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of Invoice.
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchInvoiceList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(InvoiceService.basePath)}/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch list of available statuses of Invoice.
     * @param {number} invoiceId Id of the resource for the statuses
     * @returns {Promise<List[]>} Promise with the List as payload
     */
    async fetchStatusListFromInvoice(invoiceId: number): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(InvoiceService.basePath)}/${invoiceId}/statuses`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch count of different invoice Status.
     * @returns {Promise<null>} Promise with the List as payload
     */
    async fetchInvoiceStatusCount(): Promise<null> {
        try {
            return await this.baseGet<null>(
                `${this.createPath(InvoiceService.basePath)}/count`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Send a Invoice.
     * @param {number} invoiceId Id of the resource to send
     * @param {WriteInvoiceDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    send(invoiceId: number, data: WriteInvoiceDto): Promise<null> {
        return this.basePost<null>(
            `${this.createPath(InvoiceService.basePath)}/${invoiceId}/send`,
            data,
        );
    }

    /**
     * @description Update a Invoice.
     * @param {number} invoiceId Id of the resource to save
     * @param {WriteInvoiceDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(invoiceId: number, data: WriteInvoiceDto): Promise<null> {
        return this.basePut<null>(
            `${this.createPath(InvoiceService.basePath)}/${invoiceId}`,
            data,
        );
    }

    /**
     * @description Update a Invoice status.
     * @param {number} invoiceId Id of the resource to save
     * @param {WriteInvoiceStatusDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    updateStatus(invoiceId: number, data: WriteInvoiceStatusDto): Promise<null> {
        return this.basePatch<null>(
            `${this.createPath(InvoiceService.basePath)}/${invoiceId}/update-invoice-status`,
            data,
        );
    }

    /**
     * @description Fetch multiple relations for annual invoices overview.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<RelationsOverview>} Promise with the RelationsOverview as payload
     */
    async fetchOverviewAnnualInvoices(queryParameters = null): Promise<RelationsOverview> {
        try {
            const response = await this.baseGet<RelationsOverviewResponse>(
                `${this.createPath(InvoiceService.basePath)}/annual-invoices`,
                queryParameters,
            );

            const data = (new RelationFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Generate annual invoices.
     * @param {WriteRelationsBatchDto} data Form data to post
     * @returns {Promise<string>} Api response
     */
    generateAnnualInvoices(data: WriteRelationsBatchDto): Promise<string> {
        try {
            return this.basePost<string>(
                `${this.createPath(InvoiceService.basePath)}/generate-annual-invoices`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Generate annual invoices.
     * @param {Array} invoiceIds Invoice ID's
     * @returns {Promise<null>} Api response
     */
    async sendUnpaidInvoicesReminders(invoiceIds: []): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(InvoiceService.basePath)}/send-reminders`,
                {
                    invoiceIds,
                },
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Generate annual invoices.
     * @param {Array} invoiceIds Invoice ID's
     * @returns {Promise<null>} Api response
     */
    async generateOpenInvoices(invoiceIds: []): Promise<null> {
        try {
            return await this.basePost<null>(
                `${this.createPath(InvoiceService.basePath)}/generate-open-invoices`,
                {
                    invoiceIds,
                },
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Download invoice.
     * @param {number} invoiceId Invoice ID
     * @returns {Promise<void>} Api response
     */
    async downloadInvoice(invoiceId: number): Promise<void> {
        try {
            await this.baseDownloadBlob(
                `${this.createPath(InvoiceService.basePath)}/${invoiceId}/pdf`,
                {
                    headers: {
                        'Accept': 'application/pdf',
                        'Content-Type': 'application/json',
                    },
                },
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
