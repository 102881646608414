import type { Company } from './Company';
import type { Relation } from './Relation';
import type { SurveyList } from './SurveyList';
import type { SurveyListAnswer } from './SurveyListAnswer';
import type { ExtranetSurveyListQuestion } from '~/models/ExtranetSurveyListQuestion';

export class QuestionStep {
    public id: number | null = null;

    public answers: SurveyListAnswer[] = [];

    public companies: Company[] = [];

    public endedDate: string | null = null;

    public startedDate: string | null = null;

    public questionAndAnswer: ExtranetSurveyListQuestion | null = null;

    public relation: Relation | null = null;

    public step: number | null = null;

    public surveylist: SurveyList | null = null;

    public uuid: string | null = null;

    public progress: number | null = null;

    public surveyProgress: number | null = null;
}
