import { type FetchError } from 'ofetch';

// eslint-disable-next-line max-len
export const apiErrorHandler = (fetchError: FetchError): FetchError | Error => ([422, 500].includes(fetchError?.statusCode || 0) ? fetchError : new Error(fetchError?.data?.message || 'Het lijkt erop dat er iets misgaat!'));

// eslint-disable-next-line max-statements, complexity, max-lines-per-function
export const handleErrors = (error: FetchError, messageContainer: string[]): void => {
    if ([406, 422, 424].includes(Number(error?.statusCode))) {
        const errors = error?.data || {};
        for (const errorField of Object.keys(errors)) {
            // Check if errorField value is an array or object. A typeof object can also be null, function or array.
            if (
                typeof errors[errorField] === 'object' &&
                !Array.isArray(errors[errorField]) &&
                errors[errorField] !== null
            ) {
                // The returned 'array' with deep field validation could be an object with a number as key.
                // This is because PHP json_encode returns an array with a number as key when the array is not assoc.
                for (const [index, errorMessage] of Object.entries(errors[errorField])) {
                    // eslint-disable-next-line max-depth
                    if (typeof errorMessage === 'string') {
                        messageContainer.push(`${errorField}: ${errorMessage}`);
                    } else {
                        const errorMessages = errorMessage as Record<string, string[]>;
                        // eslint-disable-next-line max-depth
                        for (const deepErrorField of Object.keys(errorMessages)) {
                            // eslint-disable-next-line max-depth
                            if (Array.isArray(errorMessages[deepErrorField])) {
                                // eslint-disable-next-line max-depth
                                for (const deepErrorMessage of errorMessages[deepErrorField]) {
                                    messageContainer.push(
                                        `${errorField} ${Number.parseInt(index, 10) + 1}:
                                            ${deepErrorField}: ${deepErrorMessage}`,
                                    );
                                }
                            } else if (typeof errorMessages[deepErrorField] === 'string') {
                                messageContainer.push(`${errorMessages[deepErrorField]}`);
                            } else {
                                messageContainer.push(errorField);
                            }
                        }
                    }
                }
            } else if (Array.isArray(errors[errorField])) {
                // The returned 'array' with deep field validation could also be an array (indexed 0 based).
                // This is because PHP json_encode returns an array with a number as key when the array is not assoc.
                for (const [index, errorMessage] of errors[errorField].entries()) {
                    if (typeof errorMessage === 'string') {
                        messageContainer.push(`${errorField}: ${errorMessage}`);
                    } else {
                        // eslint-disable-next-line max-depth
                        for (const deepErrorField of Object.keys(errorMessage)) {
                            // eslint-disable-next-line max-depth
                            if (Array.isArray(errorMessage[deepErrorField])) {
                                // eslint-disable-next-line max-depth
                                for (const deepErrorMessage of errorMessage[deepErrorField]) {
                                    messageContainer.push(
                                        `${errorField} ${index + 1}: ${deepErrorField}: ${deepErrorMessage}`,
                                    );
                                }
                            } else if (typeof errorMessage[deepErrorField] === 'string') {
                                messageContainer.push(`${errorMessage[deepErrorField]}`);
                            } else {
                                messageContainer.push(errorField);
                            }
                        }
                    }
                }
            } else if (typeof errors[errorField] === 'string') {
                messageContainer.push(`${errors[errorField]}`);
            } else {
                messageContainer.push(errorField);
            }
        }
    } else {
        messageContainer.push('Het lijkt erop dat er iets misgaat!');
    }
};
