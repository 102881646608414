/* eslint-disable complexity, max-statements */
import { BaseFactory } from './BaseFactory';
import { RelationPoints2024 } from '~/models/RelationPoints2024';
import { type ReadRelationPoints2024Dto } from '~/types/RelationPoints2024';

export class RelationPoints2024Factory extends BaseFactory<ReadRelationPoints2024Dto, RelationPoints2024> {
    public toModel(dto: ReadRelationPoints2024Dto): RelationPoints2024 {
        const model = new RelationPoints2024();

        model.required = dto.points?.required || null;

        model.facultative = dto.points?.facultative || null;

        model.total = dto.points?.total || null;

        model.year = dto.points?.year || null;

        model.rows = dto.points?.rows || null;

        model.totalPoints = dto.points?.totalPoints || null;

        model.totalPointsNeeded = dto.points?.totalPointsNeeded || null;

        model.extraPointsNextYear = dto.points?.extraPointsNextYear || null;

        model.shortageNextYear = dto.points?.shortageNextYear || null;

        model.extraNextYear = dto.points?.extraNextYear || null;

        model.unsubscribedPoints = dto.points?.unsubscribedPoints || null;

        model.requiredElearningPassed = dto.requiredElearningPassed || false;

        return model;
    }
}
