import type { User } from './User';

export class Remark {
    public user: User | null = null;

    public userEmail: string | null = null;

    public id: number | null = null;

    public important: boolean | null = false;

    public text: string | null = null;

    public createdAt: string | null = null;
}
