export class RelationPoints2022 {
    public required: {
        [key: string]: {
            needed: number | null;
            achieved: number | null;
            passed: number | null;
            extra: number | null;
            chamberName: string | null;
            chamberCode: string | null;
            unsubscribed: boolean | false;
        };
    } | null = null;

    public facultative: {
        [key: string]: {
            needed: number | null;
            achieved: number | null;
            passed: number | null;
            extra: number | null;
            chamberName: string | null;
            chamberCode: string | null;
            unsubscribed: boolean | false;
        };
    } | null = null;

    public total: {
        required: {
            needed: number | null;
            achieved: number | null;
            passed: number | null;
        };
        facultative: {
            needed: number | null;
            achieved: number | null;
            passed: number | null;
        };
        free: {
            needed: number | null;
            achieved: number | null;
            passed: number | null;
        };
        passed: boolean | false;
        shortage: {
            shortage: number | null;
        } | [];
        shortageTotal: number | null;
        extra: number | null;
        shortageNextYear: {
            shortage: number | null;
        } | [];
        extraNextYear: number | null;
    } | null = null;

    public year: number | null = null;

    public totalPoints: number | null = null;

    public totalPointsNeeded: number | null = null;

    public extraPointsNextYear: number | null = null;

    public shortageNextYear: {
        shortage: number | null;
    } | [] = [];

    public extraNextYear: number | null = null;

    public unsubscribedPoints: {
        required: {
            [key: string]: {
                needed: number | null;
                achieved: number | null;
                passed: number | null;
                extra: number | null;
                chamberName: string | null;
                chamberCode: string | null;
                unsubscribed: boolean | false;
            };
        };
        facultative: {
            [key: string]: {
                needed: number | null;
                achieved: number | null;
                passed: number | null;
                extra: number | null;
                chamberName: string | null;
                chamberCode: string | null;
                unsubscribed: boolean | false;
            };
        };
        total: {
            required: {
                needed: number | null;
                achieved: number | null;
                passed: number | null;
            };
            facultative: {
                needed: number | null;
                achieved: number | null;
                passed: number | null;
            };
            free: {
                needed: number | null;
                achieved: number | null;
                passed: number | null;
            };
            passed: boolean | false;
            shortage: {
                shortage: number | null;
            } | [];
            shortageTotal: number | null;
            extra: number | null;
            shortageNextYear: {
                shortage: number | null;
            } | [];
            extraNextYear: number | null;
        };
        year: number;
        totalPoints: number;
        totalPointsNeeded: number;
        extraPointsNextYear: number;
        shortageNextYear: {
            shortage: number | null;
        };
        extraNextYear: number;
    } | null = null;

    public requiredElearningPassed: boolean | null = false;
}
