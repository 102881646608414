import { Department } from '../Department';
import { BaseFactory } from './BaseFactory';
import type { ReadDepartmentDto } from '~/types/Department';

export class DepartmentFactory extends BaseFactory<ReadDepartmentDto, Department> {
    public toModel(dto: ReadDepartmentDto): Department {
        const model = new Department();

        model.id = Number(dto.id);
        model.name = dto.name;

        return model;
    }
}
