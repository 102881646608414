import { BaseFactory } from './BaseFactory';
import { AttachmentFactory } from './AttachmentFactory';
import type { ReadRelationActivityDto } from '~/types/RelationActivity';
import { RelationActivity } from '~/models/RelationActivity';

export class RelationActivityFactory extends BaseFactory<ReadRelationActivityDto, RelationActivity> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadRelationActivityDto): RelationActivity {
        const model = new RelationActivity();

        model.id = dto.id ?? Number(dto.id);
        model.activityTypeId = dto.activityTypId ?? Number(dto.activityTypId);
        model.points = dto.points ?? Number(dto.points);

        model.activityTypeString = dto.activityTypeString || null;
        model.date = dto.date || null;
        model.description = dto.description || null;
        model.other = dto.other || null;

        if (dto.attachments) {
            model.attachments = (new AttachmentFactory()).toModels(dto.attachments.data);
        }

        return model;
    }
}
