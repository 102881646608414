import { ExtranetSurveyListQuestion } from '../ExtranetSurveyListQuestion';
import { BaseFactory } from './BaseFactory';
import { SurveyListOptionFactory } from './SurveyListOptionFactory';
import { ExtranetRelationSurveyListAnswersFactory } from './ExtranetRelationSurveyListAnswersFactory';
import type { ExtranetSurveyListQuestionDto } from '~/types/ExtranetSurveyListQuestion';

export class ExtranetSurveyListQuestionFactory
    extends BaseFactory<ExtranetSurveyListQuestionDto, ExtranetSurveyListQuestion> {
    // eslint-disable-next-line max-statements
    public toModel(dto: ExtranetSurveyListQuestionDto): ExtranetSurveyListQuestion {
        const model = new ExtranetSurveyListQuestion();

        model.no = dto.no;
        model.id = dto.id;
        model.question = dto.question;
        model.explanation = dto.explanation;
        model.childQuestion = dto.childQuestion;

        // eslint-disable-next-line no-undefined
        if (dto.childQuestions !== undefined && dto.childQuestions !== null) {
            model.childQuestions = (new ExtranetSurveyListQuestionFactory()).toModels(dto.childQuestions.data);
        }

        model.parentQuestion = dto.parentQuestionId;
        model.parentQuestionChoice = dto.parentQuestionChoice;
        model.questionType = dto.questionType;
        model.typeText = dto.typeText;
        model.statement = dto.statement;
        model.required = dto.required;
        model.pdfIntro = dto.pdfIntro;
        model.optionSet = dto.optionSet;
        model.validation = dto.validation;
        model.yesnoInapplicable = dto.yesnoInapplicable;

        // eslint-disable-next-line no-undefined
        if (dto.options !== undefined && dto.options !== null) {
            model.options = (new SurveyListOptionFactory()).toModels(dto.options.data);
        }

        model.answer = (new ExtranetRelationSurveyListAnswersFactory()).toModel(dto.answer.data, dto.questionType);

        return model;
    }
}
