import { GET_BREADCRUMBS, GET_ROUTE_NAMESPACE } from './-getter-types';
import findBreadcrumbName from '~/config/breadcrumbs';
import type { Breadcrumb } from '~/models/Breadcrumb';
import { useApplicationStore } from '~/stores/application';

const Getters = {
    [GET_BREADCRUMBS]: (): Breadcrumb[] => {
        const applicationStore = useApplicationStore();

        return applicationStore.breadcrumbs.map((item: Breadcrumb) => ({
            ...item,
            name: findBreadcrumbName(item.name),
        }));
    },
    [GET_ROUTE_NAMESPACE]: (): string => {
        const applicationStore = useApplicationStore();

        return applicationStore.routeNamespace;
    },
};

export default Getters;
