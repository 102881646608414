import type { Attachment } from './Attachment';
import type { SurveyListOption } from '~/models/SurveyListOption';

export class ExtranetRelationSurveyListQuestionAndAnswer {
    public no: number | null = null;

    public id: number | null = null;

    public explanation: string | null = null;

    public question: string | null = null;

    public typeText: string | null = null;

    public isChild: boolean | null = false;

    public questionType: string | null = null;

    public parentQuestionChoice: string | null = null;

    public answer: {
        answer: boolean | number | string | string[] | null;
        id: number;
        else: boolean;
    } | null = null;

    public options: SurveyListOption[] | null = null;

    public attachments: Attachment[] | null = null;

    // @TODO[NRVT-2213]: This is a circular reference
    // eslint-disable-next-line no-use-before-define
    public childQuestions: ExtranetRelationSurveyListQuestionAndAnswer[] | null = null;

    public required: boolean | null = null;

    public yesnoInapplicable: boolean | null = null;
}
