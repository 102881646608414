import { BaseFactory } from './BaseFactory';
import { RelationFactory } from './RelationFactory';
import { ReminderFactory } from './ReminderFactory';
import { SurveylistParticipant } from '~/models/SurveylistParticipant';
import type { ReadSurveylistParticipantDto } from '~/types/SurveylistParticipant';

export class SurveylistParticipantFactory extends BaseFactory<ReadSurveylistParticipantDto, SurveylistParticipant> {
    public toModel(dto: ReadSurveylistParticipantDto): SurveylistParticipant {
        const model = new SurveylistParticipant();

        model.id = dto.id || null;

        model.mailSend = dto.mailSend || null;

        model.startedDate = dto.startedDate || null;

        model.endedDate = dto.endedDate || null;

        // eslint-disable-next-line no-undefined
        if (dto.relation !== undefined && dto.relation !== null) {
            model.relation = (new RelationFactory()).toModel(dto.relation.data);
        }

        // eslint-disable-next-line no-undefined
        if (dto.reminder !== undefined && dto.reminder !== null) {
            model.reminder = (new ReminderFactory()).toModel(dto.reminder.data);
        }

        return model;
    }
}
