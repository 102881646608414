import { BaseFactory } from './BaseFactory';
import type { ReadActivityTypeDto } from '~/types/ActivityType';
import { ActivityType } from '~/models/ActivityType';

export class ActivityTypeFactory extends BaseFactory<ReadActivityTypeDto, ActivityType> {
    public toModel(dto: ReadActivityTypeDto): ActivityType {
        const model = new ActivityType();

        model.id = dto.id;

        model.name = dto.name;

        model.points = Number(dto.points);

        model.mandatory = Boolean(dto.mandatory);

        return model;
    }
}
