import { BaseFactory } from './BaseFactory';
import type { ReadExtranetCourseDto } from '~/types/ExtranetCourse';
import { ExtranetCourse } from '~/models/ExtranetCourse';

export class ExtranetCourseFactory extends BaseFactory<ReadExtranetCourseDto, ExtranetCourse> {
    /* eslint-disable-next-line max-statements, complexity */
    public toModel(dto: ReadExtranetCourseDto): ExtranetCourse {
        const model = new ExtranetCourse();

        model.courseCode = dto.courseCode || null;

        model.courseId = dto.courseId || null;

        model.courseName = dto.courseName || null;

        model.chamberCodes = dto.chamberCodes || null;

        model.points = dto.points || null;

        model.courseDate = dto.courseDate || null;

        model.applicableYear = dto.applicableYear || null;

        model.trainerName = dto.trainerName || [];

        model.isActivity = dto.isActivity || null;

        model.attachments = dto.attachments || null;

        return model;
    }
}
