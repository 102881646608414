import { BaseFactory } from './BaseFactory';
import { SurveyListFactory } from './SurveyListFactory';
import type { ReadExtranetRelationSurveyListDetailsDto } from '~/types/ExtranetRelationSurveyListDetailsDto';
import { ExtranetRelationSurveyListDetailsModel } from '~/models/ExtranetRelationSurveyListDetails';
// eslint-disable-next-line max-len
import { ExtranetRelationSurveyListQuestionAndAnswerFactory } from '~/models/factories/ExtranetRelationSurveyListQuestionAndAnswerFactory';

// eslint-disable-next-line max-len
export class ExtranetRelationSurveyListDetailsFactory extends BaseFactory<ReadExtranetRelationSurveyListDetailsDto, ExtranetRelationSurveyListDetailsModel> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadExtranetRelationSurveyListDetailsDto): ExtranetRelationSurveyListDetailsModel {
        const model = new ExtranetRelationSurveyListDetailsModel();

        model.id = Number(dto.id);

        model.uuid = dto.uuid || null;
        model.startedDate = dto.startedDate || null;
        model.endedDate = dto.endedDate || null;
        model.step = dto.step || null;
        model.surveyProgress = dto.surveyProgress || null;

        if (dto.surveylist) {
            model.surveylist = (new SurveyListFactory()).toModel(dto.surveylist.data);
        }

        if (dto.allQuestionsAndAnswers) {
            // eslint-disable-next-line max-len
            model.allQuestionsAndAnswers = (new ExtranetRelationSurveyListQuestionAndAnswerFactory()).toModels(dto.allQuestionsAndAnswers.data);
        }

        return model;
    }
}
