import { type FetchError } from 'ofetch';
import { CompanyMovementFactory } from '~/models/factories/CompanyMovementFactory';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type CompanyMovementsOverview,
    type CompanyMovementsOverviewResponse,
    type WriteCompanyMovementDto,
} from '~/types/CompanyMovement';
import { RelationMovementFactory } from '~/models/factories/RelationMovementFactory';
import { type RelationMovement } from '~/models/RelationMovement';
import { type RelationMovementResponse } from '~/types/RelationMovement';
import { type WriteRelationPersonalMovementDto } from '~/types/RelationPersonalMovement';

export default class PersonalMovementService extends BaseApiService {
    private basePath = 'movements';

    /**
     * @description Update the relation's personal movement based on the token of Extranet user.
     * @param {WriteRelationPersonalMovementDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    updatePersonalMovement(data: WriteRelationPersonalMovementDto): Promise<null> {
        try {
            return this.basePost<null>(
                `${this.createPath(this.basePath)}/personal`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Create a new CompanyMovement.
     * @param {WriteCompanyMovementDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    create(data: WriteCompanyMovementDto): Promise<null> {
        try {
            return this.basePost<null>(
                `${this.createPath(this.basePath)}/companies`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Fetch movements of multiple companies.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CompanyMovementsOverview>} Promise with the ExtranetCompaniesOverview as payload
     */
    async fetchMovementsCompanies(queryParameters = null): Promise<CompanyMovementsOverview> {
        try {
            const response = await this.baseGet<CompanyMovementsOverviewResponse>(
                `${this.createPath(this.basePath)}/companies`,
                queryParameters,
            );
            const data = (new CompanyMovementFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }

    /**
     * @description Get the personal movements of a relation in extranet.
     * @returns {Promise<RelationMovement>} Promise with RelationMovement as payload
     */
    async fetchPersonalMovement(): Promise<RelationMovement> {
        try {
            const response = await this.baseGet<RelationMovementResponse>(
                `${this.createPath(this.basePath)}/personal`,
            );

            return (new RelationMovementFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw new Error(fetchError.message);
        }
    }
}
