import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    MailMessage,
    MailMessagePreview,
    MailMessageResponse,
    MailMessagesOverview,
} from '~/types/MailMessage';
import { type MailMessageRecipients } from '~/types/MailMessageRecipients';

export default class MailMessagesService extends BaseApiService {
    private basePath = 'mail-messages';

    async fetchAll(queryParameters: (Record<string, unknown> | null) = null): Promise<MailMessagesOverview> {
        try {
            return await this.baseGet<MailMessagesOverview>(
                this.createPath(this.basePath),
                queryParameters,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetch(id: number): Promise<MailMessage> {
        try {
            const response = await this.baseGet<MailMessageResponse>(
                `${this.createPath(this.basePath)}/${id}`,
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchPreview(id: number): Promise<MailMessagePreview> {
        try {
            return await this.baseGet<MailMessagePreview>(
                `${this.createPath(this.basePath)}/${id}/preview`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchRecipients(
        id: number,
        queryParameters: (Record<string, unknown> | null) = null,
    ): Promise<MailMessageRecipients> {
        try {
            return await this.baseGet<MailMessageRecipients>(
                `${this.createPath(this.basePath)}/${id}/recipients`,
                queryParameters,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    update(id: number, mailMessage: Pick<MailMessage, 'body' | 'name' | 'subject'>): Promise<MailMessage> {
        return this.basePut<MailMessage>(
            `${this.createPath(this.basePath)}/${id}`,
            mailMessage,
        );
    }
}
