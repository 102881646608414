import { BaseFactory } from './BaseFactory';
import type { ReadInvoiceDto } from '~/types/Invoice';
import { Invoice } from '~/models/Invoice';
import { RelationFactory } from '~/models/factories/RelationFactory';
import { InvoiceLineFactory } from '~/models/factories/InvoiceLineFactory';
import { InvoiceLogFactory } from '~/models/factories/InvoiceLogFactory';

export class InvoiceFactory extends BaseFactory<ReadInvoiceDto, Invoice> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadInvoiceDto): Invoice {
        const model = new Invoice();

        model.address = dto.address;
        model.addressType = dto.addressType;
        model.id = dto.id;
        model.invoiceNumber = dto.invoiceNumber;
        model.accountNumber = dto.accountNumber;
        model.total = dto.total;
        model.totalAmount = dto.totalAmount;
        model.invoiceTypeName = dto.invoiceTypeName;
        model.invoiceStatusName = dto?.invoiceStatusName || dto?.invoiceStatus?.data?.name || '';
        model.paidOn = dto.paidOn;
        model.createdAt = dto.createdAt;
        model.deletedAt = dto.deletedAt;
        model.queuedAt = dto.queuedAt;
        model.dateSent = dto.dateSent;
        model.date = dto.date;
        model.daysPastDue = dto.daysPastDue;
        model.reminderSent = dto.reminderSent;
        model.secondReminderSent = dto.secondReminderSent;
        model.statusText = dto.statusText;
        model.description = dto.description;
        model.accountingPeriod = dto.accountingPeriod;
        model.visibleOnExtranet = dto.visibleOnExtranet;
        model.sendDate = dto.sendDate;
        model.tax = dto.tax;
        model.subtotal = dto.subtotal;
        model.relationId = dto?.relationId;
        model.invoiceStatusId = Number(dto.invoiceStatusId);
        model.openAmountInclVat = dto.openAmountInclVat;
        model.isPdfPresent = dto.isPdfPresent;

        // eslint-disable-next-line no-undefined
        if (dto.relation !== undefined) {
            model.relation = (new RelationFactory()).toModel(dto.relation.data);
        }

        // eslint-disable-next-line no-undefined
        if (dto.invoiceLines !== undefined) {
            model.invoiceLines = (new InvoiceLineFactory()).toModels(dto.invoiceLines.data);
        }

        // eslint-disable-next-line no-undefined
        if (dto.invoiceLogs !== undefined) {
            model.invoiceLogs = (new InvoiceLogFactory()).toModels(dto.invoiceLogs.data);
        }

        return model;
    }
}
