export class RelationDepartmentPoints {
    public mandatory: {
        [key: string]: {
            departmentName: string | null;
            needed: number | null;
            achieved: number | null;
            toAchieve: number | null;
        };
    } | null = null;

    public total: {
        needed: number | null;
        achieved: number | null;
        toAchieve: number | null;
    } | null = null;

    public year: number | null = null;
}
