import { BaseFactory } from './BaseFactory';
import type { ReadMailDto } from '~/types/Mail';
import { Mail } from '~/models/Mail';

export class MailFactory extends BaseFactory<ReadMailDto, Mail> {
    public toModel(dto: ReadMailDto): Mail {
        const model = new Mail();

        model.dateTime = dto.dateTime || null;
        model.type = dto.type || null;
        model.email = dto.email || null;

        return model;
    }
}
