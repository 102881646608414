/* eslint-disable max-lines */
import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import {
    type SurveyListResponse,
    type SurveyListsOverviewResponse,
} from '~/types/SurveyListResponse';
import { SurveyListFactory } from '~/models/factories/SurveyListFactory';
import {
    type SurveyListQuestionsResponse,
    type SurveyListsOverview,
    type WriteInviteParticipantsDto,
    type WriteSurveyListDto,
} from '~/types/SurveyList';
import { type SurveyList } from '~/models/SurveyList';
import { SurveyListOptionFactory } from '~/models/factories/SurveyListOptionFactory';
import {
    type SurveyListOptionsOverview,
    type WriteSurveyListOptionDto,
} from '~/types/SurveyListOption';
import {
    type SurveyListOptionResponse,
    type SurveyListOptionsListResponse,
    type SurveyListOptionsOverviewResponse,
} from '~/types/SurveyListOptionResponse';
import {
    type SurveyListOptionSetOverview,
    type SurveyListOptionSetsResponse,
    type WriteSurveyListOptionSetDto,
} from '~/types/SurveyListOptionSet';
import { type SurveyListOptionSetOverviewResponse } from '~/types/SurveyListOptionSetResponse';
import { SurveyListOptionSetFactory } from '~/models/factories/SurveyListOptionSetFactory';
import { type SurveyListOption } from '~/models/SurveyListOption';
import { type SurveyListOptionSet } from '~/models/SurveyListOptionSet';
import { type SurveyListQuestion } from '~/models/SurveyListQuestion';
import { SurveyListQuestionFactory } from '~/models/factories/SurveyListQuestionFactory';
import {
    type SurveyListQuestionResponse,
    type WriteParticipantsAddDto,
    type WriteParticipantsSelectDto,
    type WriteSurveyListQuestionDto,
} from '~/types/SurveyListQuestion';
import { ListFactory } from '~/models/factories/ListFactory';
import { type List } from '~/models/List';
import { type ListResponse } from '~/types/List';
import {
    type SurveylistParticipantsOverview,
    type SurveylistParticipantsOverviewResponse,
} from '~/types/SurveylistParticipant';
import { SurveylistParticipantFactory } from '~/models/factories/SurveylistParticipantFactory';
import { type SurveyListAnswersResponse } from '~/types/SurveyListAnswers';
import { type SurveyListAnswers } from '~/models/SurveyListAnswers';
import { SurveyListAnswersFactory } from '~/models/factories/SurveyListAnswersFactory';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type { RelationSurveyExportResponse } from '~/types/RelationSurveyList';

export default class SurveyListsService extends BaseApiService {
    public static basePath = 'surveylists';

    /**
     * @description Fetch multiple surveylists depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<SurveyListsOverview[]>} Promise with the SurveyListsOverview as payload
     */
    async fetchSurveyLists(queryParameters = null): Promise<SurveyListsOverview> {
        try {
            const response = await this.baseGet<SurveyListsOverviewResponse>(
                `${this.createPath(SurveyListsService.basePath)}`,
                queryParameters,
            );

            const data = (new SurveyListFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single surveylist by id.
     * @param {number} surveyListId Identifier of the surveylist to fetch
     * @returns {Promise<SurveyList>} Promise with the SurveyList model as payload
     */
    async fetchSurveyList(surveyListId: number): Promise<SurveyList> {
        try {
            const response = await this.baseGet<SurveyListResponse>(
                `${this.createPath(SurveyListsService.basePath)}/${surveyListId}`,
            );

            return (new SurveyListFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetches all survey list options.
     * @param {Array} queryParameters Array of GET parameters
     * @returns {Promise<SurveyListOptionsOverview[]>}
     * Promise with the overview of all Promise with the SurveyListsOverview as payload
     */
    async fetchSurveyListOptions(queryParameters = null): Promise<SurveyListOptionsOverview> {
        try {
            const response = await this.baseGet<SurveyListOptionsOverviewResponse>(
                `${this.createPath(SurveyListsService.basePath)}/options`,
                queryParameters,
            );

            const data = (new SurveyListOptionFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single surveylist by id.
     * @param {number} surveylistOptionId Identifier of the surveylist to fetch
     * @returns {Promise<SurveyList>} Promise with the SurveyList model as payload
     */
    async fetchSurveyListOption(surveylistOptionId: number): Promise<SurveyListOption> {
        try {
            const response = await this.baseGet<SurveyListOptionResponse>(
                `${this.createPath(SurveyListsService.basePath)}/options/${surveylistOptionId}`,
            );

            return (new SurveyListOptionFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new option for surveylist.
     * @param {WriteSurveyListOptionDto} data Form data to post
     * @returns {Promise<SurveyListOption>} Api response
     */
    async createOption(data: WriteSurveyListOptionDto): Promise<SurveyListOption> {
        try {
            const response = await this.basePost<SurveyListOptionResponse>(
                `${this.createPath(SurveyListsService.basePath)}/options`,
                data,
            );

            return (new SurveyListOptionFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a surveylist option.
     * @param {number} surveylistOptionId ID of the resource to update
     * @param {WriteSurveyListOptionDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    async updateOption(surveylistOptionId: number, data: WriteSurveyListOptionDto): Promise<SurveyListOption> {
        try {
            const response = await this.basePut<SurveyListOptionResponse>(
                `${this.createPath(SurveyListsService.basePath)}/options/${surveylistOptionId}`,
                data,
            );

            return (new SurveyListOptionFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch a list of options.
     * @returns {Promise<List[]>} Api response
     */
    async fetchOptionsList(): Promise<List[]> {
        try {
            const response = await this.baseGet<SurveyListOptionsListResponse>(
                `${this.createPath(SurveyListsService.basePath)}/options/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetches all survey list option sets.
     * @param {Array} queryParameters Array of GET parameters
     * @returns {Promise<SurveyListOptionsOverview[]>}
     * Promise with the overview of all Promise with the SurveyListsOverview as payload
     */
    async fetchSurveyListOptionSets(queryParameters = null): Promise<SurveyListOptionSetOverview> {
        try {
            const response = await this.baseGet<SurveyListOptionSetOverviewResponse>(
                `${this.createPath(SurveyListsService.basePath)}/optionsets`,
                queryParameters,
            );

            const data = (new SurveyListOptionSetFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch surveylist optionset by id.
     * @param {number} surveylistOptionSetId Identifier of the surveylist to fetch
     * @returns {Promise<SurveyListOptionSet>} Promise which will return an OptionSet
     */
    async fetchSurveyListOptionSet(surveylistOptionSetId: number): Promise<SurveyListOptionSet> {
        try {
            const response = await this.baseGet<SurveyListOptionSetsResponse>(
                `${this.createPath(SurveyListsService.basePath)}/optionsets/${surveylistOptionSetId}`,
            );

            return (new SurveyListOptionSetFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch optionsets list.
     * @returns {Promise<List[]>} Promise which will return a list of all optionsets
     */
    async fetchSurveyListOptionSetsList(): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(SurveyListsService.basePath)}/optionsets/list`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new optionset for surveylist.
     * @param {WriteSurveyListOptionSetDto} data Form data to post
     * @returns {Promise<SurveyListOptionSet>} Api response
     */
    async createOptionSet(
        data: WriteSurveyListOptionSetDto,
    ): Promise<SurveyListOptionSet> {
        try {
            const response = await this.basePost<
                SurveyListOptionSetsResponse
            >(
                `${this.createPath(SurveyListsService.basePath)}/optionsets`,
                data,
            );

            return new SurveyListOptionSetFactory().toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update an optionset.
     * @param {number} surveylistOptionSetId ID of the resource to update
     * @param {WriteSurveyListOptionSetDto} data Form data to post
     * @returns {Promise<SurveyListOptionSet>} Api response
     */
    async updateOptionSet(
        surveylistOptionSetId: number,
        data: WriteSurveyListOptionSetDto,
    ): Promise<SurveyListOptionSet> {
        try {
            const response = await this.basePut<
                SurveyListOptionSetsResponse
            >(
                `${this.createPath(
                    SurveyListsService.basePath,
                )}/optionsets/${surveylistOptionSetId}`,
                data,
            );

            return new SurveyListOptionSetFactory().toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new survey list.
     * @param {WriteSurveyListDto} data Form data to post
     * @returns {Promise<SurveyList>} Api response
     */
    async create(data: WriteSurveyListDto): Promise<SurveyList> {
        try {
            const response = await this.basePost<SurveyListResponse>(
                this.createPath(SurveyListsService.basePath),
                data,
            );

            return new SurveyListFactory().toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a surveylist.
     * @param {number} surveyListId ID of the resource to update
     * @param {WriteSurveyListDto} data Form data to post
     * @returns {Promise<SurveyList>} Api response
     */
    async update(surveyListId: number, data: WriteSurveyListDto): Promise<SurveyList> {
        try {
            const response = await this.basePut<SurveyListResponse>(
                `${this.createPath(SurveyListsService.basePath)}/${surveyListId}`,
                data,
            );

            return (new SurveyListFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single questions by surveylistId.
     * @param {number} surveylistId Identifier of the surveylist to fetch
     * @returns {Promise<SurveyListQuestion[]>} Returns a promise which returns an array of SurveyListQuestion
     */
    async fetchQuestionsSurveyList(surveylistId: number): Promise<SurveyListQuestion[]> {
        try {
            const response = await this.baseGet<SurveyListQuestionsResponse>(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/questions`,
            );

            return (new SurveyListQuestionFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch parents questions for a specific surveylist.
     * @param {number} surveylistId Identifier of the surveylist to fetch
     * @returns {Promise<List[]>} Promise with the List models as payload
     */
    async fetchParentQuestionsSurveyList(surveylistId: number): Promise<List[]> {
        try {
            const response = await this.baseGet<ListResponse>(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/parent-questions`,
            );

            return (new ListFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new question for a survey list.
     * @param {number} surveylistId The resource id for creating a question
     * @param {WriteSurveyListQuestionDto} data Form data to post a question for survey list
     * @returns {Promise<SurveyListQuestion>} Api response
     */
    async createQuestion(surveylistId: number, data: WriteSurveyListQuestionDto): Promise<SurveyListQuestion> {
        try {
            const response = await this.basePost<SurveyListQuestionResponse>(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/questions`,
                data,
            );

            return (new SurveyListQuestionFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a question of a surveylist.
     * @param {number} surveylistId ID of the surveylist which is the question part of
     * @param {number} surveylistQuestionId ID of the question to update
     * @param {WriteSurveyListQuestionDto} data Form data to post
     * @returns {Promise<SurveyListQuestion>} Api response
     */
    // eslint-disable-next-line max-len
    async updateQuestion(surveylistId: number, surveylistQuestionId: number, data: WriteSurveyListQuestionDto): Promise<SurveyListQuestion> {
        try {
            const response = await this.basePut<SurveyListQuestionResponse>(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/questions/${surveylistQuestionId}`,
                data,
            );

            return (new SurveyListQuestionFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete a question of a surveylist.
     * @param {number} surveylistId The ID of the surveylist which the question is part of
     * @param {number} surveylistQuestionId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    deleteQuestion(surveylistId: number, surveylistQuestionId: number): Promise<null> {
        try {
            return this.baseDelete(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/questions/${surveylistQuestionId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single questions by surveylistId.
     * @param {number} surveylistId Identifier of the surveylist to fetch
     * @param {number} surveylistQuestionId Identifier of the question
     * @returns {Promise<SurveyListQuestion>} API response
     */
    async fetchSurveyListQuestion(surveylistId: number, surveylistQuestionId: number): Promise<SurveyListQuestion> {
        try {
            const response = await this.baseGet<SurveyListQuestionResponse>(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/questions/${surveylistQuestionId}`,
            );

            return (new SurveyListQuestionFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Select participants for a surveylist.
     * @param {number} surveylistId The resource id for selecting participants
     * @param {WriteParticipantsSelectDto} data Form data to select participants for a surveylist
     * @returns {Promise<null>} Api response
     */
    async selectParticipants(surveylistId: number, data: WriteParticipantsSelectDto): Promise<null> {
        try {
            const response = await this.basePost<null>(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/participants/select`,
                data,
            );

            return response;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Add participants for a surveylist.
     * @param {number} surveylistId The resource id for adding participants
     * @param {WriteParticipantsAddDto} data Form data to add participants for a surveylist
     * @returns {Promise<null>} Api response
     */
    async addParticipants(surveylistId: number, data: WriteParticipantsAddDto): Promise<null> {
        try {
            const response = await this.basePost<null>(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/participants/add`,
                data,
            );

            return response;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete a participant from a surveylist.
     * @param {number} surveylistId The ID of the surveylist which the participant is part of
     * @param {number} participantId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    deleteParticipant(surveylistId: number, participantId: number): Promise<null> {
        try {
            return this.baseDelete(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/participants/${participantId}`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Reopen a surveylist for a participant.
     * @param {number} surveylistId The ID of the surveylist which the participant is part of
     * @param {number} participantId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    reopen(surveylistId: number, participantId: number): Promise<null> {
        try {
            return this.basePost<null>(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/participants/${participantId}/reopen`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple SurveylistParticipants depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @param {number} surveylistId Resource id of surveylist
     * @returns {Promise<SurveylistParticipantsOverview>} Promise with the SurveylistParticipantOverview as payload
     */
    async fetchSurveylistParticipants(
        queryParameters = null,
        surveylistId: number,
    ): Promise<SurveylistParticipantsOverview> {
        try {
            const response = await this.baseGet<SurveylistParticipantsOverviewResponse>(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/participants`,
                queryParameters,
            );
            const surveylistparticipantData = (new SurveylistParticipantFactory()).toModels(response.data);

            const metaData = response.meta;

            return {
                data: surveylistparticipantData,
                meta: metaData,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch the answers of a participant of a certain surveylist.
     * @param {number} surveylistId Identifier of the surveylist to fetch
     * @param {number} participantId Identifier of the question
     * @returns {Promise<SurveyListAnswers>} API response
     */
    async fetchSurveyListAnswers(surveylistId: number, participantId: number): Promise<SurveyListAnswers> {
        try {
            const response = await this.baseGet<SurveyListAnswersResponse>(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/participants/${participantId}`,
            );

            return (new SurveyListAnswersFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Export all the participants of surveylist to a .csv file.
     * @param {number} surveylistId The surveylistId of the file to be exported
     * @returns {Promise<string>} Returns a .csv file with all participants
     */
    async exportParticipants(surveylistId: number): Promise<string> {
        try {
            const response = await this.baseGet<RelationSurveyExportResponse>(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/participants/export`,
            );

            const { message } = response;

            return message;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Send mail invitation or reminder to.
     * @param {number} surveylistId Identifier of the surveylist to fetch
     * @param {WriteInviteParticipantsDto} data Array of participants ids for sending invite
     * @returns {Promise<null>} API response
     */
    async sendMail(surveylistId: number, data: WriteInviteParticipantsDto): Promise<null> {
        try {
            const response = await this.basePost<null>(
                `${this.createPath(SurveyListsService.basePath)}/${surveylistId}/participants/send-mail`,
                data,
            );

            return response;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete a survey.
     * @param {number} surveyId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(surveyId: number): Promise<null> {
        return this.baseDelete(`${this.createPath(SurveyListsService.basePath)}/${surveyId}`);
    }
}
