import { useNuxtApp } from 'nuxt/app';
import type { RouterConfig } from 'nuxt/schema';

export default <RouterConfig> {
    routes: allRoutes => {
        const { ssrContext } = useNuxtApp();

        const subdomain = (process.client || !ssrContext) ? (
            globalThis.location.hostname.split('.')[0]
        ) : ssrContext.event.node.req.headers.host?.split('.')[0];
        const isAppCrm = subdomain?.includes('crm') ?? false;

        const returnedRoutes = allRoutes
            .filter(route => {
                const pathParts = route.path.split('/');
                const firstPart = pathParts.length > 1 ? pathParts[1] : '';

                if (firstPart !== 'crm' && firstPart !== 'extranet') {
                    return false;
                }

                return (
                    firstPart === 'crm' && isAppCrm
                ) || (
                    firstPart === 'extranet' && !isAppCrm
                );
            })
            .map(route => ({
                ...route,
                path: subdomain === 'localhost' ? route.path : route.path.replace(/\/(crm|extranet)/, ''),
            }));

        return returnedRoutes;
    },
};
