import type { SurveyList } from './SurveyList';
import type { ExtranetRelationSurveyListQuestionAndAnswer } from '~/models/ExtranetRelationSurveyListQuestionAndAnswer';

export class ExtranetRelationSurveyListDetailsModel {
    public id: number | null = null;

    public uuid: string | null = null;

    public name: string | null = null;

    public step: number | null = null;

    public surveyProgress: number | null = null;

    public startedDate: string | null = null;

    public endedDate: string | null = null;

    public surveylist: SurveyList | null = null;

    public allQuestionsAndAnswers: ExtranetRelationSurveyListQuestionAndAnswer[] | null = null;
}
