import type { TradeOrganisation } from './TradeOrganisation';

export class RelationTradeOrganisation {
    public tradeOrganisation: TradeOrganisation | null = null;

    public tradeOrganisationName: string | null = null;

    public id: number | null = null;

    public number: string | null = null;

    public updatedAt: string | null = null;
}
