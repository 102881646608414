export class Attachment {
    public id: number | null = null;

    public filename: string | null = null;

    public type: string | null = null;

    public visibleOnExtranet: boolean | null = false;

    public documentDescription: string | null = null;

    public documentType: string | null = null;

    public createdAt: string | null = null;
}
