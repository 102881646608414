import { BaseFactory } from './BaseFactory';
import { CertificateStateFactory } from './CertificateStateFactory';
import { ChamberFactory } from './ChamberFactory';
import { PeriodFactory } from './PeriodFactory';
import { RelationFactory } from './RelationFactory';
import { DepartmentFactory } from './DepartmentFactory';
import { Certificate } from '~/models/Certificate';
import type { ReadCertificateDto } from '~/types/Certificate';

export class CertificateFactory extends BaseFactory<ReadCertificateDto, Certificate> {
    /* eslint-disable-next-line max-statements, complexity */
    public toModel(dto: ReadCertificateDto): Certificate {
        const model = new Certificate();

        model.unsubscribeReason = dto.unsubscribeReason || null;

        // eslint-disable-next-line no-undefined
        if (dto.certificateState !== undefined) {
            model.certificateState = (new CertificateStateFactory()).toModel(dto.certificateState.data);
        }

        if (typeof dto.chamber === 'string') {
            model.chamber = dto.chamber;
        // eslint-disable-next-line no-undefined
        } else if (dto.chamber !== undefined) {
            model.chamber = (new ChamberFactory()).toModel(dto.chamber.data);
        }

        if (typeof dto.department === 'string') {
            model.department = dto.department;
        // eslint-disable-next-line no-undefined
        } else if (dto.department !== undefined) {
            model.department = (new DepartmentFactory()).toModel(dto.department.data);
        }

        model.exportForValidation = Boolean(dto.exportForValidation);
        model.id = Number(dto.id);
        model.period = dto.period || null;
        model.periodId = dto.periodId ? Number(dto.periodId) : null;

        // eslint-disable-next-line no-undefined
        if (dto.periods !== undefined) {
            model.periods = (new PeriodFactory()).toModels(dto.periods.data);
        }

        // eslint-disable-next-line no-undefined
        if (dto.relation !== undefined) {
            model.relation = (new RelationFactory()).toModel(dto.relation.data);
        }

        model.relationFullName = dto.relationFullName || null;
        model.relationId = dto.relationId ? Number(dto.relationId) : null;
        model.relationRTNumber = dto.relationRTNumber || null;
        model.requestUnsubscribeDate = dto.requestUnsubscribeDate || null;
        model.sentEndingReminder = Boolean(dto.sentEndingReminder);
        model.status = dto.status || null;
        model.unsubscribeDate = dto.unsubscribeDate || null;
        model.unsubscribeReason = dto.unsubscribeReason || null;

        return model;
    }
}
