import type { Attachment } from './Attachment';

export class RelationActivity {
    public attachments: Attachment[] | null = null;

    public activityTypeId: number | null = null;

    public activityTypeString: string | null = null;

    public date: string | null = null;

    public description: string | null = null;

    public id: number | null = null;

    public other: string | null = null;

    public points: number | null = null;

    public getActivityTypeStringIncludingOther(): string {
        if (this.activityTypeId === -1) {
            return `Anders:\n${this.other}`;
        }

        return `${this.activityTypeString}`;
    }
}
